import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url } from "../../../api/utils/baseURL";

const LiquidationForm = ({ show, handleClose, liquidation, refreshData }) => {
    const [formValues, setFormValues] = useState(
        liquidation || {
            fullname: "",
            name: ""
        });

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const getAccessToken = () => localStorage.getItem("accessToken");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const fieldLabels = {
            name: "Mã loại thanh lý",
            fullname: "Tên loại thanh lý",
        };

        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                toast.error(`${fieldLabels[key]} không được để trống`);
                return false;
            }
        }
        return true;
    };

    const handleSubmitForm = async () => {
        if (!validateForm()) return;
        setIsConfirmModalOpen(true);
    };

    const handleDeleteLiquidation = async () => {
        try {
            const token = getAccessToken();
            await axios.delete(`${base_url}LoaiThanhLy/deleteLoaiTL/${liquidation.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Xóa thanh lý thành công");
            refreshData();
            handleClose();
        } catch (error) {
            toast.error("Lỗi khi xóa thanh lý");
            console.error("Error deleting liquidation:", error);
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = getAccessToken();
            if (liquidation) {
                await axios.put(`${base_url}LoaiThanhLy/updateLoaiTL/${liquidation.id}`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Cập nhật loại thanh lý thành công");
            } else {
                await axios.post(`${base_url}LoaiThanhLy/createLoaiTL`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Tạo loại thanh lý thành công");
            }
            refreshData();
            handleClose();
        } catch (error) {
            if (error.response.status === 400) {
                toast.error("Đã tồn tại loại thanh lý này");
            }
            else {
                toast.error("Có lỗi xảy ra khi tạo thanh lý");
            }
            console.error("Error submitting form:", error);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    if (!show) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={handleClose}>
                    &times;
                </span>
                <h2>{liquidation ? "Cập nhật loại thanh lý" : "Thêm mới loại thanh lý"}</h2>

                <div className="modal-body">
                    {[
                        { label: "Tên loại thanh lý", name: "fullname" },
                        { label: "Mã loại thanh lý", name: "name" },
                    ].map((field) => (
                        <div className="form-group" key={field.name}>
                            <label>{field.label}</label>
                            <input
                                type="text"
                                name={field.name}
                                value={formValues[field.name] || ""}
                                onChange={handleInputChange}
                            />

                        </div>
                    ))}
                </div>

                <div className="modal-footer">
                    <button onClick={handleSubmitForm} className="submitButton">
                        Lưu
                    </button>
                    {liquidation && (
                        <button
                            onClick={() => setIsDeleteModalOpen(true)}
                            className="deleteButton"
                        >
                            Xóa
                        </button>
                    )}
                </div>

                {/* Modal xác nhận lưu */}
                {isConfirmModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>
                                {liquidation
                                    ? "Bạn có chắc chắn muốn cập nhật thanh lý này không?"
                                    : "Bạn có chắc chắn muốn tạo loại thanh lý mới không?"}
                            </h3>
                            <div className="confirm-actions">
                                <button onClick={handleConfirmSubmit}>Lưu</button>
                                <button onClick={() => setIsConfirmModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal xác nhận xóa */}
                {isDeleteModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>Bạn có chắc chắn muốn xóa loại thanh lý này không?</h3>
                            <div className="confirm-actions">
                                <button onClick={handleDeleteLiquidation}>Xóa</button>
                                <button onClick={() => setIsDeleteModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiquidationForm;
