import Header from "../compoment/header/header";
// import Login from "../components/login/Login.jsx";
// import Register from "../components/register/Register.jsx";
import PTHhomepage from "../compoment/phongtonghop/phongtonghoppage";

function HomePage() {
  return (
    <div>
      <PTHhomepage/>
    </div>
  );
}

export default HomePage;
