import React, { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../../api/utils/baseURL";

const FileListComponent = ({ fileCode , flagCall}) => {
  const [loadingState, setLoadingState] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleFileList = async () => {
      setLoadingState(true);
      setError("");
      const encodedFileCode = encodeURIComponent(fileCode);

      try {
        const response = await axios.get(`${base_url}file/list_linkFile/${encodedFileCode}`);
        setFileList(response.data.data);
      } catch (error) {
        setError("Lỗi khi lấy danh sách file.");
        console.error("Error fetching file list:", error);
      } finally {
        setLoadingState(false);
      }
    };

    handleFileList();
  }, [flagCall]);

  return (
    <div className="max-w-md py-4">
      {loadingState ? (
        <div className="text-center text-gray-500">Đang tải...</div>
      ) : (
        <div>
          {fileList.length > 0 ? (
            <div>
                <select
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                    onChange={(e) => {
                    window.open(e.target.value, "_blank");  
                    }}
                >
                    <option value="">-- Danh sách file --</option>
                    {fileList.map((file, index) => (
                    <option key={index} value={file.file_link}>
                        {file.file_name}
                    </option>
                    ))}
                </select>
            </div>
          ) : (
            <div className="text-center text-gray-500">Không có file nào.</div>
          )}
          {error && <div className="text-red-500 text-center mt-2">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default FileListComponent;
