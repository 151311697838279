import axios from "axios";
import { base_url } from "../utils/baseURL";
import { configUser } from "../utils/axiosConfig";

export const getUser = async (token) => {
    try {
        const res = await axios.get(`${base_url}user/info`, configUser(token));
        return res.data.result;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};
