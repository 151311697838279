import React, { useState, useEffect, useRef } from 'react';
import 'tailwindcss/tailwind.css';
import { getNote, createNote } from '../../api/feartures/contractService';

const Note = ({ onClose, vanbanID, LoaiVB }) => {
    const [messages, setMessages] = useState([]);
    const [NoiDung, setNewNote] = useState('');
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            fetchMessages();
        }
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await getNote(vanbanID, LoaiVB);
            console.log(response);
            setMessages(response.result);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleSubmitNote = async () => {
        try {
            const response = await createNote(vanbanID, LoaiVB, NoiDung);
            console.log('Response from createNote:', response);
            setNewNote('');
            fetchMessages(); // Or update messages state directly
        } catch (error) {
            console.error('Error creating note:', error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ animation: "slideDown 0.5s ease-out forwards" }}>
            <div className="relative w-80 h-4/5 bg-white border shadow-lg flex flex-col rounded-lg overflow-hidden">
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500 hover:text-gray-700">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <div className="flex-1 p-4 overflow-y-scroll border-b">
                    <h2 className="text-lg font-semibold">Phản hồi từ PCM</h2>
                    <div className="mt-2 space-y-4">
                        {messages.map((message) => (
                            <div key={message.id} className="bg-yellow-100 rounded-full px-4 py-2">
                                <h6 className="text-xl font-bold text-gray-800 dark:text-white mb-2">
                                    {message.NhanVien.HoTen}
                                </h6>
                                <p className="text-sm text-gray-700 dark:text-gray-300">
                                    {message.NoiDung}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-4 border-t">
                    <textarea
                        className="w-full p-2 mb-2 border rounded-md resize-none"
                        style={{ minHeight: "100px" }}
                        placeholder="Type your message here..."
                        value={NoiDung}
                        onChange={(e) => setNewNote(e.target.value)}
                    ></textarea>
                    <button className="w-full px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600" onClick={handleSubmitNote}>
                        Thêm ghi chú
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Note;
