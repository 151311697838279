import React, { useState, useEffect } from "react";
import axios from 'axios';
import './ManageAdmin.css';
import { base_url } from "../../api/utils/baseURL";
import { FaSearch } from "react-icons/fa";

const ManageHistory = () => {
    const [editHistoryData, setEditHistoryData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const getAccessToken = () => localStorage.getItem('accessToken');

    const fetchData = async () => {
        try {
            const token = getAccessToken();
            const response = await axios.get(`${base_url}history/list`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setEditHistoryData(response.data.result.data);
            console.log(response.data.result.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredHistoryData = editHistoryData.filter(history =>
        history.SoVanBan.toLowerCase().includes(searchQuery.toLowerCase()) ||
        history.NgayThayDoi.toLowerCase().includes(searchQuery.toLowerCase()) ||
        history.LinkCu.toLowerCase().includes(searchQuery.toLowerCase()) ||
        history.NhanVien.HoTen.toLowerCase().includes(searchQuery.toLowerCase()) ||
        history.NoiDungSua.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{'Danh sách lịch sử chỉnh sửa'}</h1>
                </div>
                <div className="rightPart">
                    <div className="search-container">
                        <form className="searchForm" onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <button type="submit"><FaSearch /></button>
                        </form>
                    </div>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Số văn bản</th>
                        <th>Ngày thay đổi</th>
                        <th>Link cũ</th>
                        <th>ID người đổi</th>
                        <th>Nội dung sửa</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredHistoryData.map((history) => (
                        <tr key={history.id}>
                            <td>{history.SoVanBan}</td>
                            <td>{history.NgayThayDoi}</td>
                            <td>{history.LinkCu}</td>
                            <td>{history.NhanVien.HoTen}</td>
                            <td>{history.NoiDungSua}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ManageHistory;
