export const customStyles = {
  option: (provided, state) => {
    if (state.data.isDisabled) {
      if (state.data.title === 'Đang bị phạt, không thể ký') {
        return {
          ...provided,
          color: 'white',
          backgroundColor: 'red',
        };
      } else if (state.data.title === 'Đã ký 3 năm liên tiếp') {
        return {
          ...provided,
          color: 'white',
          backgroundColor: 'orange',
        };
      }
    }
    return provided;
  },
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    zIndex: 10
}),

};