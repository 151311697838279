import moment from 'moment';
import { format } from 'date-fns';

export const useDateUtils = () => {
    const RealDateString = (dateString) => {
        return dateString ? new Date(moment(dateString, 'DD/MM/YYYY').format()) : null;
    };

    const parseDateString = (dateString) => {
        const dateInput = dateString ? new Date(dateString) : null;
        return new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate());
    };

    const formatDateString = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    };

    const postDate = (date) => {
        return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return {
        RealDateString,
        parseDateString,
        formatDateString,
        postDate,
    };
};