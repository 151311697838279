export const ROLE_ID = localStorage.getItem("role_id")

export const REQUIRED_HD_FIELD = [
    'NgayGhiThucTe',
    'MaLoaiHD',
    // 'ThoiGianHieuLuc',
    'NgayGhiThucTe',
    // 'GiaTriTruocVAT',
    // 'VAT',
    'SoBan',
    'Noidung',
    'MaKhachHang',
    'MaThanhVienBGD',
    "listNhiemVu"
]
export const REQUIRED_TL_FIELD = [
    'NgayGhiThucTe',
    'MaLoaiTL',
    // 'ThoiGianHieuLuc',
    'NgayGhiThucTe',
    // 'GiaTriTruocVAT',
    'VAT',
    'SoBan',
    'Noidung',
    'DonViThucHien',
    'MaThanhVienBGD'
]
export const REQUIRED_PL_FIELD = [
    'NgayGhiThucTe',
    'MaLoaiPL',
    // 'ThoiGianHieuLuc',
    'NgayGhiThucTe',
    // 'GiaTriTruocVAT',
    'VAT',
    'SoBan',
    'Noidung',
    'MaKhachHang',
    'MaThanhVienBGD',
    "listNhiemVu"
]

export const REQUIRED_BC_FIELD = [
    'NgayGhiThucTe',
    'MaLoaiBC',
    // 'ThoiGianHieuLuc',
    'NgayGhiThucTe',
    'SoBan',
    'Noidung',
    "KyThuatVien",
    "TruongNhom",
    "DanhSachKTV",
    'MaThanhVienBGD',
]

export const LuuY_LIST = [
    {
        id: 0,
        name: "Không Lưu ý"
    },
    {
        id: 1,
        name: "Đã lưu trữ"
    },
    {
        id: 2,
        name: "Chưa lưu trữ"
    },
]

export const VATOptions = [
    { id: 8, name: '8' },
    { id: 10, name: '10' },
];

export const getStatusColor = (status) => {
    switch (status) {
        case "HOAN_THANH":
            return "bg-green-100 text-green-800";
        case "DANG_THUC_HIEN":
            return "bg-yellow-100 text-yellow-800";
        // case "CHO_THUC_HIEN":
        //     return "bg-orange-100 text-orange-800";
        case "DA_DUYET":
            return "bg-green-100 text-green-800";
        case "CHO_DUYET":
            return "bg-purple-100 text-purple-800"
        case "DA_NHAN_XET":
            return "bg-orange-100 text-orange-800"
        default:
            return "bg-red-100 text-red-800";
    }
};

export const getStatusText = (text) => {
    switch (text) {
        case "HOAN_THANH":
            return "Hoàn thành";
        case "DANG_THUC_HIEN":
            return "Đang thực hiện";
        // case "CHO_THUC_HIEN":
        //     return "Chờ thực hiện";
        case "CHO_DUYET":
            return "Chờ duyệt";
        case "DA_DUYET":
            return "Đã duyệt";
        case "DA_NHAN_XET":
            return "Đã nhận xét";
        default:
            return "Chưa thực hiện";
    }
};

export const HOAN_THANH = "HOAN_THANH"
export const DA_NHAN_XET = "DA_NHAN_XET"
export const CHO_DUYET = "CHO_DUYET"
export const DANG_THUC_HIEN = "DANG_THUC_HIEN"
export const DA_DUYET = "DA_DUYET"