import Admin from "../compoment/Admin/Admin";
// import Login from "../components/login/Login.jsx";
//  import Register from "../components/register/Register.jsx";
function AdminPage() {
  return (
    <div>
      <Admin/>
    </div>
  );
}

export default AdminPage;
