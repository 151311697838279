// customerService.js
import axios from 'axios';
import { base_url } from '../utils/baseURL';


export const getCustomerTypesData = async () => {
    try {
        const response = await axios.get(`${base_url}customerType/list`);
        return {
            data: response.data.result,
        };
    } catch (error) {
        console.error("Error fetching customer data:", error);
        throw error;
    }
};


export const getCustomerTypeDetail = async (id, token) => {
    try {
        const response = await axios.get(`${base_url}customerType/${id}/detail`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {
            data: response.data.result,
        };
    } catch (error) {
        console.error(`Error fetching customer type detail with ID ${id}:`, error);
        throw error;
    }
};

export const deleteCustomerType = async (id) => {
    try {
        const response = await axios.delete(`${base_url}customerType/${id}/delete`);
        return {
            message: "Xóa loại khách hàng thành công",
        };
    } catch (error) {
        console.error(`Error deleting customer type with ID ${id}:`, error);
        throw error;
    }
};
// customerTypeService.js