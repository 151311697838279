import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { getContractTypes, getBaoCaoTypes } from '../../api/feartures/contractService';
import { getCustomers } from '../../api/feartures/customerService';
import { useCurrencyUtils } from '../../hooks/useCurrencyUtils';
import { useDateUtils } from "../../hooks/useDateUtils";
// import { useFormUtils} from "../../hooks/useFormUtils";
import { toast } from "react-toastify";
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css';
import { REQUIRED_HD_FIELD } from '../../api/utils/constant';
import { validateMissionReportRequiredFields, validateRequiredFields } from '../../api/utils/common';
import ReactSelect from 'react-select';
import { customStyles } from './customStyle';

function CreateHopDong({ onClose, onSave }) {
    const [formData, setFormData] = useState({
        // SoHopDong: '',
        // created_at: moment().format('DD/MM/YYYY'),
        NgayGhiThucTe: '',
        TongGiaTri: 0,
        ThoiGianHieuLuc: '',
        GiaTriTruocVAT: 0,
        VAT: 0,
        SoBan: 0,
        Noidung: '',
        MaKhachHang: '',
        MaHopDongKH: '',
        // DonViThucHien: '',
        // MaNguoiNhap: '',
        MaThanhVienBGD: '',
        LinkDrive: '',
        listNhiemVu: [],
        MaHDCuaKH: ''
    });

    const [contractTypes, setContractTypes] = useState([]);
    const [baoCaoTypes, setBaoCaoTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const hasFetchedData = useRef(false);
    const [errors, setErrors] = useState({});
    const [missionErrors, setMissonErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [contractTypesData, boardMembersData, customersData, baoCaoTypesData] = await Promise.all([
                    getContractTypes(),
                    getBoardMembers(),
                    getCustomers(),
                    getBaoCaoTypes()
                ]);
                setContractTypes(contractTypesData);
                setBoardMembers(boardMembersData);
                setCustomers(customersData);
                setBaoCaoTypes(baoCaoTypesData)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);

    const { formatCurrency } = useCurrencyUtils();
    const { postDate } = useDateUtils();

    const handleInputChange = (e, index, type) => {
        const { name, value } = e.target;

        if (type === 'report') {
            setFormData((prevData) => {
                const newList = [...prevData.listNhiemVu];
                newList[index][name] = value;
                return {
                    ...prevData,
                    listNhiemVu: newList,
                };
            });
            setMissonErrors((prevErrors) => {
                const errorKey = `${name}_${index}`;
                const { [errorKey]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            setErrors({ ...errors, [name]: '' });
        }
    };

    const handleDateChange = (date, field, index, type) => {
        const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;

        if (type === 'report') {
            setFormData((prevData) => {
                const newList = [...prevData.listNhiemVu];
                newList[index][field] = formattedDate;
                return {
                    ...prevData,
                    listNhiemVu: newList,
                };
            });
            setMissonErrors((prevErrors) => {
                const errorKey = `${field}_${index}`;
                const { [errorKey]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [field]: formattedDate,
            }));
            setErrors((prevErrors) => {
                const { [field]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        }

    };

    const handleSave = () => {
        const newErrors = validateRequiredFields(formData, REQUIRED_HD_FIELD);
        const missionContractError = validateMissionReportRequiredFields(formData);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        if (Object.keys(missionContractError).length > 0) {
            setMissonErrors(missionContractError);
            return; 
        }
        if (formData?.listNhiemVu.length < 1) {
            toast.warning("Phải có ít nhất 1 báo cáo!");
            return; 
        }
        try {
            const dataToSend = {
                ...formData,
                GiaTriTruocVAT: parseInt(formData.GiaTriTruocVAT.toString().replace(/\./g, '')),
                TongGiaTri: parseInt(formData.TongGiaTri.toString().replace(/\./g, '')),
                VAT: parseInt(formData.VAT),
                ThoiGianHieuLuc: postDate(formData.ThoiGianHieuLuc),
                listNhiemVu: formData.listNhiemVu.map((item) => ({
                    ...item,
                    ThoiGianHoanThanh: postDate(item.ThoiGianHoanThanh),
                })),
            };
            onSave(dataToSend);
        } catch (error) {
            console.error('Error saving new contract:', error);
        }
    };
    

    const handleClickOutside = (event) => {
        event.stopPropagation();
    };

    const addBaoCao = () => {
        setFormData((prevData) => ({
            ...prevData,
            listNhiemVu: [
                ...prevData.listNhiemVu,
                {
                    MaLoaiBC: '',
                    ThoiGianHoanThanh: null,
                },
            ],
        }));
    };

    const removeBaoCao = (index) => {
        setFormData((prevData) => {
            const newList = prevData.listNhiemVu.filter((_, i) => i !== index);
            return {
                ...prevData,
                listNhiemVu: newList,
            };
        });
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: 'slideDown 0.5s ease-out forwards' }}
            onClick={handleClickOutside}
        >
            <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                style={{ animation: 'slideDownContent 0.5s ease-out forwards' }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <div className="flex h-full">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col items-center">
                            <h1 className="font-bold mb-4 text-xl text-gray-600">Tạo mới hợp đồng</h1>
                        </div>
                        <div className="overflow-auto p-4 flex-grow">  
                            {[
                                { label: 'Số hợp đồng', name: 'SoHopDong', readOnly: true, placeholder: 'Số hợp đồng sẽ được tạo tự động sau khi lưu' },
                                { label: 'Số hợp đồng của khách hàng', name: 'MaHDCuaKH' },
                                { label: '(*) Ngày ghi thực tế trên hợp đồng', name: 'NgayGhiThucTe', type: 'date' },
                                { label: '(*) Loại hợp đồng', name: 'MaLoaiHD', type: 'select', options: contractTypes },
                                // { label: '(*) Thời gian hiệu lực hợp đồng', name: 'ThoiGianHieuLuc', type: 'date' },
                                { label: '(*) Giá trị trước VAT', name: 'GiaTriTruocVAT', format: 'currency', placeholder: 'Nhập giá trị trước VAT (VND)' },
                                { label: '(*) VAT', name: 'VAT', type: 'select', options: [{ id: 8, name: '8%' }, { id: 10, name: '10%' }] },
                                { label: '(*) Số bản', name: 'SoBan', type: 'number', placeholder: 'Nhập số bản hợp đồng' },
                                { label: '(*) Nội dung', name: 'Noidung', type: 'textarea' },
                                { label: '(*) Khách hàng', name: 'MaKhachHang', type: 'select', options: customers },
                                { label: '(*) Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: boardMembers },
                                { label: 'Link Drive', name: 'LinkDrive' },
                                { label: '(*) Danh sách báo cáo', name: 'listNhiemVu', type: 'list' },
                            ].map((field, idx) => (
                                <div className="mb-2 flex items-center" key={idx}>
                                    <label className="font-bold w-1/3">{field.label}:</label>
                                    <div className="w-2/3">
                                        {errors[field.name] && <span className="text-red-500 text-sm">{errors[field.name]}</span>}
                                        {field.type === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        ) : field.type === 'select' && field.name !== 'MaKhachHang' && field.name !== 'MaThanhVienBGD' ? (
                                            <select
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name] || ''}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Chọn...</option>
                                                {field.options.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name || option.HoTen}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.name === 'MaKhachHang' ? (
                                            <ReactSelect
                                                name={field.name}
                                                options={
                                                    field.options?.map((customer) => ({
                                                        value: customer.id,
                                                        label: `${customer.name} - ${customer.MST}`,
                                                    })) || []
                                                }
                                                className="w-full"
                                                value={
                                                    formData[field.name]
                                                        ? { value: formData[field.name], label: field.options.find(option => option.id === formData[field.name])?.name }
                                                        : null
                                                }
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: { name: field.name, value: selectedOption?.value || null },
                                                    })
                                                }
                                                styles={customStyles}
                                                placeholder="Chọn khách hàng..."
                                                isClearable
                                                isSearchable
                                            />
                                        ) : field.name === 'MaThanhVienBGD' ? (
                                            <ReactSelect
                                                name={field.name}
                                                options={
                                                    field.options?.map((customer) => ({
                                                        value: customer.id,
                                                        label: `${customer.HoTen}`,
                                                    })) || []
                                                }
                                                className="w-full"
                                                value={
                                                    formData[field.name]
                                                        ? { value: formData[field.name], label: field.options.find(option => option.id === formData[field.name])?.HoTen }
                                                        : null
                                                }
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: { name: field.name, value: selectedOption?.value || null },
                                                    })
                                                }
                                                placeholder="Chọn BGD..."
                                                isClearable
                                                isSearchable
                                            />
                                        ) : field.type === 'date' ? (
                                            <DatePicker
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />
                                        ) : field.type === 'list' ? (
                                            <div>
                                                {formData.listNhiemVu.map((baoCao, index) => (
                                                    <fieldset key={index}
                                                        className="mb-2 flex flex-row justify-between border rounded p-2">
                                                        <legend className={`font-normal`}>
                                                            Báo cáo {index + 1} &nbsp;
                                                            <span>
                                                                <button
                                                                    type="button"
                                                                    className="ml-2 text-white bg-red-500 rounded px-2 py-1 aspect-square"
                                                                    onClick={() => removeBaoCao(index)}
                                                                >
                                                                    <i className={`fa-solid fa-trash`}></i>
                                                                </button>
                                                            </span>
                                                        </legend>

                                                        <div>
                                                            <label className="font-bold italic">Loại báo cáo:</label>
                                                            <select
                                                                name="MaLoaiBaoCao"
                                                                className="w-full border rounded px-2 py-1"
                                                                value={baoCao.MaLoaiBaoCao}
                                                                onChange={(event) => handleInputChange(event, index, 'report')}
                                                            >
                                                                <option value="">Chọn...</option>
                                                                {baoCaoTypes.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {missionErrors[`MaLoaiBaoCao_${index}`] && (
                                                                <div className="text-red-500 text-sm">
                                                                    {missionErrors[`MaLoaiBaoCao_${index}`]}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <label clssName="font-bold italic">Thời gian hoàn thành:</label>
                                                            <div>
                                                                <DatePicker
                                                                    onChange={(date) => handleDateChange(date, 'ThoiGianHoanThanh', index, 'report')}
                                                                    value={baoCao.ThoiGianHoanThanh ? new Date(moment(baoCao.ThoiGianHoanThanh, 'DD/MM/YYYY').format()) : null}
                                                                    format="dd/MM/yyyy"
                                                                    locale="vi"
                                                                    className="w-full border rounded px-2 py-1"
                                                                    placeholderText="Ngày/Tháng/Năm"
                                                                />
                                                            </div>
                                                            {missionErrors[`ThoiGianHoanThanh_${index}`] && (
                                                                <div className="text-red-500 text-sm block">
                                                                    {missionErrors[`ThoiGianHoanThanh_${index}`]}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </fieldset>
                                                ))}
                                                <button
                                                    className={`w-full border-2 rounded px-2 py-1 border-blue-800 text-blue-800 font-bold `}
                                                    onClick={addBaoCao}>
                                                    <i className="fa-solid fa-plus"></i>
                                                    &nbsp;Thêm báo cáo
                                                </button>
                                            </div>
                                        ) : field.type === 'number' ? (
                                            <input
                                                type={"number"}
                                                className={"w-full border rounded px-2 py-1"}
                                                name={field.name}
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                placeholder={field.placeholder}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.format === 'currency' ? formatCurrency(formData[field.name]) : formData[field.name]}
                                                onChange={handleInputChange}
                                                readOnly={field.readOnly}
                                                placeholder={field.readOnly ? "Số hợp đồng sẽ được tạo tự động sau khi lưu" : null}
                                                style={field.readOnly ? { fontStyle: 'italic' } : null}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2">
                            Lưu hợp đồng
                        </button>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

CreateHopDong.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CreateHopDong;