import axios from 'axios';
import { base_url } from "../utils/baseURL";
import { configUser } from "../utils/axiosConfig";


export const createThanhLy = async (data) => {
    try {
        const res = await axios.post(`${base_url}thanhLy/create`, data, configUser(localStorage.getItem("accessToken")));        
        return res.data;
    } catch (error) {
        console.error('Error creating thanh ly:', error);
        throw error;
    }
}

export const getListByThanhLy = async (page,accessToken) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/list-by-thanhly?page=${page}`,configUser(accessToken));
        return res.data.result;
    } catch (error) {
        console.error('Error fetching KTV thuc hien:', error);
        throw error;
    }
}
export const searchThanhly= async (pageNumber,SoThanhLy, TenKhachHang,SoHopDong,TrangThai) => {
    try {
        const res= await axios.get( `${base_url}generalRoom/list-by-thanhly?page=${pageNumber}` +
                    (TenKhachHang ? `&TenKhachHang=${TenKhachHang}` : '') +
                    (SoThanhLy ? `&SoThanhLy=${SoThanhLy}` : '')+
                    (SoHopDong ? `&SoHopDong=${SoHopDong}` : '')+
                    (TrangThai ? `&TrangThai=${TrangThai}` : ''),configUser);
        if(res.data) return res.data;
    } catch (error) {
        console.error('Error fetching list data:', error);
        return null;  
    }
};
export const updateThanhLy = async (data, token) => {
    try {
        const res = await axios.put(`${base_url}thanhLy/update`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error update hop dong:', error);
        throw error;
    }
}