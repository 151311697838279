import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { createNhiemVuHD, getBaoCaoTypes, getNhiemVu } from '../../api/feartures/contractService';
import { useDateUtils } from '../../hooks/useDateUtils';
import { toast } from 'react-toastify';
import { formatDateToISO, formattedDate } from '../../api/utils/common';
import { ROLE_ID } from '../../api/utils/constant';

function CreateNhiemVu({ vanbanID, type, onClose, status }) {
  const [baoCaoType, setBaoCaoTypes] = useState([]);
  const hasFetchedData = useRef(false);
  const { postDate } = useDateUtils();
  const fetchBCTypes = async () => {
    const res = await getBaoCaoTypes();
    if (res) {
      setBaoCaoTypes(res);
    }
  };

  const fetchNhiemVu = async () => {
    const res = await getNhiemVu(vanbanID, type);
    if (res) {
      const listNhiemVu = res.map(item => ({
        MaLoaiBaoCao: item.LoaiBC.id,
        ThoiGianHoanThanh: item.ThoiGianHoanThanh,
        id: item.id
      }));
      setFormData(prevData => ({
        ...prevData,
        listNhiemVu: listNhiemVu,
      }));
    }
  };

  useEffect(() => {
    fetchNhiemVu();
  }, [vanbanID, type]);

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchBCTypes();
      hasFetchedData.current = true;
    }
  }, []);

  const [formData, setFormData] = useState({
    listNhiemVu: [{ MaLoaiBaoCao: '', ThoiGianHoanThanh: '', id: null }],
  });

  const [missionErrors, setMissionErrors] = useState({});

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newListNhiemVu = [...formData.listNhiemVu];
    newListNhiemVu[index][name] = value;
    setFormData({ ...formData, listNhiemVu: newListNhiemVu });
  };

  const handleDateChange = (date, field, index) => {
    const newListNhiemVu = [...formData.listNhiemVu];
    newListNhiemVu[index][field] = moment(date).format('YYYY-MM-DD');
    setFormData({ ...formData, listNhiemVu: newListNhiemVu });
  };

  const addBaoCao = () => {
    const newListNhiemVu = [...formData.listNhiemVu, { MaLoaiBaoCao: '', ThoiGianHoanThanh: '' }];
    setFormData({ ...formData, listNhiemVu: newListNhiemVu });
  };

  const removeBaoCao = (index) => {
    const newListNhiemVu = formData.listNhiemVu.filter((_, i) => i !== index);
    setFormData({ ...formData, listNhiemVu: newListNhiemVu });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let errors = {};
    formData.listNhiemVu.forEach((baoCao, index) => {
      if (!baoCao.MaLoaiBaoCao) {
        errors[`MaLoaiBaoCao_${index}`] = 'Vui lòng chọn loại báo cáo!';
      }
      if (!baoCao.ThoiGianHoanThanh) {
        errors[`ThoiGianHoanThanh_${index}`] = 'Vui lòng chọn thời gian hoàn thành!';
      }
    });

    if (Object.keys(errors).length > 0) {
      setMissionErrors(errors);
    } else {
      const dataToSend = {
        ...formData,
        HopDongID: type === 'HD' ? vanbanID : null,
        PhuLucID: type === 'PL' ? vanbanID : null,

      };
      const res = await createNhiemVuHD(dataToSend);
      toast.success(res?.message);
      onClose();
    }
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        {/* Modal header with close button */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Tạo Nhiệm Vụ</h2>
          <button
            type="button"
            onClick={closeModal}
            className="text-white bg-red-500 rounded-full px-2 py-0.5"
          >
            <i className="fa-solid fa-times"></i>
          </button>
        </div>
        <div>
          <form onSubmit={submitForm}>
            <div className="max-h-[50vh] min-h-[40vh] overflow-y-auto z-50">
              {formData.listNhiemVu.map((baoCao, index) => (
                <fieldset key={index} className="mb-2 flex flex-row justify-between border rounded p-2">
                  <legend className="font-normal">
                    Báo cáo {index + 1} &nbsp;
                    <span>
                      <button
                        type="button"
                        className="ml-2 text-white bg-red-500 rounded px-2 py-1 aspect-square"
                        onClick={() => removeBaoCao(index)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </span>
                  </legend>

                  <div>
                    <label className="font-bold italic">Loại báo cáo:</label>
                    <select
                      name="MaLoaiBaoCao"
                      className="w-full border rounded px-2 py-1"
                      value={baoCao.MaLoaiBaoCao}
                      onChange={(event) => handleInputChange(event, index)} // Đã sửa
                    // disabled={!!baoCao.MaLoaiBaoCao} 
                    >
                      <option value="">Chọn...</option>
                      {baoCaoType?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {missionErrors[`MaLoaiBaoCao_${index}`] && (
                      <div className="text-red-500 text-sm">
                        {missionErrors[`MaLoaiBaoCao_${index}`]}
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="font-bold italic">Thời gian hoàn thành:</label>
                    <div>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'ThoiGianHoanThanh', index)}
                        value={baoCao.ThoiGianHoanThanh ? new Date(moment(baoCao.ThoiGianHoanThanh, 'YYYY/MM/DD').format()) : null}
                        format="dd/MM/yyyy"
                        locale="vi"
                        className="w-full border rounded px-2 py-1"
                        placeholderText="Ngày/Tháng/Năm"
                        wrapperClassName="relative z-50"
                      // disabled={!!baoCao.ThoiGianHoanThanh}
                      />
                    </div>
                    {missionErrors[`ThoiGianHoanThanh_${index}`] && (
                      <div className="text-red-500 text-sm block">
                        {missionErrors[`ThoiGianHoanThanh_${index}`]}
                      </div>
                    )}
                  </div>
                </fieldset>
              ))}
              {(status === "CHO_DUYET" || status === "DA_NHAN_XET" || ROLE_ID === '2') && (
                <button
                  className="w-full border-2 rounded px-2 py-1 border-blue-800 text-blue-800 font-bold"
                  onClick={addBaoCao}
                  type="button"
                >
                  <i className="fa-solid fa-plus"></i> &nbsp;Thêm báo cáo
                </button>
              )}

            </div>
            <div className="mt-4 flex justify-center z-0">
              {(status === "CHO_DUYET" || status === "DA_NHAN_XET" || ROLE_ID === '2') && (
                <button
                  type="submit"
                  className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full
                  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  <i className="fa-solid fa-check"></i> &nbsp;Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateNhiemVu;
