export const useCurrencyUtils = () => {
    const formatCurrency = (value) => {
        if (!value) return '';
        return value
            .replace(/\D/g, '') // Loại bỏ ký tự không phải số
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Thêm dấu chấm tách nhóm
    };

    return {
        formatCurrency
    };
};
