import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomePage from '../../page/HomePage';
import HomePagePCM from '../../page/HomePagePCM';
import LoginForm from '../../compoment/LoginForm/LoginForm';
import Dashboard from '../../compoment/phongtonghop/dashboard';
import AdminPage from '../../page/AdminPage'
import Header from '../../compoment/header/header';
import Dsbaocao from '../../compoment/phongtonghop/DSBaoCao/dsbaocao';
import Dsthanhly from '../../compoment/phongtonghop/DSThanhLy/dsthanhly';
import Dsphuluc from '../../compoment/phongtonghop/DSPhuLuc/dsphuluc';
import UserProfile from '../../page/InfoPage';
import ImportFile from '../../compoment/importFile/importFile';
import { useSelector } from 'react-redux';
import { getUserRoleId } from '../../redux/slices/userSlice';
import { toast } from 'react-toastify';

function PrivateRoute({ roleAllowed, children }) {
    const roleId = useSelector(getUserRoleId)
    if (!roleAllowed.includes(roleId)) {
        toast.error("Bạn không có quyền truy cập trang này");
        alert("Bạn không có quyền truy cập trang này");
        return <Navigate to="/" />;
    }

    return children;
}

function AppRouter() {
    const roleId = useSelector(getUserRoleId)
    console.log('====================================', roleId);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginForm />} />
                <Route element={<LayoutWithHeader />}>
                    <Route path="/home" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <HomePage /> </PrivateRoute>} />
                    <Route path="/user-profile" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <UserProfile /> </PrivateRoute>} />
                    <Route path="/import-file" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <ImportFile /> </PrivateRoute>} />
                    <Route path="/homePCM" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <HomePagePCM /> </PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dashboard /> </PrivateRoute>} />
                    <Route path="/dsbaocao" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsbaocao /> </PrivateRoute>} />
                    <Route path="/dsphuluc" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsphuluc /> </PrivateRoute>} />
                    <Route path="/dsthanhly" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsthanhly /> </PrivateRoute>} />
                    <Route path="/Admin" element={<PrivateRoute roleAllowed={[1]}> <AdminPage /></PrivateRoute>} />

                </Route>
            </Routes>
            {/*<ToastContainer />*/}
        </BrowserRouter>
    );
}

function LayoutWithHeader() {
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/home" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <HomePage /> </PrivateRoute>} />
                <Route path="/homePCM" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <HomePagePCM /> </PrivateRoute>} />
                <Route path="/dsbaocao" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsbaocao /> </PrivateRoute>} />
                <Route path="/dsthanhly" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsthanhly /> </PrivateRoute>} />
                <Route path="/dsphuluc" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dsphuluc /> </PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <Dashboard /> </PrivateRoute>} />
                <Route path="/user-profile" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <UserProfile /> </PrivateRoute>} />
                <Route path="/import-file" element={<PrivateRoute roleAllowed={[1, 2, 3]}> <ImportFile /> </PrivateRoute>} />
                <Route path="/Admin" element={<PrivateRoute roleAllowed={[1]}> <AdminPage /></PrivateRoute>} />

            </Routes>
        </div>
    );
}

export default AppRouter;