import axios from 'axios';
import { base_url } from "../utils/baseURL";
import { configUser } from "../utils/axiosConfig";




export const getContractListPCM = async (accessToken, page) => {
    try {
        const res = await axios.get(`${base_url}specializeRoom/list?page=${page}`, configUser(accessToken));
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error updating status report:', error);
        throw error;
    }
};




export const searchContractforPCM = async (accessToken, pageNumber, TenKhachHang, SoHopDong, TrangThai) => {
    try {
        const res = await axios.get(`${base_url}specializeRoom/list?page=${pageNumber}` +
            (TenKhachHang ? `&TenKhachHang=${TenKhachHang}` : '') +
            (SoHopDong ? `&SoHopDong=${SoHopDong}` : '') +
            (TrangThai ? `&TrangThai=${TrangThai}` : ''), configUser(accessToken));
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error fetching list data:', error);
        return null;  // hoặc bạn có thể xử lý lỗi theo cách khác tùy thuộc vào yêu cầu của bạn
    }
};
