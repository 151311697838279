import React from 'react';

const EditHistoryDetails = ({ isOpen, onClose, historyData }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-3/4">
        <h2 className="text-2xl mb-4">Lịch sử chỉnh sửa</h2>
        {historyData.length === 0 ? (
          <p>No data available</p>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Số văn bản</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ngày thay đổi</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Link cũ</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID người đổi</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nội dung sửa</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {historyData.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.SoVanBan}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(item.NgayThayDoi).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap"><a href={item.LinkCu} target="_blank" rel="noopener noreferrer">{item.LinkCu}</a></td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.NguoiSua}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.NoiDungSua}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded"
          >
            Đóng
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditHistoryDetails;
