// Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import { ROLE_ID } from '../../api/utils/constant';
const Sidebar = ({ isOpen, onClose }) => {
    const [currentItem, setCurrentItem] = useState(null);
    const sidebarRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Lấy đường dẫn URL hiện tại và xác định mục menu phù hợp
        const pathName = window.location.pathname;
        setCurrentItem(getCurrentItemFromPath(pathName));

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleItemClick = (itemName) => {
        setCurrentItem(itemName);
        // Thực hiện các hành động khác khi chọn mục menu
    };

    const getCurrentItemFromPath = (path) => {
        if (path === '/home') {
            return 'home pth';
        }
        else if (path === '/homePCM') {
            return 'home pcm';
        } else if (path === '/dashboard') {
            return 'dashboard';
        } else if (path === '/dsbaocao') {
            return 'dsbaocao';
        }
        else if (path === '/dsphuluc') {
            return 'dsphuluc';
        }
        else if (path === '/dsthanhly') {
            return 'dsthanhly';
        }
        return null;
    };

    return (
        <div
            ref={sidebarRef}
            className={`slide-bar fixed inset-y-0 left-0 w-80 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
                } transition-transform duration-300 ease-in-out z-50`}
        >
            <div className="flex justify-end p-4">
                <button onClick={onClose} className="text-gray-500 focus:outline-none">
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </button>
            </div>
            <div className="bg-blue-500 text-white text-center py-2">
                AASC
            </div>
            <nav className="p-4">
                <ul>
                    {ROLE_ID === "2" ? (
                        <li className="p-2">
                            <a
                                href="/home"  // Thay đổi href tương ứng với từng mục menu
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'home pth' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('home pth')}
                            >
                                Danh sách hợp đồng
                            </a>
                        </li>
                    ) : (
                        <li className="p-2">
                            <a
                                href="/homePCM"  // Thay đổi href tương ứng với từng mục menu
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'home pcm' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('home pcm')}
                            >
                                Danh sách hợp đồng
                            </a>
                        </li>
                    )
                    }
                    <>
                        <li className="p-2">
                            <a
                                href="/dsbaocao"  // Thay đổi href tương ứng với từng mục menu
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'dsbaocao' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('dsbaocao')}
                            >
                                Xem DS Báo cáo
                            </a>
                        </li>
                        <li className="p-2">
                            <a
                                href="/dsphuluc"  // Thay đổi href tương ứng với từng mục menu
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'dsphuluc' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('dsphuluc')}
                            >
                                Xem DS Phụ lục
                            </a>
                        </li>
                        <li className="p-2">
                            <a
                                href="/dsthanhly"  // Thay đổi href tương ứng với từng mục menu
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'dsthanhly' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('dsthanhly')}
                            >
                                Xem DS Thanh lý
                            </a>
                        </li>
                        <li className="p-2">
                            <a
                                href="/dashboard"
                                className={`text-gray-700 hover:text-blue-500 hover:bg-gray-100 px-2 py-1 rounded block ${currentItem === 'dashboard' ? 'bg-blue-100' : ''
                                    }`}
                                onClick={() => handleItemClick('dashboard')}
                            >
                                Dashboard
                            </a>
                        </li>
                    </>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
