import React, { useEffect, useState } from "react";
import './LoginForm.css';
import { FaUser, FaLock } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { base_url } from "../../api/utils/baseURL";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { updateUserRoleId } from "../../redux/slices/userSlice";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const roleId = localStorage.getItem('role_id');
        if (roleId) {
            if (roleId === '1') {
                navigate('/admin');
            } else if (roleId === '2') {
                navigate('/home');
            } else if (roleId === '3') {
                navigate('/homePCM');
            }
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${base_url}auth/login`, {
                username,
                password
            });

            console.log("Response:", response);

            const { access_token, dataUser } = response.data.result;

            // Lưu thông tin vào localStorage
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('userName', dataUser.username);
            localStorage.setItem('role_id', dataUser.role_id);

            // Cập nhật role_id vào Redux
            dispatch(updateUserRoleId(dataUser.role_id));

            console.log("Đăng nhập thành công:", response.data.result);

            toast.success("Đăng nhập thành công");

            // Chuyển hướng sau khi lưu role_id
            setTimeout(() => {
                if (dataUser.role_id === 1) {
                    window.location.href = '/Admin';
                } else if (dataUser.role_id === 2) {
                    window.location.href = '/home';
                } else if (dataUser.role_id === 3) {
                    window.location.href = '/homePCM';
                }
            }, 1000);

        } catch (error) {
            toast.error(error.response.data.message);
            console.error('Error logging in:', error);
        }
    };

    return (
        <div className="login-page">
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Đăng nhập</h1>

                    <div className="input-box">
                        <input
                            type="text"
                            placeholder="Tài khoản"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <FaUser className="icon" />
                    </div>

                    <div className="input-box">
                        <input
                            type="password"
                            placeholder="Mật khẩu"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <FaLock className="icon" />
                    </div>

                    <div className="remember-forgot">
                        <label>
                            <input type="checkbox" /> Nhớ tài khoản
                        </label>
                        <a href="#">Quên mật khẩu?</a>
                    </div>

                    <button type="submit">Đăng nhập</button>
                </form>
            </div>

            <ToastContainer />
        </div>
    );
};

export default LoginForm;
