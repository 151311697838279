import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ImportFile = () => {
    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);
    const [excelData, setExcelData] = useState(null);

    const handleFile = (e) => {
        const fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (fileTypes.includes(selectedFile.type)) {
                setTypeError(null);
                const reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFile(e.target.result);
                };
            } else {
                setTypeError('Please select only excel file types');
                setExcelFile(null);
            }
        } else {
            console.log('Please select your file');
        }
    };
    console.log(excelData)


    const handleFileSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
            setExcelData(data);
        }
    };

    const headers = [
        "STT ngày", "Ngày", "Số (mặc định)", "Loại VB (mặc định)", "Đơn vị",
        "Ký hiệu văn bản", "NƠI NHẬN", "NỘI DUNG", "LOẠI VB", "BTGĐ",
        "SỐ BẢN", "SỐ LƯU", "GHI CHÚ", "NGƯỜI NHẬP"
    ];

    return (
        <div className="wrapper">
            <h3>Upload & View Excel Sheets</h3>

            <form className="form-group custom-form" onSubmit={handleFileSubmit}>
                <input type="file" className="form-control" required onChange={handleFile} />
                <button type="submit" className="btn btn-success btn-md">UPLOAD</button>
                {typeError && (
                    <div className="alert alert-danger" role="alert">{typeError}</div>
                )}
            </form>

            <div className="viewer">
                {excelData ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    {headers.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {excelData.map((row, index) => (
                                    <tr key={index}>
                                        {headers.map((header, i) => (
                                            <td key={i}>{row[`__EMPTY_${i}`] !== undefined ? row[`__EMPTY_${i}`] : ''}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>No File is uploaded yet!</div>
                )}
            </div>
        </div>
    );
};

export default ImportFile;
