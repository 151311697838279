import PCMHomepage from "../compoment/phongchuyenmon/phongchuyenmonpage";
// import Login from "../components/login/Login.jsx";
// import Register from "../components/register/Register.jsx";
function HomePagePCM() {
    return (
        <div>
            <PCMHomepage />
        </div>
    );
}

export default HomePagePCM;
