import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../../api/utils/baseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UploadFileModal({ isOpen, onClose, fileCode }) {
    const [file, setFile] = useState(null);
    const [customFileName, setCustomFileName] = useState("");
    const [file_code, setFileCode] = useState("");  
    const [fileLink, setFileLink] = useState("");
    const [accessToken, setAccessToken] = useState("");

    const handleFileChange = (e) => setFile(e.target.files[0]);
    const handleFileNameChange = (e) => setCustomFileName(e.target.value); 
    const handleAccessTokenChange = (e) => setAccessToken(e.target.value);

    const resetForm = () => {
        setFile(null);
        setCustomFileName("");
        setFileCode("");
        setFileLink("");
        setAccessToken("");
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const checkValid = () => {
        if (!file) {
            toast.error("Vui lòng chọn file!");
            return false;
        }
        if (!fileCode) {
            toast.error("Vui lòng nhập mã file!");
            return false;
        }
        if (!accessToken) {
            toast.error("Vui lòng nhập Develop Token!");
            return false;
        }
        return true;
    };

    const handleUpload = async () => {
        if (!checkValid()) return;

        const formData = new FormData();
        let fileName = customFileName || file.name;
        if (!fileName.endsWith(".pdf")) fileName += ".pdf";

        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("accessToken", accessToken);
        formData.append("fileCode", fileCode);

        try {
            const response = await axios.post(`${base_url}file/createFile`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setFileLink(response.data.shared_link);
            toast.success("File tải lên thành công!");
        } catch (error) {
            console.error("Upload error:", error);
            toast.error("Lỗi tải lên file. Vui lòng thử lại.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <ToastContainer position="top-center" autoClose={1000} />
            <div className="bg-white p-6 rounded-lg w-96 shadow-lg relative">
                <button
                    className="absolute top-2 right-2 text-xl font-bold text-gray-500 hover:text-gray-700"
                    onClick={handleClose}
                >
                    ×
                </button>
                <h2 className="text-2xl font-semibold mb-4">Tải lên File</h2>
                              
                <input
                    type="text"
                    placeholder="Nhập mã file"
                    value={fileCode}
                    className="w-full border p-2 mb-4 rounded-md"
                />
                
                <input
                    type="text"
                    placeholder="Develop Token"
                    value={accessToken}
                    onChange={handleAccessTokenChange}
                    className="w-full border p-2 mb-4 rounded-md"
                />
                
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full border p-2 mb-4 rounded-md"
                />
                <input
                    type="text"
                    placeholder="Nhập tên file mong muốn"
                    value={customFileName}
                    onChange={handleFileNameChange}
                    className="w-full border p-2 mb-4 rounded-md"
                />
                <button
                    onClick={handleUpload}
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                >
                    Tải lên
                </button>
                {fileLink && (
                    <div className="mt-4">
                        <label className="block text-gray-700 font-bold mb-2">
                            Link file:
                        </label>
                        <input
                            type="text"
                            value={fileLink}
                            readOnly
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent overflow-x-auto"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default UploadFileModal;
