import React, { useState, useEffect } from "react";
import "./ManageAdmin.css";
import { FaBuilding } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { RiAccountCircleFill } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../modal";
import ManageUser from "./ManageUser";
import ManageHistory from "./ManageHistory";
import ManageCustomer from "./ManageCustomer";
import ManageEmployee from "./ManageEmployee";
import ManageCustomerType from "./ManageCustomerType";
import ManageAddendum from "./ManageAddendum";
import ManageLiquidation from "./ManageLiquidation";
import ManageReport from "./ManageReport";
import ManageContract from "./ManageContract";
import ManageDepartment from "./ManageDepartment";

const Admin = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const defaultButton = searchParams.get("activeButton") || "manageUser";
    const [activeButton, setActiveButton] = useState(defaultButton);

    const [toggleState, setToggleState] = useState({
        employee: false,
        customer: false,
        document: false,
    });

    useEffect(() => {
        setSearchParams({ activeButton });
    }, [activeButton, setSearchParams]);

    const handleButtonClick = (button, menu = null) => {
        setActiveButton(button);
        if (menu) {
            setToggleState((prev) => ({
                ...prev,
                [menu]: !prev[menu],
            }));
        }
    };

    const logOut = () => {
        setMessage("Bạn có chắc muốn đăng xuất không!");
        setIsOpen(true);
    };

    const handleClose = () => setIsOpen(false);
    const handleConfirm = () => {
        localStorage.clear();
        navigate("/");
        setIsOpen(false);
    };

    return (
        <div className="mainBox">
            {/* LeftBar */}
            <div className="leftBox sidebar">
                <h1 className="Logo">
                    <FaBuilding /> Công ty AASC
                </h1>
                <div className="buttonContainer">
                    {/* Quản lý nhân viên */}
                    <button
                        className={`btn ${activeButton.startsWith("manageUser") || toggleState.employee
                            ? "active"
                            : ""
                            }`}
                        onClick={() => handleButtonClick("manageEmployee", "employee")}
                    >
                        Quản lý danh sách
                    </button>
                    {toggleState.employee && (
                        <>
                            <button
                                className={`btn-sub ${activeButton === "manageEmployee" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageEmployee")}
                            >
                                Danh sách kiểm toán viên
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageUser" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageUser")}
                            >
                                Danh sách người dùng
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageCustomer" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageCustomer")}
                            >
                                Danh sách khách hàng
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageRoom" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageRoom")}
                            >
                                Danh sách phòng ban
                            </button>
                        </>
                    )}

                    {/* Quản lý văn bản */}
                    <button
                        className={`btn ${activeButton.startsWith("manageContract") ||
                            toggleState.document
                            ? "active"
                            : ""
                            }`}
                        onClick={() => handleButtonClick("manageContract", "document")}
                    >
                        Quản lý danh mục
                    </button>
                    {toggleState.document && (
                        <>
                            <button
                                className={`btn-sub ${activeButton === "manageContract" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageContract")}
                            >
                                Danh mục loại hợp đồng
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageReport" ? "active" : ""
                                    }`}
                                onClick={() =>
                                    handleButtonClick("manageReport")
                                }
                            >
                                Danh mục loại báo cáo
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageAddendum" ? "active" : ""
                                    }`}
                                onClick={() =>
                                    handleButtonClick("manageAddendum")
                                }
                            >
                                Danh mục loại phụ lục
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageLiquidation" ? "active" : ""
                                    }`}
                                onClick={() =>
                                    handleButtonClick("manageLiquidation")
                                }
                            >
                                Danh mục loại thanh lý
                            </button>
                            <button
                                className={`btn-sub ${activeButton === "manageCustomerType" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClick("manageCustomerType")}
                            >
                                Danh mục loại khách hàng
                            </button>
                        </>
                    )}

                    {/* Lịch sử chỉnh sửa */}
                    <button
                        className={`btn ${activeButton === "editHistory" ? "active" : ""
                            }`}
                        onClick={() => handleButtonClick("editHistory")}
                    >
                        Lịch sử chỉnh sửa
                    </button>
                </div>

                {/* Footer */}
                <div className="footer">
                    <div className="footer-content">
                        <RiAccountCircleFill className="iconAccount" />
                        <h1 className="userNameAdmin">
                            {localStorage.getItem("userName") || "Admin"}
                        </h1>
                        <MdLogout className="iconLogOut" onClick={logOut} />
                        <Modal
                            isOpen={isOpen}
                            onClose={handleClose}
                            onConfirm={handleConfirm}
                            message={message}
                        />
                    </div>
                </div>
            </div>

            {/* RightBox */}
            <div className="rightBox">
                {activeButton === "manageUser" && <ManageUser />}
                {activeButton === "manageRoom" && <ManageDepartment />}
                {activeButton === "editHistory" && <ManageHistory />}
                {activeButton === "manageCustomer" && <ManageCustomer />}
                {activeButton === "manageEmployee" && <ManageEmployee />}
                {activeButton === "manageContract" && <ManageContract />}
                {activeButton === "manageCustomerType" && <ManageCustomerType />}
                {activeButton === "manageReport" && <ManageReport />}
                {activeButton === "manageAddendum" && <ManageAddendum />}
                {activeButton === "manageLiquidation" && <ManageLiquidation />}
            </div>
        </div>
    );
};

export default Admin;
