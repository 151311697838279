import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({ pageCount, onPageChange, pageRangeDisplayed }) {
  return (
    <div className="flex justify-center mt-4">
      <ReactPaginate
        previousLabel="Trước"
        nextLabel="Sau"
        breakLabel="..."
        onPageChange={onPageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item previous"
        previousLinkClassName="page-link"
        nextClassName="page-item next"
        nextLinkClassName="page-link"
        breakClassName="page-item break"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    </div>
  );
}

export default Pagination;
