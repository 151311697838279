import React, { useState, useEffect } from "react";
import { base_url } from "../../api/utils/baseURL";
import axios from "axios";
import Pagination from "../Pagination";
import ContractForm from "./Form/ContractForm";

const ManageContract = () => {
    // Manage State
    const [pageCount, setPageCount] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedData, setSelectedData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${base_url}LoaiHopDong/getListHD?page=${currentPage + 1}`
            );
            setFilteredData(response.data.result.data);
            setPageCount(Math.ceil(response.data.result.total / 10));
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const handleAddingContract = async () => {
        setSelectedData(null); 
        setShowModal(true);
    };

    const handleViewEdit = async (id) => {
        try {
            const response = await axios.get(
                `${base_url}LoaiHopDong/getDetailHD/${id}`
            );
            setSelectedData(response.data);
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching contract details", error);
        }
    };

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    };
    
    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{"Danh mục Hợp đồng"}</h1>
                </div>
                <div className="rightPart">
                    <button
                        className="addButton"
                        onClick={handleAddingContract}
                    >
                        Thêm hợp đồng
                    </button>
                </div>
            </div>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Tên hợp đồng</th>
                        <th>Mã hợp đồng</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((item) => (
                            <tr key={item.id}>
                                <td>{item.fullname}</td>
                                <td>{item.name}</td>
                                <td>
                                    <button
                                        className="editButton"
                                        onClick={() => handleViewEdit(item.id)}
                                    >
                                        Xem/Sửa
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisHDayed={3}
            />
        
        {showModal &&
            <ContractForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                contract={selectedData}
                refreshData={fetchData}
            />

    }  
        </div>
    );
};

export default ManageContract;
