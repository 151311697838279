import './css/index.css';
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppRouter from './app/router/AppRouter';
import { ToastContainer } from 'react-toastify';
import '../node_modules/react-toastify/dist/ReactToastify.css'
import { Provider } from 'react-redux';
import store from './redux/store';
import { initializeUserRoleId } from './redux/slices/userSlice';
const container = document.getElementById('root');
const root = createRoot(container); // Use createRoot to manage the root

store.dispatch(initializeUserRoleId());
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <AppRouter />
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
