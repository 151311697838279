import React, { useState, useEffect } from "react";
import { base_url } from "../../api/utils/baseURL";
import axios from "axios";
import ReportForm from "./Form/ReportForm";
import Pagination from "../Pagination";

const ManageReport = () => {
    // Manage State
    const [pageCount, setPageCount] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedData, setSelectedData] = useState()
    const [showModal, setShowModal] = useState(false)

    const fetchData = async () => {
        try {
            const response = await axios.get(`${base_url}LoaiBaoCao/getListBC?page=${currentPage + 1}`);
            setFilteredData(response.data.result.data);
            setPageCount(Math.ceil(response.data.result.total / 10));
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const handleAddingReport = async () => {
        setSelectedData(null);
        setShowModal(true)
    };

    const handleViewEdit = async (id) => {
        try {
            const response = await axios.get(
                `${base_url}LoaiBaoCao/getDetailBC/${id}`
            );
            const reportData = response.data;
            setSelectedData(reportData);
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching report details", error);
        }
    };

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    }

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{"Danh mục Báo cáo"}</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddingReport}>
                        Thêm loại báo cáo
                    </button>
                </div>
            </div>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Tên loại báo cáo</th>
                        <th>Mã loại báo cáo</th>
                        <th>Kiểm toán viên ký</th>
                        <th>Giới hạn ký (năm)</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((item) => (
                            <tr key={item.id}>
                                <td>{item.fullname}</td>
                                <td>{item.name}</td>
                                <td>{item.KTKy ? "Có" : "Không"}</td>
                                <td>{item.KTKy ? item.ThoiGian : "0"}</td>
                                <td>
                                    <button
                                        className="editButton"
                                        onClick={() => handleViewEdit(item.id)}
                                    >
                                        Xem/Sửa
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {showModal &&
                <ReportForm
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    refreshData={fetchData}
                    reportData={selectedData}
                />
            }
            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
            />



        </div>
    );
};

export default ManageReport;
