import moment from "moment";

export const validateRequiredFields = (formData, requiredFields) => {
    const newErrors = {};

    requiredFields.forEach(field => {
        if (!formData[field]) {
            newErrors[field] = 'Trường này là bắt buộc!';
        }
    });

    return newErrors;
};
export const validateMissionReportRequiredFields = (formData) => {
    const missionContractError = {};
    if (Array.isArray(formData?.listNhiemVu)) {
        for (const [index, baoCao] of formData.listNhiemVu.entries()) {
            if (!baoCao.MaLoaiBaoCao) {
                missionContractError[`MaLoaiBaoCao_${index}`] = "Bắt buộc nhập mã BC!";
            }
            if (!baoCao.ThoiGianHoanThanh) {
                missionContractError[`ThoiGianHoanThanh_${index}`] = "Bắt buộc nhập!";
            }
        }
    }
    return missionContractError;
};

export const formatDateToISO = (date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    if (typeof date.getFullYear !== 'function') {
        return null;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
export const formatDateToDDMMYYYY = (date) => {
    if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
        return date; // Trả về ngày đã được định dạng nếu đã ở dạng dd/mm/yyyy
    }

    if (!(date instanceof Date)) {
        return ''; // hoặc giá trị mặc định tùy vào yêu cầu của bạn khi không phải là đối tượng Date
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

export const formattedDate = (data) => moment(data, "YYYY-MM-DD").toISOString();
