import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser } from '../../api/feartures/userService';


// Thunk để gọi hàm getUser
export const fetchUser = createAsyncThunk('user/fetchUser', async (token) => {
    const response = await getUser(token);
    console.log(response, 'response');
    return response;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userInfo: null,
        loading: false,
        error: null
    },
    reducers: {
        updateUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        updateUserRoleId: (state, action) => {
            state.userRoleId = action.payload;
        },
        initializeUserRoleId: (state) => {
            const roleId = localStorage.getItem('role_id');
            if (roleId) {
                state.userRoleId = parseInt(roleId, 10);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userInfo = action.payload;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});
export const { updateUserInfo, updateUserRoleId, initializeUserRoleId } = userSlice.actions;

export const getUserInfo = (state) => state.user.userInfo;
export const getUserRoleId = (state) => state.user.userRoleId;

export default userSlice.reducer;