import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from 'react-paginate';
import "../../css/phongtonghop/paginate.css"
import "./../../css/index.css";
import PopUpDetailProgress from "./popUpDetailProgress";
import ContractDetails from "../overlay/contractDetails";
import PhuLucDetails from "../overlay/phuLucDetail";
import {
    getContractList,
    getDetailHD,
    getDetailPL,
    getDetailTL,
    getProcessReportofHD,
    getProcessReportofPL,
    searchContract
} from "../../api/feartures/contractService";
import ThanhLyDetails from "../overlay/thanhLyDetail";
import './style.scss'
import { getStatusColor, getStatusText } from "../../api/utils/constant";
function PTHhomepage() {
    const [contractData, setContractData] = useState([]);
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isOpenDetailPL, setOpenDetailPL] = useState(false);
    const [isOpenDetailTL, setOpenDetailTL] = useState(false);


    //createHDTL
    const [isOpenCreateHDTL, setOpenCreateTLHD] = useState(false);
    const [dataThanhLy, setDataThanhLy] = useState(null);
    // const [isOpenCreatePLTL,setOpenCreatePLTL] = useState(false);

    const [selectedContractId, setSelectedContractId] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [detailHD, setDetailHD] = useState(null);
    const [detailPL, setDetailPL] = useState(null);
    const [detailTL, setDetailTL] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const hasFetchedData = useRef({}); // Sử dụng đối tượng để theo dõi các trang đã tải
    const [status, setStatus] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getContractList(currentPage); // 10 là số lượng bản ghi trên mỗi trang
                setContractData(response.result.data);
                setPageCount(Math.ceil(response.result.total / 10)); // Giả sử API trả về tổng số bản ghi
            } catch (error) {
                console.error('Error fetching contract list:', error);
            }
        };
        if (!hasFetchedData.current[currentPage]) {
            fetchData();
            hasFetchedData.current[currentPage] = true; // Đánh dấu trang đã được tải
        }
    }, [currentPage]);

    const toggleReports = (id) => {
        if (selectedContractId === id) {
            setSelectedContractId(null);
        } else {
            setSelectedContractId(id);
        }
    };

    const xemTienDoHD = async (id, SoHopDong, TrangThai) => {
        const contract = await getProcessReportofHD(id);
        contract.HopDongID = id;
        contract.SoHopDong = SoHopDong;
        contract.PhuLucID = null;
        setSelectedContract(contract);
        setPopupVisible(true);
        setStatus(TrangThai)
    };


    const xemTienDoPL = async (id, SoHopDong, TrangThai) => {
        const process = await getProcessReportofPL(id)
        process.HopDongID = null;
        process.SoHopDong = SoHopDong;
        process.PhuLucID = id;
        setSelectedContract(process);
        setPopupVisible(true);
        setStatus(TrangThai)
    };


    const closePopup = () => {
        setPopupVisible(false);
        setSelectedContract(null);
    };

    const viewDetailHD = async (id) => {
        const dataDetailHD = await getDetailHD(id);
        setDetailHD(dataDetailHD);
        setOverlayOpen(true);
    };

    const viewDetailPL = async (id) => {
        const dataDetailPL = await getDetailPL(id);
        setDetailPL(dataDetailPL);
        setOpenDetailPL(true);
    };

    const ViewThanhLy = async (id) => {
        const dataDetailTL = await getDetailTL(id);
        setDetailTL(dataDetailTL);
        setOpenDetailTL(true);
    }

    const createThanhLysHD = async (HopDongID, SoHopDong) => {
        const dataThanhLy = {
            HopDongID,
            SoHopDong
        }
        setDataThanhLy(dataThanhLy)
        setOpenCreateTLHD(true);
    }

    const handlePageClick = async (event) => {
        const selectedPage = event.selected + 1; // Vì event.selected bắt đầu từ 0
        setCurrentPage(selectedPage); // Cập nhật trang hiện tại

        // Kiểm tra event.selected có giá trị hợp lệ (số trang) hay không trước khi gọi API
        if (!isNaN(selectedPage)) {
            try {
                const response = await getContractList(selectedPage); // Gọi API để lấy dữ liệu trang mới
                setContractData(response.result.data); // Cập nhật dữ liệu mới từ API
                setPageCount(Math.ceil(response.result.total / 10)); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching contract list:', error);
            }
        }
    };

    ////search
    const [searchValues, setSearchValues] = useState({
        TenKhachHang: '',
        SoHopDong: '',
        TrangThai: '',
    });
    useEffect(() => {
        handleSearch()
    }, [searchValues])
    const handleSearch = async () => {
        const { TenKhachHang, SoHopDong, TrangThai } = searchValues;
        try {
            const response = await searchContract(
                currentPage,
                TenKhachHang,
                SoHopDong,
                TrangThai
            );
            setContractData(response.result.data);
            setPageCount(Math.ceil(response.result.total / 10));
        } catch (error) {
            console.error('Error searching contracts:', error);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };



    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto p-4">
                <span className="font-bold text-lg">Danh sách hợp đồng</span>

                {/* đoạn đầu */}
                <div className="flex justify-between items-center flex-wrap mb-4">
                    <div className="flex items-center space-x-4 mb-2 md:mb-0">
                        <div className="flex space-x-4 items-center mt-4 md:mt-0">
                            {/* Ô input để nhập Số Hợp Đồng */}
                            <input
                                type="text"
                                placeholder="Nhập Số Hợp Đồng"
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.SoHopDong}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, SoHopDong: e.target.value })
                                }
                                onKeyDown={handleKeyDown}
                            />
                            <input
                                type="text"
                                placeholder="Nhập tên Khách Hàng"
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.TenKhachHang}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, TenKhachHang: e.target.value })
                                }
                                onKeyDown={handleKeyDown}
                            />
                            {/* Dropdown để chọn Trạng Thái */}
                            <select
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.TrangThai}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, TrangThai: e.target.value })
                                }
                            >
                                <option value="">Tất cả trạng thái<i></i></option>
                                <option value="DANG_THUC_HIEN">Đang thực hiện</option>
                                <option value="HOAN_THANH">Đã hoàn thành</option>
                                <option value="CHO_DUYET">Chờ duyệt</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md focus:outline-none transition duration-300 ease-in-out transform hover:bg-blue-200">
                            <i className="fa-solid fa-arrow-down-wide-short"></i> <span></span>
                            Sắp xếp
                        </button>
                    </div>
                </div>



                {/* đoạn sau */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                        <thead className="bg-blue-100">
                            <tr>
                                <th className="py-2 px-4 text-left text-sm text-gray-600"></th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">STT</th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">Mã hợp đồng</th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">Khách hàng</th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">Tiến độ báo cáo</th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">Thanh lý</th>
                                <th className="py-2 px-4 text-left text-sm text-gray-600">Trạng thái</th>

                            </tr>
                        </thead>

                        <tbody>
                            {contractData.map((contract, index) => (
                                <React.Fragment key={contract.id}>
                                    <tr className="border-t">
                                        <td className="py-2 px-4 text-sm text-gray-700">
                                            <button
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
                                                onClick={() => toggleReports(contract.id)}
                                            >
                                                {selectedContractId === contract.id ? "-" : "+"}
                                            </button>
                                        </td>
                                        <td className="py-2 px-4 text-sm text-gray-700">{index + 1 + (currentPage - 1) * 10}</td>
                                        <td className="cursor-pointer py-2 px-4 text-sm text-blue-500 hover:bg-sky-100" onClick={() => viewDetailHD(contract.id)}>{contract.MaHDCuaKH ? `${contract.MaHDCuaKH} - ${contract.SoHopDong}` : contract.SoHopDong}</td>
                                        <td className="cursor-pointer py-2 px-4 text-sm text-blue-500 hover:bg-sky-100">{contract.KhachHang.name}</td>
                                        <td className="py-2 px-4 text-sm" onClick={() => xemTienDoHD(contract.id, contract.SoHopDong, contract.TrangThai)}>
                                            <div className="w-full bg-gray-200 rounded-full h-2.5 cursor-pointer">
                                                <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${contract.TongBaoCaos > 0 ? (contract.BaoCaos * 100) / contract.TongBaoCaos : 0}%` }}></div>
                                            </div>
                                            <div className="text-right text-gray-500 text-xs">  {`${contract.BaoCaos}/${contract.TongBaoCaos}`}</div>
                                        </td>
                                        <td className="py-2 px-4 text-sm">
                                            <button
                                                className={`px-4 py-2 rounded-md focus:outline-none transition duration-300 ease-in-out transform 
                           ${contract.ThanhLys && contract.ThanhLys.id != null
                                                        ? "bg-green-500 text-white hover:bg-green-800"
                                                        : "bg-gray-200 text-gray-700"
                                                    }`}
                                                disabled={!(contract.ThanhLys && contract.ThanhLys.id != null)}
                                                onClick={() => ViewThanhLy(contract.ThanhLys.id)}
                                            >
                                                <span> </span>
                                                Xem thanh lý
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <span className={`block ${getStatusColor(contract.TrangThai)} text-xs font-semibold px-2 py-1 rounded-full`}>
                                                {getStatusText(contract.TrangThai)}
                                            </span> </td>
                                    </tr>
                                    {selectedContractId === contract.id &&
                                        contract.PhuLucs.rows.map((phuLuc) => (
                                            <tr key={phuLuc.SoPhuLuc} className="bg-blue-50 ">
                                                <td className="py-2 px-4 text-sm text-gray- 700"></td>
                                                <td className="py-2 px-4 text-sm text-gray-700"></td>
                                                <td className="py-2 px-4 text-sm text-blue-500 cursor-pointer  hover:bg-sky-100" onClick={() => viewDetailPL(phuLuc.id)}>{phuLuc.SoPhuLuc}</td>
                                                <td className="py-2 px-4 text-sm text-blue-500 cursor-pointer  hover:bg-sky-100">{contract.KhachHang.name}</td>
                                                <td className="py-2 px-4 text-sm" onClick={() => xemTienDoPL(phuLuc.id, contract.id, contract.SoHopDong, phuLuc.TrangThai)}>
                                                    <div className="w-full bg-gray-200 rounded-full h-2.5 cursor-pointer">
                                                        <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: phuLuc.TongBaoCaos > 0 ? `${(phuLuc.BaoCaos * 100) / (phuLuc.TongBaoCaos)}%` : `0%` }}></div>
                                                    </div>
                                                    <div className="text-right text-gray-500 text-xs">{phuLuc.BaoCaos}/{phuLuc.TongBaoCaos}</div>
                                                </td>
                                                <td className="py-2 px-4 text-sm text-blue-500"></td>
                                                <td>
                                                    <span className={`block ${getStatusColor(phuLuc.TrangThai)} text-center text-xs font-semibold px-2 py-1 rounded-full`}>
                                                        {getStatusText(phuLuc.TrangThai)}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                <ReactPaginate
                    previousLabel={"Trước"}
                    nextLabel={"Sau"}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    disableInitialCallback={true} // Ngăn cản callback mặc định
                />

            </div>

            {isOverlayOpen && (<ContractDetails onClose={() => setOverlayOpen(false)} contract={detailHD} />)}
            {isOpenDetailPL && (<PhuLucDetails onClose={() => setOpenDetailPL(false)} phuLuc={detailPL} />)}
            {isOpenDetailTL && (<ThanhLyDetails onClose={() => setOpenDetailTL(false)} thanhLy={detailTL} />)}
            {isPopupVisible && <PopUpDetailProgress
                onClose={closePopup} contract={selectedContract}
                HopDongID={selectedContract?.HopDongID}
                PhuLucID={selectedContract?.PhuLucID}
                SoHopDong={selectedContract?.SoHopDong}
                status={status}
            />}
        </div>
    );
}

export default PTHhomepage;