import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url } from "../../../api/utils/baseURL";

const ReportForm = ({ show, handleClose, reportData, refreshData }) => {
    const [formValues, setFormValues] = useState(
        reportData || {
            fullname: "",
            name: "",
            KTKy: false,
            ThoiGian: "" || 0,
        }
    );


    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const getAccessToken = () => localStorage.getItem("accessToken");

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };


    const validateForm = () => {
        const fieldsToValidate = [
            { label: "Tên loại báo cáo", name: "fullname" },
            { label: "Mã loại báo cáo", name: "name" },
        ];

        for (const field of fieldsToValidate) {
            const value = formValues[field.name];
            if (!value || value.toString().trim() === "") {
                toast.error(`${field.label} không được để trống`);
                return false;
            }
        }
        return true;
    };

    const handleSubmitForm = async () => {
        if (!validateForm()) return;
        setIsConfirmModalOpen(true);
    };


    const handleDeleteReport = async () => {
        try {
            const token = getAccessToken();
            await axios.delete(`${base_url}LoaiBaoCao/deleteLoaiBC/${reportData.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Xóa loại báo cáo thành công");
            refreshData();
            handleClose();
        } catch (error) {
            toast.error("Lỗi khi xóa báo cáo");
            console.error("Error deleting customer:", error);
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = getAccessToken();
            if (reportData) {
                await axios.put(`${base_url}LoaiBaoCao/updateLoaiBC/${reportData.id}`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Cập nhật loại báo cáo thành công");
            } else {
                await axios.post(`${base_url}LoaiBaoCao/createLoaiBC`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Tạo loại báo cáo thành công");
            }
            refreshData();
            handleClose();
        } catch (error) {
            if (error.response.status === 400) {
                toast.error("Đã tồn tại loại báo cáo này");
            }
            else {
                toast.error("Có lỗi xảy ra khi tạo báo cáo");
            }
            console.error("Error submitting form:", error);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    if (!show) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={handleClose}>
                    &times;
                </span>
                <h2>{reportData ? "Cập nhật loại báo cáo" : "Thêm mới loại báo cáo"}</h2>

                <div className="modal-body">
                    {[
                        { label: "Tên loại báo cáo", name: "fullname" },
                        { label: "Mã loại báo cáo", name: "name" },
                        { label: "Kiểm toán viên ký", name: "KTKy", type: "checkbox" },
                    ].map((field) => (
                        <div className="form-group" key={field.name}>
                            <label>{field.label}</label>
                            {field.type === "checkbox" ? (
                                <input
                                    type="checkbox"
                                    name="KTKy"
                                    checked={formValues.KTKy}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name={field.name}
                                    value={formValues[field.name] || ""}
                                    onChange={handleInputChange}
                                />
                            )}
                        </div>
                    ))}
                    {formValues.KTKy && (
                        <div className="form-group">
                            <label>Giới hạn ký (năm)</label>
                            <input
                                type="text"
                                name="ThoiGian"
                                value={formValues.ThoiGian || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}
                </div>

                <div className="modal-footer">
                    <button onClick={handleSubmitForm} className="submitButton">
                        Lưu
                    </button>
                    {reportData && (
                        <button
                            onClick={() => setIsDeleteModalOpen(true)}
                            className="deleteButton"
                        >
                            Xóa
                        </button>
                    )}
                </div>

                {/* Modal xác nhận lưu */}
                {isConfirmModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>
                                {reportData
                                    ? "Bạn có chắc chắn muốn cập nhật báo cáo này không?"
                                    : "Bạn có chắc chắn muốn tạo loại báo cáo mới không?"}
                            </h3>
                            <div className="confirm-actions">
                                <button onClick={handleConfirmSubmit}>Lưu</button>
                                <button onClick={() => setIsConfirmModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal xác nhận xóa */}
                {isDeleteModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>Bạn có chắc chắn muốn xóa loại báo cáo này không?</h3>
                            <div className="confirm-actions">
                                <button onClick={handleDeleteReport}>Xóa</button>
                                <button onClick={() => setIsDeleteModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportForm;
