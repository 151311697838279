import React, { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../../api/utils/baseURL";
import "../ManageAdmin.css";
import { toast } from "react-toastify";
import { getCustomerTypesData } from "../../../api/feartures/customerTypeService";

const CustomerForm = ({ isOpen, onClose, customer, refreshData }) => {
    const [customerTypeData, setCustomerTypeData] = useState([]);
    const fetchData = async () => {
        try {
            const result = await getCustomerTypesData();
            setCustomerTypeData(result.data);
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const [formValues, setFormValues] = useState(
        customer || {
            name: "",
            MST: "",
            SDT: "",
            email: "",
            DiaChi: "",
            TenNguoiDaiDien: "",
            MaLoaiKH: "",
            MaKhachHang: "",
        }
    );

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const getAccessToken = () => localStorage.getItem("accessToken");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const validateForm = () => {
        const fieldLabels = {
            name: "Tên công ty",
            MST: "Mã số thuế",
            MaKhachHang: "Mã khách hàng",
            MaLoaiKH: "Mã loại khách hàng",
            SDT: "Số điện thoại",
            email: "Email",
            DiaChi: "Địa chỉ",
            TenNguoiDaiDien: "Tên người đại diện",
        };

        for (const [key, value] of Object.entries(formValues)) {
            if (!value && key !== 'SDT' && key !== 'email' && key !== 'DiaChi' && key !== 'TenNguoiDaiDien') {
                toast.error(`${fieldLabels[key]} không được để trống`);
                return false;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;

        if (formValues.email && !emailRegex.test(formValues.email)) {
            toast.error("Email không hợp lệ");
            return false;
        }
        if (formValues.SDT && !phoneRegex.test(formValues.SDT)) {
            toast.error("Số điện thoại phải có đúng 10 chữ số");
            return false;
        }
        return true;
    };

    const handleSubmitForm = async () => {
        if (!validateForm()) return;
        setIsConfirmModalOpen(true);
    };

    const handleDeleteCustomer = async () => {
        try {
            const token = getAccessToken();
            await axios.delete(`${base_url}customer/${customer.id}/delete`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Xóa khách hàng thành công");
            refreshData();
            onClose();
        } catch (error) {
            toast.error("Lỗi khi xóa khách hàng");
            console.error("Error deleting customer:", error);
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = getAccessToken();
            if (customer) {
                await axios.put(`${base_url}customer/update`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Cập nhật khách hàng thành công");
            } else {
                await axios.post(`${base_url}customer/create`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Tạo khách hàng thành công");
            }
            refreshData();
            onClose();
        } catch (error) {
            if (error.response.status === 400) {
                toast.error("Đã tồn tại mã khách hàng hoặc mã số thuế");
            }
            else {
                toast.error("Lỗi khi lưu khách hàng");
            }
            console.error("Error submitting form:", error);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    return isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h2>{customer ? "Cập nhật khách hàng" : "Thêm mới khách hàng"}</h2>

                <div className="modal-body">
                    {[
                        { label: "Tên công ty", name: "name" },
                        { label: "Mã số thuế", name: "MST" },
                        { label: "Mã loại khách hàng", name: "MaLoaiKH", type: "select" }, // Đánh dấu là select
                        { label: "Mã khách hàng", name: "MaKhachHang" },
                        { label: "Số điện thoại", name: "SDT" },
                        { label: "Email", name: "email" },
                        { label: "Địa chỉ", name: "DiaChi" },
                        { label: "Tên người đại diện", name: "TenNguoiDaiDien" },
                    ].map((field) => (
                        <div className="form-group" key={field.name}>
                            <label>{field.label}</label>

                            {field.type === "select" ? (
                                <select
                                    name={field.name}
                                    value={formValues[field.name] || ""}
                                    onChange={handleInputChange}
                                >
                                    <option value="">-- Chọn danh mục khách hàng --</option>
                                    {customerTypeData.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    name={field.name}
                                    value={formValues[field.name] || ""}
                                    onChange={handleInputChange}
                                />
                            )}
                        </div>
                    ))}
                </div>

                <div className="modal-footer">
                    <button onClick={handleSubmitForm} className="submitButton">
                        Lưu
                    </button>
                    {customer && (
                        <button
                            onClick={() => setIsDeleteModalOpen(true)}
                            className="deleteButton"
                        >
                            Xóa người dùng
                        </button>
                    )}
                </div>

                {/* Modal xác nhận */}
                {isConfirmModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>
                                {customer
                                    ? "Bạn có chắc chắn sửa không?"
                                    : "Bạn có chắc chắn tạo khách hàng không?"}
                            </h3>
                            <div className="confirm-actions">
                                <button onClick={handleConfirmSubmit}>Lưu</button>
                                <button onClick={() => setIsConfirmModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal xác nhận xóa */}
                {isDeleteModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>Bạn có chắc chắn muốn xóa người dùng không?</h3>
                            <div className="confirm-actions">
                                <button onClick={handleDeleteCustomer}>Xóa</button>
                                <button onClick={() => setIsDeleteModalOpen(false)}>
                                    Quay lại
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default CustomerForm;
