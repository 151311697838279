// customerService.js
import axios from 'axios';
import { base_url } from '../utils/baseURL';
import { config } from '../utils/axiosConfig';

export const getCustomers = async () => {
    try {
        const res = await axios.get(`${base_url}contract/list-KH`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching customers:', error);
        throw error;
    }
}

export const getCustomersData = async (page) => {
    try {
        const response = await axios.get(`${base_url}customer/list?page=${page + 1}`);
        return {
            data: response.data.result.data,
            total: response.data.result.total,
        };
    } catch (error) {
        console.error("Error fetching customer data:", error);
        throw error;
    }
};

export const getCustomerDetail = async (id, token) => {
    try {
        const response = await axios.get(`${base_url}customer/${id}/detail`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error("Error fetching customer details:", error);
        throw error;
    }
};