
import axios from 'axios';
import { base_url } from "../utils/baseURL";
import { config } from "../utils/axiosConfig";

export const getStat = async () => {
    try {
        const res = await axios.get(`${base_url}generalRoom/getStat`, config);
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error fetching department list:', error);
        throw error;
    }
};
