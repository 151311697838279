import React, { useEffect, useState } from "react";
import { MdLogout } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Modal from "../modal";
import Sidebar from "../sidebar/sidebar";
import { ROLE_ID } from "../../api/utils/constant";
// import './style.scss'
function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    let userName = localStorage.getItem('userName');



    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const logOut = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleConfirm = () => {
        localStorage.clear();
        navigate('/');
        setIsOpen(false);
    };

    const goToUserProfile = () => {
        navigate('/user-profile'); // Điều hướng đến component UserProfile
    };

    return (
        <nav className="bg-white shadow-lg p-4">
            <div className="flex justify-between items-center max-w-7xl mx-auto">
                <div className="flex items-center">
                    {ROLE_ID === "2" || ROLE_ID === "3" ? (
                        <button
                            className="text-gray-500 focus:outline-none mr-4"
                            onClick={toggleSidebar}
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                ></path>
                            </svg>
                        </button>
                    ) : <></>}
                </div>
                <div className="flex items-center space-x-4">
                    <div className="text-gray-700" onClick={goToUserProfile} style={{ cursor: 'pointer' }}>{userName}</div>
                    <div className="relative">
                        <button className="text-gray-500 focus:outline-none">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14V9a6 6 0 10-12 0v5c0 .538-.214 1.057-.595 1.595L4 17h5"
                                ></path>
                            </svg>
                        </button>
                        <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span>
                    </div>
                    <MdLogout
                        className="iconLogOut text-gray-500 hover:text-red-500 cursor-pointer"
                        onClick={logOut}
                    />
                    <Modal
                        isOpen={isOpen}
                        onClose={handleClose}
                        onConfirm={handleConfirm}
                        message="Bạn có chắc chắn muốn đăng xuất không?"
                    />
                </div>
            </div>
            <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
        </nav>
    );
}

export default Header;
