import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { getPhuLucTypes, confirmVB } from '../../api/feartures/contractService';
// import { getCustomers } from '../../redux/feartures/customerService';
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css'
import { useDateUtils } from "../../hooks/useDateUtils";
import { useFormUtils } from "../../hooks/useFormUtils";
import { useCurrencyUtils } from "../../hooks/useCurrencyUtils";
import Modal from '../modal';
import { toast } from 'react-toastify';
import ModalText from '../modal/modalText';
import Note from './noteDetail';
import { CHO_DUYET, DA_NHAN_XET, LuuY_LIST, ROLE_ID, getStatusColor, getStatusText } from '../../api/utils/constant';
import { formatDateToDDMMYYYY, formatDateToISO } from '../../api/utils/common';
import { updatePhuLuc } from '../../api/feartures/phuLucService';
import moment from 'moment';
import CreateNhiemVu from '../create/newNhiemVu';
function PhuLucDetails({ onClose, phuLuc }) {
    let GiaTriTruocVAT = phuLuc.GiaTriTruocVAT ? phuLuc.GiaTriTruocVAT.split('.')[0] : null;
    let GiaTriSauVAT = phuLuc.GiaTriSauVAT ? phuLuc.GiaTriSauVAT.split('.')[0] : null;
    let TongGiaTri = phuLuc.TongGiaTri ? phuLuc.TongGiaTri.split('.')[0] : null;
    const [selectedContract, setSelectedContractID] = useState(null);
    const [status, setStatus] = useState('')
    const [isPopupVisible, setPopupVisible] = useState(false);
    const closePopup = () => {
        setPopupVisible(false);
        setSelectedContractID(null);
    };
    const { parseDateString, RealDateString } = useDateUtils();
    const { formData, handleInputChange, handleDateChange } = useFormUtils({
        ...phuLuc,
        created_at: phuLuc.created_at ? RealDateString(phuLuc.created_at) : null,
        NgayGhiThucTe: phuLuc.NgayGhiThucTe ? RealDateString(phuLuc.NgayGhiThucTe) : null,
        ThoiGianHieuLuc: phuLuc.ThoiGianHieuLuc ? parseDateString(phuLuc.ThoiGianHieuLuc) : null,
        GiaTriTruocVAT: GiaTriTruocVAT,
        GiaTriSauVAT: GiaTriSauVAT,
        TongGiaTri: TongGiaTri,
    });
    const { formatCurrency } = useCurrencyUtils();

    const [phuLucTypes, setPhuLucTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    // const [customers, setCustomers] = useState([]);
    const hasFetchedData = useRef(false);

    //Modal
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState(" ")

    const openModal = async (message) => {
        setModalIsOpen(true)
        setModalMessage(message);
    }
    const closeModal = async () => {
        setModalIsOpen(false)
        setModalTextIsOpen(false);
    }
    const handleModalConfirm = async (phuLuc) => {
        const LoaiVB = "Phu luc";
        try {
            toast.success("Duyệt văn bản thành công, reload lại trang để xem cập nhật");
            const data = await confirmVB(phuLuc.id, LoaiVB);
            setModalIsOpen(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    }
    const handleModal = () => {
        if (phuLuc.TrangThai === "HOAN_THANH") {
            toast.warn("Văn bản này đã hoàn thành rồi!")
        } else if (phuLuc.TrangThai === "DANG_THUC_HIEN") {
            toast.warn("Văn bản này đã được duyệt rồi")
        } else {
            openModal("Bạn có chắc chắn duyệt Phụ lục này?");
        }
    }

    //Note

    const [modalTextIsOpen, setModalTextIsOpen] = useState(false);

    const [openNote, setOpenNote] = useState(false);
    const [vanBanID, setVanBanID] = useState(null);

    const handleRefuse = async (phuLuc) => {
        if (phuLuc.TrangThai === "CHO_DUYET") {
            setVanBanID(phuLuc.id);
            openModalText("Thêm ghi chú cho báo cáo này:");
        }
        else {
            toast.error("Không thể từ chối văn bản này")
        }
    };

    const openModalText = (message) => {
        setModalTextIsOpen(true);
        setModalMessage(message);
    }


    const handleOpenNote = (id) => {
        console.log("id = ", id)
        setVanBanID(id);
        setOpenNote(true);
    }


    ///////


    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Fetching data...');
                const [phuLucTypesData, boardMembersData] = await Promise.all([
                    getPhuLucTypes(),
                    getBoardMembers(),
                    // getCustomers()
                ]);
                setPhuLucTypes(phuLucTypesData);
                setBoardMembers(boardMembersData);
                // setCustomers(customersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);

    const handleSave = async () => {
        const formattedData = {
            ...formData,
            // ThoiGianHieuLuc: formatDateToISO(formData.ThoiGianHieuLuc),
            NgayGhiThucTe: formatDateToDDMMYYYY(formData.NgayGhiThucTe),
            created_at: formatDateToISO(formData.created_at),
        };
        try {
            const response = await updatePhuLuc(formattedData, localStorage.getItem("accessToken"));
            if (response?.result) {
                toast.success('Cập nhật phụ lục thành công!');
                onClose()
            }
        } catch (error) {
            console.error('Error updating contract:', error);
            toast.error('Có lỗi xảy ra khi cập nhật hợp đồng.');
        }
    };

    const handleClickOutside = (event) => {
        event.stopPropagation();
    };

    const role_id = localStorage.getItem("role_id");
    const xemTienDoPL = async (id, TrangThai) => {
        setStatus(TrangThai)
        setSelectedContractID(id);
        setPopupVisible(true);
    };

    return (

        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div>
                <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                    style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
                >
                    <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                        <i className="fa-solid fa-rectangle-xmark"></i>
                    </button>
                    <div className="flex h-full">
                        <div className="w-1/2 flex flex-col">
                            <div>
                                <h1 className="font-bold">Chi tiết phụ lục</h1>
                                <span className={`block ${getStatusColor(phuLuc.TrangThai)} text-sm  font-bold px-0.5 py-2 rounded-full`}>
                                    {getStatusText(phuLuc.TrangThai)}
                                </span>
                            </div>
                            <div className="overflow-auto p-4 flex-grow">
                                {[
                                    { label: 'Thuộc hợp đồng', name: 'SoHopDong', value: phuLuc.HopDong.SoHopDong },
                                    { label: 'Số phụ lục', name: 'SoPhuLuc' },
                                    { label: 'Số phụ lục của khách hàng', name: 'MaPLCuaKH', value: phuLuc.MaPLCuaKH || '' },
                                    { label: 'Ngày khởi tạo', name: 'created_at', type: 'dateKT' },
                                    { label: 'Ngày ghi thực tế trên phụ lục', name: 'NgayGhiThucTe', type: 'date' },
                                    { label: 'Loại phụ lục', name: 'MaLoaiTL', type: 'select', options: phuLucTypes },
                                    { label: 'Số bản', name: 'SoBan' },
                                    ...(ROLE_ID === "2" ? [{ label: 'Số lưu', name: 'SoLuu' }, { label: 'Lưu ý', name: 'LuuY', type: 'select', options: LuuY_LIST },
                                    { label: 'Ghi chú', name: 'Note' }] : []),
                                    //{ label: 'Thời gian hiệu lực phụ lục', name: 'ThoiGianHieuLuc', type: 'date' },
                                    { label: 'Giá trị trước VAT', name: 'GiaTriTruocVAT', format: 'currency' },
                                    { label: 'Giá trị sau VAT', name: 'GiaTriSauVAT', format: 'currency' },
                                    { label: 'Nội dung', name: 'Noidung', type: 'textarea' },
                                    { label: 'Phòng ban thực hiện', name: 'DonViThucHien', type: 'text', value: phuLuc.NguoiNhap?.DonVi?.name },
                                    { label: 'Người nhập', name: 'MaNguoiNhap', type: 'text', value: phuLuc.NguoiNhap?.HoTen },
                                    { label: 'Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: boardMembers },
                                    { label: 'Link Drive', name: 'LinkDrive' },
                                ].map((field, idx) => (
                                    <div className="mb-2" key={idx}>
                                        <label className="font-bold">{field.label}:</label>
                                        {field.type === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        ) : field.type === 'select' ? (
                                            <select
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name] || ''}
                                                onChange={handleInputChange}
                                            >
                                                {field.options.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name || option.HoTen}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.type === 'date' ? (
                                            <DatePicker
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />
                                        ) : field.type === 'dateKT' ? (
                                            <DatePicker
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                                readOnly
                                            />
                                        ) : field.type === 'text' ? (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.value}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={
                                                    field.format === 'currency'
                                                        ? formatCurrency(formData[field.name])
                                                        : (field.name === "SoPhuLuc")
                                                            ? phuLuc.MaPLCuaKH
                                                                ? `${phuLuc.MaPLCuaKH} - ${phuLuc.SoPhuLuc}`
                                                                : `${phuLuc.SoPhuLuc}`
                                                            : (field.name === "SoHopDong")
                                                                ? phuLuc.HopDong.MaHDCuaKH
                                                                    ? `${phuLuc.HopDong.MaHDCuaKH} - ${phuLuc.HopDong.SoHopDong}`
                                                                    : `${phuLuc.HopDong.SoHopDong}`
                                                                : (field.value || formData[field.name])
                                                }

                                                onChange={handleInputChange}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <button onClick={() => xemTienDoPL(phuLuc.id, phuLuc.TrangThai)} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2">
                                    Danh sách nhiệm vụ
                                </button>
                        </div>
                        <div className="border-l border-gray-300 mx-4"></div>
                        <div className="w-1/2 flex flex-col">
                            <h1 className="font-bold">Xem trước:</h1>
                            <div className="flex-grow overflow-auto p-4">
                                <div className="mt-4 border p-4 rounded-md h-full">
                                    <img src={phuLuc.previewImage} alt="Preview" className="max-w-full h-auto" />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4 px-4">
                                {((phuLuc.TrangThai === CHO_DUYET || phuLuc.TrangThai === DA_NHAN_XET) || (role_id === '2')) && (
                                    <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2">
                                        Lưu
                                    </button>
                                )}
                                {role_id === '2' && (
                                    <>
                                        {phuLuc.TrangThai === CHO_DUYET && (
                                            <button onClick={() => handleRefuse(phuLuc)} className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded mr-2"
                                            >
                                                Từ chối
                                            </button>
                                        )}
                                        {(phuLuc.TrangThai === CHO_DUYET || phuLuc.TrangThai === DA_NHAN_XET) && (
                                            <button onClick={handleModal} className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded mr-2">
                                                Duyệt
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalIsOpen} onClose={closeModal} message={modalMessage} onConfirm={() => handleModalConfirm(phuLuc)} />
                <ModalText isOpen={modalTextIsOpen} onClose={closeModal} message={modalMessage} VanBanId={vanBanID} LoaiVB="Phu luc" />

            </div>
            <div>
                <button onClick={() => handleOpenNote(phuLuc.id)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                    <i class="fa-solid fa-comment"></i>
                </button>

            </div>
            {openNote && (<Note onClose={() => setOpenNote(false)} vanbanID={vanBanID} LoaiVB="Phu luc" />)}
            {isPopupVisible && <CreateNhiemVu onClose={closePopup} vanbanID={selectedContract} type="PL" status={status} />}

        </div>
    );
}

PhuLucDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    phuLuc: PropTypes.shape({
        SoHopDong: PropTypes.string,
        SoPhuLuc: PropTypes.string,
        MaPLCuaKH: PropTypes.string,
        created_at: PropTypes.string,
        NgayGhiThucTe: PropTypes.string,
        MaLoaiPL: PropTypes.number,
        // SoLuu: PropTypes.string,
        SoBan: PropTypes.string,
        Note: PropTypes.string,
        LuuY: PropTypes.string,
        TongGiaTri: PropTypes.string,
        // ThoiGianHieuLuc: PropTypes.string,
        // GiaTriTruocVAT: PropTypes.string,
        // GiaTriSauVAT: PropTypes.string,
        Noidung: PropTypes.string,

        // MaKhachHang: PropTypes.number,
        MaHDCuaKH: PropTypes.string,
        DonViThucHien: PropTypes.string,
        TenNguoiNhap: PropTypes.number,
        MaThanhVienBGD: PropTypes.number,
        LinkDrive: PropTypes.string,
    }).isRequired,
};

export default PhuLucDetails;