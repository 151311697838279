import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { getKTVKy, getKTVThucHien } from "../../api/feartures/baoCaoService";
import { useCurrencyUtils } from '../../hooks/useCurrencyUtils';
import { useDateUtils } from "../../hooks/useDateUtils";
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css';
import { validateRequiredFields } from '../../api/utils/common';
import { REQUIRED_BC_FIELD } from '../../api/utils/constant';
import ReactSelect from 'react-select';
import { customStyles } from './customStyle';

function CreateBaoCao({ onClose, onSave, baoCao }) {
    const [formData, setFormData] = useState({
        NhiemVuID: baoCao.id,
        NgayGhiThucTe: '',
        ThoiGianHieuLuc: '',
        MaLoaiBC: baoCao.MaLoaiBC,
        KyThuatVien: '',
        TruongNhom: '',
        SoBan: 0,
        Noidung: '',
        MaThanhVienBGD: '',
        LinkDrive: '',
        DanhSachKTV: 0,
        MaBCCuaKH: ''
    });

    const hasFetchedData = useRef(false);
    const [KTVKy, setKTVKy] = useState([])
    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [KTVKyData] = await Promise.all([
                    getKTVKy(baoCao.MaLoaiBC, baoCao.HopDongID),
                ]);
                setKTVKy(KTVKyData)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);
    const { formatCurrency } = useCurrencyUtils();
    const { postDate } = useDateUtils();

    const handleInputChange = (e, index, type) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors({ ...errors, [name]: '' });
    };

    const handleDateChange = (date, field, index, type) => {
        const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
        setFormData((prevData) => ({
            ...prevData,
            [field]: formattedDate,
        }));
        setErrors((prevErrors) => {
            const { [field]: _, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });
    };
    const handleDateChangeTGHL = (date, field, index, type) => {
        const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
        setFormData((prevData) => ({
            ...prevData,
            [field]: formattedDate,
            MaThanhVienBGD: null,
            DanhSachKTV: null,  
        }));
        setErrors((prevErrors) => {
            const { [field]: _, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });
    };
    const handleSave = () => {
        const newErrors = validateRequiredFields(formData, REQUIRED_BC_FIELD);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            const dataToSend = {
                ...formData,
                ThoiGianHieuLuc: postDate(formData.ThoiGianHieuLuc),
            };
            onSave(dataToSend);
        } catch (error) {
            console.error('Error saving new contract:', error);
        }
    }



    const handleClickOutside = (event) => {
        event.stopPropagation();
    };
    const selectedKTVId = formData.DanhSachKTV?.id;
    const selectedBGDId = formData.MaThanhVienBGD?.id;
    const getOptionDisabled = (id) => {
        return id === selectedKTVId || id === selectedBGDId;
    };
    return (
        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: 'slideDown 0.5s ease-out forwards' }}
            onClick={handleClickOutside}
        >
            <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                style={{ animation: 'slideDownContent 0.5s ease-out forwards' }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <div className="flex h-full">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col items-center">
                            <h1 className="font-bold mb-4 text-xl text-gray-600">Tạo mới báo cáo</h1>
                        </div>
                        <div className="overflow-auto p-4 flex-grow">
                            {[
                                { label: 'Thuộc hợp đồng', name: 'SoHopDong', type: 'fixed', readOnly: true },
                                { label: 'Số báo cáo của khách hàng', name: 'MaBCCuaKH' },
                                { label: '(*) Ngày ghi thực tế trên báo cáo', name: 'NgayGhiThucTe', type: 'date' },
                                { label: '(*) Loại báo cáo', name: 'LoaiBC', type: 'fixed' },
                                { label: '(*) Thời gian hiệu lực báo cáo', name: 'ThoiGianHieuLuc', type: 'date' },
                                { label: '(*) Số bản', name: 'SoBan', type: 'number', placeholder: 'Nhập số bản báo cáo' },
                                { label: '(*) Nội dung', name: 'Noidung', type: 'textarea' },
                                { label: '(*) Kỹ thuật viên', name: 'KyThuatVien' },
                                { label: '(*) Trưởng nhóm', name: 'TruongNhom' },
                                { label: '(*) Kiểm toán viên ký', name: 'DanhSachKTV', type: 'select', options: KTVKy },
                                { label: '(*) Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: KTVKy },
                                { label: 'Link Drive', name: 'LinkDrive' },
                            ].map((field, idx) => (
                                <div className="mb-2 flex items-center" key={idx}>
                                    <label className="font-bold w-1/3">{field.label}:</label>
                                    <div className="w-2/3">
                                        {errors[field.name] && <span className="text-red-500 text-sm">{errors[field.name]}</span>}
                                        {field.type === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        ) : field.type === 'fixed' ? (
                                            <p className={`w-full border rounded px-2 py-1`}>
                                                {baoCao[field.name]}
                                            </p>
                                            // ) : (field.type === 'select' && (field.name !== "DanhSachKTV") || (field.name !== "MaThanhVienBGD")) ? (
                                            //     <select
                                            //         name={field.name}
                                            //         className="w-full border rounded px-2 py-1"
                                            //         value={formData[field.name] || ''}
                                            //         onChange={handleInputChange}
                                            //     >
                                            //         <option value="">Chọn...</option>
                                            //         {field.options?.map((option) => (
                                            //             <option key={option.id} value={option.id}>
                                            //                 {option.name || option.HoTen}
                                            //             </option>
                                            //         ))}
                                            //     </select>
                                        ) : (field.type === 'select' && field.name === "DanhSachKTV") ? (
                                            <div className="w-full">
                                                {formData.ThoiGianHieuLuc ? (
                                                    <ReactSelect
                                                        name={field.name}
                                                        value={{
                                                            value: formData.DanhSachKTV?.id || '',
                                                            label: formData.DanhSachKTV?.NhanVien?.HoTen || '',
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            const selectedKTV = field.options?.ListKTV.find(option => option.id === selectedOption.id)
                                                                || field.options?.ListKTVKyLienTiep.find(option => option.id === selectedOption.id);
                                                            const event = {
                                                                target: {
                                                                    name: "DanhSachKTV",
                                                                    value: {
                                                                        id: selectedKTV?.id,
                                                                        NhanVien: selectedKTV,
                                                                    },
                                                                },
                                                            };
                                                            handleInputChange(event);
                                                        }}
                                                        styles={customStyles}
                                                        options={[
                                                            ...(Array.isArray(field.options?.ListKTV)
                                                                ? field.options.ListKTV.map(option => {
                                                                    return {
                                                                        value: option.id,
                                                                        label: option.HoTen,
                                                                        id: option.id,
                                                                        isDisabled: getOptionDisabled(option.id),
                                                                    };
                                                                })
                                                                : []),

                                                            ...(Array.isArray(field.options?.ListKTVPhat)
                                                                ? field.options.ListKTVPhat.map(option => (
                                                                    {
                                                                        value: option.id,
                                                                        label: `${option.HoTen} (Phạt)`,
                                                                        isDisabled: true,
                                                                        id: option.id,
                                                                        title: 'Đang bị phạt, không thể ký',
                                                                    }))
                                                                : []),
                                                            ...(Array.isArray(field.options?.ListKTVKyLienTiep)
                                                                ? field.options.ListKTVKyLienTiep.map(option => {
                                                                    const ngayGhiThucTe = formData?.ThoiGianHieuLuc;
                                                                    const parts = ngayGhiThucTe.split('/');
                                                                    const formYear = Number(parts[2]);
                                                                    if (option.NamKyLienTiep.length === KTVKy.times) {
                                                                        const yearLast = option.NamKyLienTiep[KTVKy.times - 1];
                                                                        const yearFirst = option.NamKyLienTiep[0];
                                                                        if (formYear === yearLast + 1 || formYear === yearFirst - 1) {
                                                                            return {
                                                                                value: option.id,
                                                                                label: `${option.HoTen} (Ký 3 năm)`,
                                                                                isDisabled: getOptionDisabled(option.id) || true,
                                                                                id: option.id,
                                                                                title: 'Đã ký 3 năm liên tiếp',
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                value: option.id,
                                                                                label: `${option.HoTen}`,
                                                                                isDisabled: getOptionDisabled(option.id) || false,
                                                                                id: option.id,
                                                                                title: 'Được phép ký',
                                                                            };
                                                                        }
                                                                    } else {
                                                                        return {
                                                                            value: option.id,
                                                                            label: `${option.HoTen}`,
                                                                            isDisabled: getOptionDisabled(option.id) || false,
                                                                            id: option.id,
                                                                            title: 'Được phép ký',
                                                                        };
                                                                    }
                                                                })
                                                                : [])
                                                        ]}
                                                    />
                                                ) : (
                                                    <div className="text-red-500">Vui lòng nhập Thời gian hiệu lực trước khi chọn KTV ký.</div>
                                                )}
                                            </div>
                                        ) : (field.type === 'select' && field.name === "MaThanhVienBGD") ? (
                                            <div className={`w-full`}>
                                                {formData.ThoiGianHieuLuc ? (
                                                    <ReactSelect
                                                        name={field.name}
                                                        value={{
                                                            value: formData.MaThanhVienBGD?.id || '', // Lưu ID vào value
                                                            label: formData.MaThanhVienBGD?.NhanVien?.HoTen || '', // Hiển thị tên nhân viên BGD
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            const selectedBGD = field.options?.ListKTV.find(option => option.id === selectedOption.id)
                                                                || field.options?.ListKTVKyLienTiep.find(option => option.id === selectedOption.id);
                                                            const event = {
                                                                target: {
                                                                    name: "MaThanhVienBGD",
                                                                    value: {
                                                                        id: selectedBGD?.id,
                                                                        NhanVien: selectedBGD,
                                                                    },
                                                                },
                                                            };
                                                            handleInputChange(event);
                                                        }}
                                                        styles={customStyles}
                                                        options={[
                                                            ...(Array.isArray(field.options?.ListKTV)
                                                                ? field.options.ListKTV
                                                                    .filter((option) => option.DonViID === 5) // Chỉ hiển thị DonViID = 5
                                                                    .map((option) => ({
                                                                        value: option.id,
                                                                        label: option.HoTen,
                                                                        id: option.id,
                                                                        isDisabled: getOptionDisabled(option.id),
                                                                    }))
                                                                : []),
                                                            ...(Array.isArray(field.options?.ListKTVPhat)
                                                                ? field.options.ListKTVPhat
                                                                    .filter((option) => option.DonViID === 5) // Chỉ hiển thị DonViID = 5
                                                                    .map((option) => ({
                                                                        value: option.id,
                                                                        label: `${option.HoTen} (Phạt)`,
                                                                        isDisabled: true,
                                                                        id: option.id,
                                                                        title: 'Đang bị phạt, không thể ký',
                                                                    }))
                                                                : []),
                                                            ...(Array.isArray(field.options?.ListKTVKyLienTiep)
                                                                ? field.options.ListKTVKyLienTiep
                                                                    .filter((option) => option.DonViID === 5)
                                                                    .map((option) => {
                                                                        const ngayGhiThucTe = formData?.ThoiGianHieuLuc;
                                                                        const parts = ngayGhiThucTe.split('/');
                                                                        const formYear = Number(parts[2]);
                                                                        if (option.NamKyLienTiep.length === KTVKy.times) {
                                                                            const yearLast = option.NamKyLienTiep[KTVKy.times - 1];
                                                                            const yearFirst = option.NamKyLienTiep[0];
                                                                            if (formYear === yearLast + 1 || formYear === yearFirst - 1) {
                                                                                return {
                                                                                    value: option.id,
                                                                                    label: `${option.HoTen} (Ký  ${KTVKy.times} năm)`,
                                                                                    isDisabled: getOptionDisabled(option.id) || true,
                                                                                    id: option.id,
                                                                                    title: `Đã ký  ${KTVKy.times} năm liên tiếp`,
                                                                                };
                                                                            } else {
                                                                                return {
                                                                                    value: option.id,
                                                                                    label: `${option.HoTen}`,
                                                                                    isDisabled: getOptionDisabled(option.id) || false,
                                                                                    id: option.id,
                                                                                    title: 'Được phép ký',
                                                                                };
                                                                            }
                                                                        } else {
                                                                            return {
                                                                                value: option.id,
                                                                                label: `${option.HoTen}`,
                                                                                isDisabled: getOptionDisabled(option.id) || false,
                                                                                id: option.id,
                                                                                title: 'Được phép ký',
                                                                            };
                                                                        }
                                                                    })
                                                                : []),
                                                        ]}
                                                    />
                                                ) : (
                                                    <div className="text-red-500">Vui lòng nhập Thời gian hiệu lực trước khi chọn BGD ký.</div>
                                                )}
                                            </div>

                                        ) : field.type === 'date' && field.name !== 'ThoiGianHieuLuc' ? (
                                            <DatePicker
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />
                                        ) : ( field.type === 'date' && field.name === 'ThoiGianHieuLuc')?  (
                                            <DatePicker
                                                onChange={(date) => handleDateChangeTGHL(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />
                                        ) : field.type === 'number' ? (
                                            <input
                                                type={"number"}
                                                className={"w-full border rounded px-2 py-1"}
                                                name={field.name}
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                placeholder={field.placeholder}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.format === 'currency' ? formatCurrency(formData[field.name]) : formData[field.name]}
                                                onChange={handleInputChange}
                                                readOnly={field.readOnly}
                                                // placeholder={field.readOnly ? "Số hợp đồng sẽ được tạo tự động sau khi lưu" : null}

                                                style={field.readOnly ? { fontStyle: 'italic' } : null}
                                            />
                                        )}
                                    </div>
                                </div>

                            ))}
                        </div>
                        <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2 ">
                            Lưu báo cáo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

CreateBaoCao.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    baoCao: PropTypes.object.isRequired,
};

export default CreateBaoCao;
