import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { confirmVB, getThanhLyTypes } from '../../api/feartures/contractService';
import { getCustomers } from '../../api/feartures/customerService';
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css'
import { useDateUtils } from "../../hooks/useDateUtils";
import { useFormUtils } from "../../hooks/useFormUtils";
import { useCurrencyUtils } from "../../hooks/useCurrencyUtils";
import { DANG_THUC_HIEN, DA_DUYET, LuuY_LIST, ROLE_ID, getStatusColor, getStatusText } from '../../api/utils/constant';
import { formatDateToDDMMYYYY, formatDateToISO } from '../../api/utils/common';
import { updateThanhLy } from '../../api/feartures/thanhlySerrvice';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from '../modal';
import ModalText from '../modal/modalText';
function ThanhLyDetails({ onClose, thanhLy }) {

    let GiaTriTruocVAT = thanhLy.GiaTriTruocVAT ? thanhLy.GiaTriTruocVAT.split('.')[0] : null;
    let GiaTriSauVAT = thanhLy.GiaTriSauVAT ? thanhLy.GiaTriSauVAT.split('.')[0] : null;

    const { parseDateString, RealDateString } = useDateUtils();
    const { formData, handleInputChange, handleDateChange } = useFormUtils({
        ...thanhLy,
        created_at: thanhLy.created_at ? RealDateString(thanhLy.created_at) : null,
        NgayGhiThucTe: thanhLy.NgayGhiThucTe ? RealDateString(thanhLy.NgayGhiThucTe) : null,
        ThoiGianHieuLuc: thanhLy.ThoiGianHieuLuc ? parseDateString(thanhLy.ThoiGianHieuLuc) : null,
        GiaTriTruocVAT: GiaTriTruocVAT,
        GiaTriSauVAT: GiaTriSauVAT,
    });
    const { formatCurrency } = useCurrencyUtils();
    const [vanBanID, setVanBanID] = useState(null);

    const [thanhLyTypes, setThanhLyTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    const [setCustomers] = useState([]);
    const hasFetchedData = useRef(false);
    const [modalTextIsOpen, setModalTextIsOpen] = useState(false);
    const [contractID, setContractID] = useState(null);
    const [modalMessage, setModalMessage] = useState(" ");
    const [modalIsOpen, setModalIsOpen] = useState(false); // state quản lý modal approve

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [thanhLyTypesData, boardMembersData, customersData] = await Promise.all([
                    getThanhLyTypes(),
                    getBoardMembers(),
                    getCustomers()
                ]);
                setThanhLyTypes(thanhLyTypesData);
                setBoardMembers(boardMembersData);
                setCustomers(customersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);

    const handleSave = async () => {
        const formattedData = {
            ...formData,
            // ThoiGianHieuLuc: formatDateToISO(formData.ThoiGianHieuLuc),
            NgayGhiThucTe: formatDateToDDMMYYYY(formData.NgayGhiThucTe),
            created_at: formatDateToISO(formData.created_at),
        };
        try {
            const response = await updateThanhLy(formattedData, localStorage.getItem("accessToken"));
            if (response?.result) {
                toast.success('Cập nhật phụ lục thành công!');
                onClose()
                window.location.reload()
            }
        } catch (error) {
            console.error('Error updating contract:', error);
            toast.error('Có lỗi xảy ra khi cập nhật hợp đồng.');
        }
    };

    const handleClickOutside = (event) => {
        event.stopPropagation();
    };


    const handleRefuse = async (thanhLy) => {
        if (thanhLy.TrangThai === "CHO_DUYET") {
            setVanBanID(thanhLy.id);
            openModalText("Thêm ghi chú cho báo cáo này:");
        }
        else {
            toast.error("Không thể từ chối văn bản này")
        }
    };
    const openModalText = (message) => {
        setModalTextIsOpen(true);
        setModalMessage(message);
    }

    const openModalConfirm = (message) => {
        setModalConfirmIsOpen(true);
        setModalMessage(message);
    };
    const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);


    const closeModal = () => {
        setModalIsOpen(false);
        setModalTextIsOpen(false)
    };
    const closeModalConfirm = () => {
        setModalConfirmIsOpen(false);
    };

    const handleModalConfirm = async (thanhLy) => {
        const LoaiVB = "Thanh ly";

        try {
            toast.success("Duyệt văn bản thành công, reload lại trang để xem cập nhật");
            const data = await confirmVB(thanhLy.id, LoaiVB);
            setModalIsOpen(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    };
    const openModal = (message) => {
        setModalIsOpen(true);
        setModalMessage(message);
    };
    const handleConfirm = async (thanhLy) => {
        if (thanhLy.TrangThai === "DA_DUYET") {
            toast.warn("Đã duyệt văn bản này rồi!");
        } else {
            openModalConfirm("Bạn có chắc chắn duyệt văn bản này?");
        }
    };
    return (
        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <div className="flex h-full">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col items-center">
                            <h1 className="font-bold mb-4 text-xl text-gray-600">Chi tiết thanh lý</h1>
                            <span className={`block ${getStatusColor(thanhLy.TrangThai)} text-sm  font-bold px-0.5 py-2 rounded-full text-center w-36`}>
                                {getStatusText(thanhLy.TrangThai)}
                            </span>
                        </div>
                        <div className="overflow-auto p-4 flex-grow">
                            {[
                                { label: 'Thuộc hợp đồng', name: 'SoHopDong', value: thanhLy.HopDong.SoHopDong },
                                { label: 'Số thanh lý', name: 'SoThanhLy' },
                                { label: 'Số thanh lý của khách hàng', name: 'MaTLCuaKH' },
                                { label: 'Ngày khởi tạo', name: 'created_at', type: 'dateKT' },
                                { label: 'Ngày ghi thực tế trên hợp đồng', name: 'NgayGhiThucTe', type: 'date' },
                                { label: 'Loại thanh lý', name: 'MaLoaiTL', type: 'select', options: thanhLyTypes },
                                { label: 'Số bản', name: 'SoBan' },
                                ...(ROLE_ID === "2" ? [{ label: 'Số lưu', name: 'SoLuu' }, { label: 'Lưu ý', name: 'LuuY', type: 'select', options: LuuY_LIST },
                                { label: 'Ghi chú', name: 'Note' }] : []),
                                { label: 'Giá trị trước VAT', name: 'GiaTriTruocVAT', format: 'currency' },
                                { label: 'Giá trị sau VAT', name: 'GiaTriSauVAT', format: 'currency' },
                                { label: 'Nội dung', name: 'Noidung', type: 'textarea' },
                                { label: 'Phòng ban thực hiện', name: 'DonViThucHien', type: 'text', value: thanhLy.NguoiNhap?.DonVi?.name },
                                { label: 'Người nhập', name: 'MaNguoiNhap', type: 'text', value: thanhLy.NguoiNhap?.HoTen },
                                { label: 'Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: boardMembers },
                                { label: 'Link Drive', name: 'LinkDrive' },
                            ].map((field, idx) => (
                                <div className="mb-2 flex items-center" key={idx}>
                                    <label className="font-bold w-1/3">{field.label}:</label>
                                    <div className="w-2/3">
                                        {field.type === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name]}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        ) : field.type === 'select' ? (
                                            <select
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name] || ''}
                                                onChange={handleInputChange}
                                            >
                                                {field.options.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name || option.HoTen}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.type === 'date' ? (
                                            <DatePicker
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />
                                        ) : field.type === 'dateKT' ? (
                                            <DatePicker
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                                readOnly
                                            />
                                        ) : field.type === 'text' ? (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.value}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={
                                                    field.format === 'currency'
                                                        ? formatCurrency(formData[field.name])
                                                        : (field.name === "SoThanhLy")
                                                            ? thanhLy.MaTLCuaKH
                                                                ? `${thanhLy.MaTLCuaKH} - ${thanhLy.SoThanhLy}`
                                                                : `${thanhLy.SoThanhLy}`
                                                            : (field.name === "SoHopDong")
                                                                ? thanhLy.HopDong.MaHDCuaKH
                                                                    ? `${thanhLy.HopDong.MaHDCuaKH} - ${thanhLy.HopDong.SoHopDong}`
                                                                    : `${thanhLy.HopDong.SoHopDong}`
                                                                : (field.value || formData[field.name])
                                                }

                                                onChange={handleInputChange}
                                            />
                                        )}
                                    </div>
                                </div>

                            ))}
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="flex justify-end mt-4 px-4">
                                {(ROLE_ID === "2" && thanhLy.TrangThai !== DA_DUYET) ? (
                                    <>
                                        <button onClick={() => handleConfirm(thanhLy)} className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded mr-2">
                                            Duyệt
                                        </button>

                                        <button
                                            onClick={() => handleRefuse(thanhLy)}
                                            className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded mr-2"

                                        >
                                            Từ chối
                                        </button>
                                        <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2">
                                            Lưu
                                        </button>
                                    </>
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Modal isOpen={modalConfirmIsOpen} onClose={closeModalConfirm} message={modalMessage} onConfirm={() => handleModalConfirm(thanhLy)} />
            <ModalText isOpen={modalTextIsOpen} onClose={closeModal} message={modalMessage} VanBanId={vanBanID} LoaiVB="Thanh ly" />
        </div>
    );
}

ThanhLyDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    thanhLy: PropTypes.shape({
        SoHopDong: PropTypes.string,
        creation_at: PropTypes.string,
        NgayGhiThucTe: PropTypes.string,
        MaLoaiTL: PropTypes.number,
        MaTLCuaKH: PropTypes.string,
        SoLuu: PropTypes.string,
        SoBan: PropTypes.string,
        Note: PropTypes.string,
        LuuY: PropTypes.string,
        TongGiaTri: PropTypes.string,
        ThoiGianHieuLuc: PropTypes.string,
        GiaTriTruocVAT: PropTypes.string,
        GiaTriSauVAT: PropTypes.string,
        Noidung: PropTypes.string,
        MaKhachHang: PropTypes.number,
        MaHDCuaKH: PropTypes.string,
        DonViThucHien: PropTypes.string,
        MaNguoiNhap: PropTypes.number,
        MaThanhVienBGD: PropTypes.number,
        LinkDrive: PropTypes.string,
    }).isRequired,
};

export default ThanhLyDetails;