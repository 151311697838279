import React from 'react';
import '../css/modal.css';

function Modal({ isOpen, onClose, onConfirm, message }) {
  const handleConfirm = () => {
    onConfirm(); // Gọi hàm onConfirm để thực hiện logic khi ấn Đồng ý
    onClose();   // Gọi hàm onClose để đóng modal sau khi thực hiện xong logic
  };

  if (!isOpen) return null;

  return (
    <div className={`custom-modal-overlay ${isOpen ? 'open' : ''}`}>
      <div className="custom-modal-content">
        <h2 className='message'>{message}</h2>
        <button className="custom-button custom-button-confirm" onClick={handleConfirm}>Đồng ý</button>
        <button className="custom-button custom-button-cancel" onClick={onClose}>Hủy</button>
      </div>
    </div>
  );
}

export default Modal;
