import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { getContractTypes, confirmVB, updateHopDong, getProcessReportofHD, approveVB } from '../../api/feartures/contractService';
import { getCustomers } from '../../api/feartures/customerService';
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css';
import { useFormUtils } from "../../hooks/useFormUtils";
import { useCurrencyUtils } from "../../hooks/useCurrencyUtils";
import { toast } from 'react-toastify';
import Modal from '../modal';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import Note from './noteDetail';
import ModalText from '../modal/modalText';
import moment from 'moment';
import EditHistoryDetail from './EditHistoryDetail';
import axios from 'axios';
import { base_url } from '../../api/utils/baseURL';
import PopUpDetailProgress from '../phongchuyenmon/popUpDetailProgress';
import { CHO_DUYET, DANG_THUC_HIEN, DA_NHAN_XET, HOAN_THANH, LuuY_LIST, ROLE_ID, VATOptions, getStatusColor, getStatusText } from '../../api/utils/constant';
import UploadFileModal from '../modal/modalUpload'
import FileListComponent from '../UploadFile/GetListFile'
import ReactSelect from 'react-select';
import { customStyles } from '../create/customStyle';
import CreateNhiemVu from '../create/newNhiemVu';

function ContractDetails({ onClose, contract }) {
    const convertVATToString = (vat) => {
        return vat ? String(Number(vat).toFixed(0)) : '0';
    };
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [selectedContract, setSelectedContractID] = useState(null);
    // const { parseDateString, RealDateString } = useDateUtils ();
    const [status, setStatus] = useState('')
    const closePopup = () => {
        setPopupVisible(false);
        setSelectedContractID(null);
    };
    const convertStringToDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formatDateToISO = (date) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        if (typeof date.getFullYear !== 'function') {
            return null;
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formatDateToDDMMYYYY = (date) => {
        if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
            return date; // Trả về ngày đã được định dạng nếu đã ở dạng dd/mm/yyyy
        }

        if (!(date instanceof Date)) {
            return ''; // hoặc giá trị mặc định tùy vào yêu cầu của bạn khi không phải là đối tượng Date
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    let GiaTriTruocVAT = contract.GiaTriTruocVAT ? contract.GiaTriTruocVAT.split('.')[0] : null;
    let GiaTriSauVAT = contract.GiaTriSauVAT ? contract.GiaTriSauVAT.split('.')[0] : null;
    let TongGiaTri = contract.TongGiaTri ? contract.TongGiaTri.split('.')[0] : null;
    const vatValues = convertVATToString(contract.VAT)
    const { formData, handleInputChange, handleDateChange } = useFormUtils({
        ...contract,
        VAT: vatValues,
        created_at: contract.created_at ? convertStringToDate(contract.created_at) : null,
        NgayGhiThucTe: contract.NgayGhiThucTe ? convertStringToDate(contract.NgayGhiThucTe) : null,
        ThoiGianHieuLuc: contract.ThoiGianHieuLuc ? convertStringToDate(contract.ThoiGianHieuLuc) : null,
        GiaTriTruocVAT: GiaTriTruocVAT,
        GiaTriSauVAT: GiaTriSauVAT,
        TongGiaTri: TongGiaTri,
    });

    const { formatCurrency } = useCurrencyUtils();

    const [contractTypes, setContractTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const hasFetchedData = useRef(false);



    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Fetching data...');
                const [contractTypesData, boardMembersData, customersData] = await Promise.all([
                    getContractTypes(),
                    getBoardMembers(),
                    getCustomers()
                ]);
                setContractTypes(contractTypesData);
                setBoardMembers(boardMembersData);
                setCustomers(customersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);






    /// Xử lý các nút
    //////////
    /////////
    // Modal
    const [modalMessage, setModalMessage] = useState(" ");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTextIsOpen, setModalTextIsOpen] = useState(false);

    // Note
    const [openNote, setOpenNote] = useState(false);
    const [contractID, setContractID] = useState(null);

    const openModal = (message) => {
        setModalIsOpen(true);
        setModalMessage(message);
    };

    const openModalText = (message) => {
        setModalTextIsOpen(true);
        setModalMessage(message);
    }
    const closeModal = () => {
        setModalIsOpen(false);
        setModalTextIsOpen(false)
    };
    const handleSave = async () => {
        const formattedData = {
            ...formData,
            VAT: parseInt(formData.VAT),
            ThoiGianHieuLuc: formatDateToISO(formData.ThoiGianHieuLuc),
            NgayGhiThucTe: formatDateToDDMMYYYY(formData.NgayGhiThucTe),
            created_at: formatDateToISO(formData.created_at),
        };
        try {
            const response = await updateHopDong(formattedData, localStorage.getItem("accessToken"));
            if (response?.result) {
                toast.success('Cập nhật hợp đồng thành công!');
                onClose()
            }
        } catch (error) {
            console.error('Error updating contract:', error);
            toast.error('Có lỗi xảy ra khi cập nhật hợp đồng.');
        }
    };

    const handleClickOutside = (event) => {
        event.stopPropagation();
    };

    const handleModalConfirm = async (contract) => {
        const LoaiVB = "Hop dong";

        try {
            toast.success("Duyệt văn bản thành công, reload lại trang để xem cập nhật");
            const data = await confirmVB(contract.id, LoaiVB);
            setModalIsOpen(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    };
    
    const handleSucces = async (contract) => {
        if (contract.TrangThai === "HOAN_THANH") {
            toast.warn("Văn bản này đã hoàn thành rồi");
        } else if (contract.TrangThai === "CHUA_THUC_HIEN" || contract.TrangThai === "CHO_DUYET") {
            toast.error("Văn bản chưa được thực hiện");
        } else {
            openModal("Bạn có chắc chắn Hoàn thành hợp đồng này?");
        }
    };
    const handleModalApprove = async (contract) => {
        const LoaiVB = "Hop dong";
        try {
            toast.success("Hoàn thành văn bản!, reload lại trang để xem cập nhật");
            const data = await approveVB(contract.id, LoaiVB);
            setModalIsOpen(false);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    };

    const handleConfirm = async (contract) => {
        if (contract.TrangThai === "DANG_THUC_HIEN") {
            toast.warn("Đã duyệt văn bản này rồi!");
        } else if (contract.TrangThai === "HOAN_THANH") {
            toast.warn("Văn bản này đã hoàn thành rồi!");
        } else {
            openModal("Bạn có chắc chắn duyệt báo cáo này?");
        }
    };

    const handleRefuse = async (contract) => {
        if (contract.TrangThai === "CHO_DUYET") {
            setContractID(contract.id);
            openModalText("Thêm ghi chú cho báo cáo này:");
        }
        else {
            toast.error("Không thể từ chối văn bản này")
        }
    };

    const handleOpenNote = (id) => {
        setContractID(id);
        setOpenNote(true);
    }

    const role_id = localStorage.getItem("role_id");
    /// Xử lý các nút
    //////////
    /////////

    const [isEditHistoryOpen, setEditHistoryOpen] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [soHopDong, setSoHopDong] = useState('');

    const fetchHistoryData = async (SoHopDong, accessToken) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${base_url}history/listBySoVanBan`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    SoVanBan: SoHopDong
                }
            });
            setHistoryData(Array.isArray(response.data.result.data) ? response.data.result.data : []);
            console.log(response.data)
        } catch (err) {
            setError('Failed to fetch history data');
            setHistoryData([]);
        } finally {
            setLoading(false);
        }
    };


    const editHistoryDetail = () => {
        let accessToken = localStorage.getItem('accessToken');
        fetchHistoryData(contract.SoHopDong, accessToken);
        console.log('Số hợp đồng:');
        console.log(contract.SoHopDong);
        setEditHistoryOpen(true);
    };

    const xemTienDoHD = async (id, TrangThai) => {
        setStatus(TrangThai)
        setSelectedContractID(id);
        setPopupVisible(true);
    };



    //modify by Phi
    const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);

    return (
        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div>

                <div className="relative">
                    <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                        style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
                    >
                        <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                            <i className="fa-solid fa-rectangle-xmark"></i>
                        </button>

                        <div className="flex h-full">
                            <div className="w-full flex flex-col">
                                <div className="flex flex-col items-center">
                                    <h1 className="font-bold mb-4 text-xl text-gray-600">Chi tiết hợp đồng</h1>
                                    <span className={`block ${getStatusColor(contract.TrangThai)} text-sm font-bold px-0.5 py-2 rounded-full text-center w-36`}>
                                        {getStatusText(contract.TrangThai)}
                                    </span>
                                </div>
                                <div className="overflow-auto p-4 flex-grow">
                                    {[
                                        { label: 'Số hợp đồng', name: 'SoHopDong' },
                                        { label: 'Số hợp đồng của khách hàng', name: 'MaHDCuaKH' },
                                        { label: 'Ngày khởi tạo', name: 'created_at', type: 'dateKT' },
                                        { label: 'Ngày ghi thực tế trên hợp đồng', name: 'NgayGhiThucTe', type: 'date' },
                                        { label: 'Loại hợp đồng', name: 'MaLoaiHD', type: 'select', options: contractTypes },
                                        { label: 'Số bản', name: 'SoBan' },
                                        ...(ROLE_ID === "2" ? [{ label: 'Số lưu', name: 'SoLuu' }, { label: 'Lưu ý', name: 'LuuY', type: 'select', options: LuuY_LIST },
                                        { label: 'Ghi chú', name: 'Note' }] : []),
                                        // { label: 'Thời gian hiệu lực hợp đồng', name: 'ThoiGianHieuLuc', type: 'date' },
                                        { label: 'Giá trị trước VAT', name: 'GiaTriTruocVAT', format: 'currency' },
                                        { label: 'VAT', name: 'VAT', type: 'select', options: VATOptions },
                                        { label: 'Giá trị sau VAT', name: 'GiaTriSauVAT', format: 'currency' },
                                        { label: 'Nội dung', name: 'Noidung', type: 'textarea' },
                                        { label: 'Khách hàng', name: 'MaKhachHang', type: 'select', options: customers },
                                        { label: 'Mã hợp đồng của khách hàng', name: 'MaHDCuaKH', type: 'text', value: contract.MaHDCuaKH },
                                        { label: 'Phòng ban thực hiện', name: 'DonViThucHien', type: 'text', value: contract.NguoiNhap?.DonVi?.name },
                                        { label: 'Người nhập', name: 'MaNguoiNhap', type: 'text', value: contract.NguoiNhap?.HoTen },
                                        { label: 'Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: boardMembers },
                                    ].map((field, idx) => (
                                        <div className="mb-2 flex items-center" key={idx}>
                                            <label className="font-bold w-1/3">{field.label}:</label>
                                            <div className="w-2/3">
                                                {field.type === 'textarea' ? (
                                                    <textarea
                                                        name={field.name}
                                                        className="w-full border rounded px-2 py-1"
                                                        value={formData[field.name]}
                                                        onChange={handleInputChange}
                                                        rows="3"
                                                    ></textarea>
                                                ) : field.type === 'select' && field.name !== 'MaKhachHang' ? (
                                                    <select
                                                        name={field.name}
                                                        className="w-full border rounded px-2 py-1"
                                                        value={formData[field.name]}
                                                        onChange={handleInputChange}
                                                    >
                                                        {field.options.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name || option.HoTen}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : field.type === 'date' ? (
                                                    <DatePicker
                                                        onChange={(date) => handleDateChange(date, field.name)}
                                                        value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                        format="dd/MM/yyyy"
                                                        locale="vi"
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        className="w-full border rounded px-2 py-1"
                                                        placeholderText="Ngày/Tháng/Năm"
                                                    />
                                                ) : field.type === 'dateKT' ? (
                                                    <DatePicker
                                                        value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                        format="dd/MM/yyyy"
                                                        locale="vi"
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        className="w-full border rounded px-2 py-1"
                                                        placeholderText="Ngày/Tháng/Năm"
                                                        readOnly
                                                    />
                                                ) : field.name === 'MaKhachHang' ? (
                                                    <ReactSelect
                                                        name={field.name}
                                                        options={
                                                            field.options?.map((customer) => ({
                                                                value: customer.id,
                                                                label: `${customer.name} - ${customer.MST}`,
                                                            })) || []
                                                        }
                                                        className="w-full"
                                                        value={
                                                            formData[field.name]
                                                                ? { value: formData[field.name], label: field.options.find(option => option.id === formData[field.name])?.name }
                                                                : null
                                                        }
                                                        onChange={(selectedOption) =>
                                                            handleInputChange({
                                                                target: { name: field.name, value: selectedOption?.value || null },
                                                            })
                                                        }
                                                        styles={customStyles}
                                                        placeholder="Chọn loại khách hàng..."
                                                        isClearable
                                                        isSearchable
                                                    />
                                                ) : field.type === 'text' ? (
                                                    <input
                                                        type="text"
                                                        name={field.name}
                                                        className="w-full border rounded px-2 py-1"
                                                        value={field.value}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name={field.name}
                                                        className="w-full border rounded px-2 py-1"
                                                        value={field.format === 'currency' ? formatCurrency(formData[field.name]) : (field.name === "SoHopDong" ? contract.MaHDCuaKH ? ` ${contract.MaHDCuaKH} - ${contract.SoHopDong}` : contract.SoHopDong : (field.value || formData[field.name]))}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                    <div className="flex items-center space-x-4">
                                        <div className="flex-grow">
                                            <FileListComponent fileCode={formData["SoHopDong"]} flagCall={isModalOpenUpload} />
                                        </div>
                                        <button
                                            onClick={() => setIsModalOpenUpload(true)}
                                            className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded"
                                        >
                                            Upload file
                                        </button>
                                    </div>
                                </div>
                                <button onClick={() => xemTienDoHD(contract.id, contract.TrangThai)} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2">
                                    Danh sách nhiệm vụ
                                </button>
                                <div className="flex flex-col items-center mt-4">
                                    <div className="flex px-4">
                                        {((contract.TrangThai === CHO_DUYET || contract.TrangThai === DA_NHAN_XET) || (role_id === '2')) && (
                                            <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2">
                                                Lưu
                                            </button>
                                        )}
                                        {role_id === '2' && (
                                            <div >
                                                {contract.TrangThai === CHO_DUYET && (
                                                    <button onClick={() => handleRefuse(contract)} className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded mr-2"
                                                    >
                                                        Từ chối
                                                    </button>
                                                )}
                                                {(contract.TrangThai === CHO_DUYET || contract.TrangThai === DA_NHAN_XET) && (
                                                    <button onClick={() => handleConfirm(contract)} className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded mr-2">
                                                        Duyệt
                                                    </button>
                                                )}
                                                {(contract.TrangThai === DANG_THUC_HIEN) && (
                                                    <button
                                                        onClick={() => handleSucces(contract)} 
                                                        className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded mr-2  "
                                                    >
                                                        Hoàn thành
                                                    </button>
                                                )}
                                                <button onClick={editHistoryDetail} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
                                                >
                                                    Lịch sử chỉnh sửa
                                                </button>
                                                <EditHistoryDetail
                                                    isOpen={isEditHistoryOpen}
                                                    onClose={() => setEditHistoryOpen(false)}
                                                    historyData={historyData}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <Modal isOpen={modalIsOpen} onClose={closeModal} message={modalMessage} onConfirm={() => handleModalConfirm(contract)} />
                <ModalText isOpen={modalTextIsOpen} onClose={closeModal} message={modalMessage} VanBanId={contractID} LoaiVB="Hop dong" />
                <Modal isOpen={modalIsOpen} onClose={closeModal} message={modalMessage} onConfirm={() => handleModalApprove(contract)} />

            </div>
            <div>
                <button onClick={() => handleOpenNote(contract.id)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                    <i class="fa-solid fa-comment"></i>
                </button>

            </div>
            {openNote && (<Note onClose={() => setOpenNote(false)} vanbanID={contractID} LoaiVB="Hop dong" />)}
            {isPopupVisible && <CreateNhiemVu onClose={closePopup} vanbanID={selectedContract} type="HD" status={status} />}

            <UploadFileModal
                isOpen={isModalOpenUpload}
                fileCode={formData["SoHopDong"]}
                onClose={() => setIsModalOpenUpload(false)}
            />
        </div>
    );
}

ContractDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    contract: PropTypes.shape({
        SoHopDong: PropTypes.string,
        created_at: PropTypes.string,
        NgayGhiThucTe: PropTypes.string,
        MaLoaiHD: PropTypes.number,
        SoLuu: PropTypes.string,
        Note: PropTypes.string,
        LuuY: PropTypes.string,
        SoBan: PropTypes.string,
        TongGiaTri: PropTypes.string,
        ThoiGianHieuLuc: PropTypes.string,
        GiaTriTruocVAT: PropTypes.string,
        VAT: PropTypes.string,
        GiaTriSauVAT: PropTypes.string,
        Noidung: PropTypes.string,
        MaKhachHang: PropTypes.number,
        MaHDCuaKH: PropTypes.string,
        DonViThucHien: PropTypes.string,
        MaNguoiNhap: PropTypes.number,
        MaThanhVienBGD: PropTypes.number,
        LinkDrive: PropTypes.string,
    }).isRequired,
};

export default ContractDetails;
