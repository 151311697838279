import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../../../api/utils/baseURL";
import "../ManageAdmin.css";
import { toast } from "react-toastify";

const CustomerTypeForm = ({ isOpen, onClose, customerType, refreshData }) => {
    const [formValues, setFormValues] = useState(
        customerType || {
            name: "",
        }
    );

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const getAccessToken = () => localStorage.getItem("accessToken");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        if (!formValues.name.trim()) {
            toast.error("Tên loại khách hàng không được để trống");
            return false;
        }
        return true;
    };

    const handleSubmitForm = async () => {
        if (!validateForm()) return;
        setIsConfirmModalOpen(true);
    };

    const handleDeleteCustomerType = async () => {
        try {
            const token = getAccessToken();
            await axios.delete(`${base_url}customerType/${customerType.id}/delete`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Xóa loại khách hàng thành công");
            refreshData();
            onClose();
        } catch (error) {
            toast.error("Lỗi khi xóa loại khách hàng");
            console.error("Error deleting customer type:", error);
        } finally {
            setIsDeleteModalOpen(false);
        }
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = getAccessToken();
            if (customerType) {
                await axios.put(`${base_url}customerType/update`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Cập nhật loại khách hàng thành công");
            } else {
                await axios.post(`${base_url}customerType/create`, formValues, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Tạo loại khách hàng thành công");
            }
            refreshData();
            onClose();
        } catch (error) {
            toast.error("Lỗi khi lưu loại khách hàng");
            console.error("Error submitting form:", error);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    return isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h2>{customerType ? "Cập nhật loại khách hàng" : "Thêm mới loại khách hàng"}</h2>

                <div className="modal-body">
                    <div className="form-group">
                        <label>Tên loại khách hàng</label>
                        <input
                            type="text"
                            name="name"
                            value={formValues.name}
                            onChange={handleInputChange}
                            placeholder="Nhập tên loại khách hàng"
                        />
                    </div>
                </div>

                <div className="modal-footer">
                    <button onClick={handleSubmitForm} className="submitButton">
                        Lưu
                    </button>
                    {customerType && (
                        <button
                            onClick={() => setIsDeleteModalOpen(true)}
                            className="deleteButton"
                        >
                            Xóa
                        </button>
                    )}
                </div>

                {/* Modal xác nhận lưu */}
                {isConfirmModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>
                                {customerType
                                    ? "Bạn có chắc chắn muốn cập nhật loại khách hàng này không?"
                                    : "Bạn có chắc chắn muốn tạo loại khách hàng mới không?"}
                            </h3>
                            <div className="confirm-actions">
                                <button onClick={handleConfirmSubmit}>Lưu</button>
                                <button onClick={() => setIsConfirmModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal xác nhận xóa */}
                {isDeleteModalOpen && (
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <h3>Bạn có chắc chắn muốn xóa loại khách hàng này không?</h3>
                            <div className="confirm-actions">
                                <button onClick={handleDeleteCustomerType}>Xóa</button>
                                <button onClick={() => setIsDeleteModalOpen(false)}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default CustomerTypeForm;
