export const config = {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  };
export const configUser = (token) => {
    return {
      headers: { 
        'Content-Type': 'application/json',
         Authorization:`Bearer ${token}`
        },
      withCredentials: true
    };
  };