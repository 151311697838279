import React, { useState } from "react";
import PropTypes from "prop-types";
import './../../css/index.css';
import BaoCaoDetails from "../overlay/baoCaoDetail";
import { getDetailBC } from "../../api/feartures/contractService";
import CreateBaoCao from "../create/createBaoCao";
import { createBaoCao } from "../../api/feartures/baoCaoService";
import { toast } from "react-toastify";
import { CHO_DUYET, DA_NHAN_XET, ROLE_ID, getStatusColor, getStatusText } from '../../api/utils/constant';
import "./style.scss"
import CreateNhiemVu from "../create/newNhiemVu";

function PopUpDetailProgress({ onClose, contract, HopDongID, PhuLucID, status }) {
    const [isOverlayBC, setOverlayBC] = useState(false);
    const [isOpenNhiemVu, setOpenNhiemVu] = useState(false);
    const [detailBC, setDetailBC] = useState(null);
    const [isOpenCreateBC, setOpenCreateBC] = useState(false);
    const [createData, setCreateData] = useState(null);
    const handleBCClick = async (id) => {
        try {
            if (id) {
                const response = await getDetailBC(id);
                setDetailBC(response);
                setOverlayBC(true);
            }
            else {
                toast.warning("Chưa tạo báo cáo")
            }
        } catch (error) {
            console.error("Error fetching detail BC:", error);
        }
    };

    const handleCreateBC = (baoCao) => {
        setOpenCreateBC(true);
        const data = Object.assign({}, baoCao)
        data.HopDongID = contract.HopDongID;
        data.SoHopDong = contract.SoHopDong;
        data.LoaiBC = baoCao.LoaiBC.name;
        setCreateData(data);
        handleCloseBC();
    }
    const handleCloseNhiemVu = () => {
        setOpenNhiemVu(false);
    };
    const handleCloseBC = () => {
        setOverlayBC(false);
        setDetailBC(null);
    };

    const handleCloseCreateBC = () => {
        setOpenCreateBC(false);
        setCreateData(null);
    }

    const handleClickOutside = (event) => {
        if (event.target.id === "popup-overlay") {
            onClose();
        }
    };

    const onSaveBC = async (data) => {
        const accessToken = localStorage.getItem('accessToken')
        try {
            const response = await createBaoCao(data, accessToken);
            toast.success(response.message);
            window.location.reload();
        } catch (error) {
            toast.warning("Có lỗi xảy ra, vui lòng nhập đủ các trường bắt buộc")
            console.error('Error tao bao cao:', error);
        }
    }
    const onSubmitNhiemVU = () => {
        onClose()
    }
    return (
        <div
            id="popup-overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div
                className="bg-white p-4 rounded-md shadow-lg relative transform translate-y-[-100vh]"
                style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <br />

                <div className="container-list">
                    {contract.map((report) => (
                        <React.Fragment key={report.BaoCaoID}>
                            <div className="w-full flex flex-row justify-between border rounded my-2">
                                <div className="py-2 m-2 cursor-pointer" onClick={() => handleBCClick(report.BaoCaoID)}>
                                    <span className="font-bold">
                                        {report.LoaiBC.name}
                                    </span>
                                    <hr className="border-gray-400 w-48"></hr>
                                    {report.BaoCao.SoBaoCao ? <p>{report.BaoCao.SoBaoCao}</p> : <p>Chưa có báo cáo</p>}
                                </div>
                                <div
                                    className={`${getStatusColor(report.BaoCao.TrangThai)} text-xs font-semibold flex px-2 m-4 w-32 rounded items-center justify-center`}
                                >
                                    &nbsp;{getStatusText(report.BaoCao.TrangThai)}&nbsp;
                                </div>
                                {ROLE_ID === "3" && (
                                    <div
                                        className={`${report.BaoCao.TrangThai ? 'border-gray-300 text-gray-300' : 'border-blue-800 text-blue-800 cursor-pointer hover:bg-sky-100 '} text-xs font-semibold flex px-2 m-4 w-32 border-2 rounded items-center justify-center`}
                                        onClick={report.BaoCao.TrangThai ? null : () => handleCreateBC(report)}
                                    >
                                        <i className="fa-solid fa-plus"></i>
                                        &nbsp;Tạo báo cáo&nbsp;
                                    </div>
                                )}
                            </div>

                        </React.Fragment>
                    ))}

                </div>
                {/* {(status === CHO_DUYET || status === DA_NHAN_XET) &&
                    // ROLE_ID === "3"&&
                    (
                        <div className="container-list-btnadd">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                                onClick={() => setOpenNhiemVu(true)}
                            >
                                Nhiệm vụ hợp đồng mới
                            </button>
                        </div>
                    )} */}
                <div className="flex justify-center mt-4">
                </div>
            </div>

            {isOverlayBC && (
                <BaoCaoDetails onClose={handleCloseBC} baoCao={detailBC} />
            )}
            {isOpenCreateBC && (
                <CreateBaoCao onClose={handleCloseCreateBC} baoCao={createData} onSave={onSaveBC} />
            )}
            {isOpenNhiemVu && (
                <CreateNhiemVu
                    HopDongID={HopDongID}
                    PhuLucID={PhuLucID}
                    onClose={handleCloseNhiemVu}
                    onSave={onSubmitNhiemVU}
                />
            )}
        </div>
    );
}

PopUpDetailProgress.propTypes = {
    onClose: PropTypes.func.isRequired,
    contract: PropTypes.array.isRequired,
};

export default PopUpDetailProgress;
