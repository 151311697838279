import axios from "axios";
import {base_url} from "../utils/baseURL";
import {configUser} from "../utils/axiosConfig";

export const createBaoCao = async (data, token) => {
    try {
        const res = await axios.post(`${base_url}report/create`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error creating hop dong:', error);
        throw error;
    }
}

export const getKTVKy = async (maLoaiBC, hopDongID) => {
    try {
        const res = await axios.get(`${base_url}report/list-KTV-ky-BC?MaLoaiBC=${maLoaiBC}&HopDongID=${hopDongID}`);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching KTV ky:', error);
        throw error;
    }
}

export const getKTVThucHien = async () => {
    try {
        const res = await axios.get(`${base_url}report/list-NhanVien`);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching KTV thuc hien:', error);
        throw error;
    }
}

export const getListByReport = async (page,accesstoken) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/list-by-report?page=${page}`,configUser(accesstoken));
        return res.data.result;
    } catch (error) {
        console.error('Error fetching KTV thuc hien:', error);
        throw error;
    }
}
export const searchReport = async (pageNumber,SoBaoCao, TenKhachHang,SoHopDong,TrangThai) => {
    try {
        const res= await axios.get( `${base_url}generalRoom/list-by-report?page=${pageNumber}` +
                    (TenKhachHang ? `&TenKhachHang=${TenKhachHang}` : '') +
                    (SoBaoCao ? `&SoBaoCao=${SoBaoCao}` : '')+
                    (SoHopDong ? `&SoHopDong=${SoHopDong}` : '')+
                    (TrangThai ? `&TrangThai=${TrangThai}` : ''),configUser);
        if(res.data) return res.data;
    } catch (error) {
        console.error('Error fetching list data:', error);
        return null;  
    }
};

export const updateBaoCao = async (data, token) => {
    try {
        const res = await axios.put(`${base_url}report/update`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error update hop dong:', error);
        throw error;
    }
}