
import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from 'react-paginate';
import "../../../css/phongtonghop/paginate.css"
import ContractDetails from "../../overlay/contractDetails";
import BaoCaoDetails from "../../overlay/baoCaoDetail";
import {
    getDetailHD, getDetailBC
} from "../../../api/feartures/contractService";
import { getListByReport, searchReport } from "../../../api/feartures/baoCaoService";
import { getStatusColor, getStatusText } from "../../../api/utils/constant";
const Dsbaocao = () => {
    const [contractData, setContractData] = useState([]);
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isOpenDetailBC, setOpenDetailBC] = useState(false);
    const [detailBC, setDetailBC] = useState(null);
    const [detailHD, setDetailHD] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const hasFetchedData = useRef({}); // Sử dụng đối tượng để theo dõi các trang đã tải
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const accessToken = localStorage.getItem('accessToken')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getListByReport(currentPage,accessToken); // 10 là số lượng bản ghi trên mỗi trang
                setContractData(response.data);
                setPageCount(Math.ceil(response.total / 10));
            } catch (error) {
                console.error('Error fetching contract list:', error);
            }
        };
        if (!hasFetchedData.current[currentPage]) {
            fetchData();
            hasFetchedData.current[currentPage] = true; // Đánh dấu trang đã được tải
        }
    }, [currentPage]);
    const viewDetailHD = async (id) => {
        const dataDetailHD = await getDetailHD(id);
        setDetailHD(dataDetailHD);
        setOverlayOpen(true);
    };
    const viewDetailBC = async (id) => {
        const dataDetailBC = await getDetailBC(id);
        setDetailBC(dataDetailBC);
        setOpenDetailBC(true);
    };
    const handlePageClick = async (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPage(selectedPage);

        if (!isNaN(selectedPage)) {
            try {
                const response = await getListByReport(selectedPage,accessToken);
                setContractData(response.data);
                setPageCount(Math.ceil(response.total / 10));
                hasFetchedData.current[selectedPage] = true;
            } catch (error) {
                console.error('Error fetching contract list:', error);
            }
        }
    };
    ////search
    const [searchValues, setSearchValues] = useState({
        SoBaoCao: '',
        SoHopDong: '',
        TenKhachHang: '',
        TrangThai: '',
    });
    useEffect(() => {
        const hasSearchValues = Object.values(searchValues).some(value => value !== '');
        if (!isFirstLoad || hasSearchValues) {
            handleSearch();
        }

        setIsFirstLoad(false);
    }, [searchValues])
    const handleSearch = async () => {
        const { TenKhachHang, SoHopDong, TrangThai, SoBaoCao } = searchValues;
        try {
            const response = await searchReport(
                currentPage,
                SoBaoCao,
                TenKhachHang,
                SoHopDong,
                TrangThai
            );
            setContractData(response.result.data);
            setPageCount(Math.ceil(response.result.total / 10));
        } catch (error) {
            console.error('Error searching contracts:', error);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto p-4">
                <span className="font-bold text-lg">Danh sách Báo cáo</span>

                {/* đoạn đầu */}
                {/* <div className="flex justify-between items-center flex-wrap mb-4">
                    <div className="flex items-center space-x-4 mb-2 md:mb-0">
                        <div className="flex space-x-4 items-center mt-4 md:mt-0">
                            <input
                                type="text"
                                placeholder="Nhập Số Báo Cáo"
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.SoBaoCao}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, SoBaoCao: e.target.value })
                                }
                                onKeyDown={handleKeyDown}
                            />
                            <input
                                type="text"
                                placeholder="Nhập Số Hợp Đồng"
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.SoHopDong}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, SoHopDong: e.target.value })
                                }
                                onKeyDown={handleKeyDown}
                            />
                            <input
                                type="text"
                                placeholder="Nhập tên Khách Hàng"
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.TenKhachHang}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, TenKhachHang: e.target.value })
                                }
                                onKeyDown={handleKeyDown}
                            />
                            <select
                                className="px-4 py-2 border rounded-md focus:outline-none"
                                value={searchValues.TrangThai}
                                onChange={(e) =>
                                    setSearchValues({ ...searchValues, TrangThai: e.target.value })
                                }
                            >
                                <option value="">Tất cả trạng thái<i></i></option>
                                <option value="DANG_THUC_HIEN">Đang thực hiện</option>
                                <option value="HOAN_THANH">Đã hoàn thành</option>
                                <option value="CHO_DUYET">Chờ duyệt</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md focus:outline-none transition duration-300 ease-in-out transform hover:bg-blue-200">
                            <i className="fa-solid fa-arrow-down-wide-short"></i> <span></span>
                            Sắp xếp
                        </button>
                    </div>
                </div> */}



                {/* đoạn sau */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                        <thead className="bg-blue-100">
                            <tr>
                                <th className="py-4 px-4 text-left text-sm text-gray-600">STT</th>
                                <th className="py-4 px-4 text-left text-sm text-gray-600">Số báo cáo</th>
                                <th className="py-4 px-4 text-left text-sm text-gray-600">Thuộc hợp đồng</th>
                                <th className="py-4 px-4 text-left text-sm text-gray-600">Khách hàng</th>
                                <th className="py-4 px-4 text-left text-sm text-gray-600">Trạng thái</th>

                            </tr>
                        </thead>

                        <tbody>
                            {contractData.map((contract, index) => (
                                <React.Fragment key={contract.id}>
                                    <tr className="border-t">
                                        <td className="py-4 px-4 text-sm text-gray-700">{index + 1 + (currentPage - 1) * 10}</td>
                                        <td className="cursor-pointer py-2 px-4 text-sm text-blue-500 hover:bg-sky-100" onClick={() => viewDetailBC(contract.id)} >{contract.SoBaoCao}</td>
                                        <td className="cursor-pointer py-2 px-4 text-sm text-blue-500 hover:bg-sky-100" onClick={() => viewDetailHD(contract.HopDong.id)}>{contract.HopDong.SoHopDong}</td>
                                        <td className="cursor-pointer py-2 px-4 text-sm text-blue-500 hover:bg-sky-100">{contract.HopDong.KhachHang.name}</td>
                                        <td >
                                            <span className={`block ${getStatusColor(contract.TrangThai)} text-xs font-semibold px-2 py-1 rounded-full`}>
                                                {getStatusText(contract.TrangThai)}
                                            </span> </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                <ReactPaginate
                    previousLabel={"Trước"}
                    nextLabel={"Sau"}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    disableInitialCallback={true}
                />

            </div>

            {isOverlayOpen && (<ContractDetails onClose={() => setOverlayOpen(false)} contract={detailHD} />)}
            {isOpenDetailBC && (<BaoCaoDetails onClose={() => setOpenDetailBC(false)} baoCao={detailBC} />)}
            {/* {isOpenCreateHD && (<CreateHopDong onClose={() => setOpenCreateHD(false)} onSave={createHopDongPTH} />)} */}
        </div>
    );
}

export default Dsbaocao;