import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import './ManageAdmin.css';
import { base_url } from "../../api/utils/baseURL";
import { FaSearch } from "react-icons/fa";
import Pagination from "../Pagination";

const ManageUser = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isAddingEmployee, setIsAddingEmployee] = useState(false);
    const [formValues, setFormValues] = useState({
        DonViID: "",
        ChucVu: "Nhân viên",
        HoTen: "",
        LaKTV: true,
        username: "",
        password: "",
        role_id: "",
    });
    const [searchTerm, setSearchTerm] = useState(""); // Trạng thái tìm kiếm
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const donViRef = useRef(null);
    const chucVuRef = useRef(null);
    const hoTenRef = useRef(null);
    const userNameRef = useRef(null);
    const passwordRef = useRef(null);

    const getAccessToken = () => localStorage.getItem("accessToken");

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        // Lọc dữ liệu dựa trên giá trị tìm kiếm
        const results = data.filter((employee) => {
            const donViID = String(employee.DonViID).toLowerCase();
            const chucVu = String(employee.ChucVu).toLowerCase();
            const hoTen = String(employee.HoTen).toLowerCase();
            return (
                donViID.includes(searchTerm.toLowerCase()) ||
                chucVu.includes(searchTerm.toLowerCase()) ||
                hoTen.includes(searchTerm.toLowerCase())
            );
        });
        setFilteredData(results);
    }, [searchTerm, data]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${base_url}employee/list?page=${currentPage + 1}`
            );
            setData(response.data.result.data);
            setFilteredData(response.data.result.data);
            setPageCount(Math.ceil(response.data.result.total / 10)); //Mỗi bảng tối đa 10 items
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSelectedEmployeeChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSelectedEmployee((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmitForm = async () => {
        if (!formValues.DonViID || !formValues.ChucVu || !formValues.HoTen) {
            alert(
                "Các trường Mã đơn vị, Chức vụ, và Họ và tên không được bỏ trống."
            );
            return;
        }

        try {
            const token = getAccessToken();
            if (isAddingUser) {
                await axios.post(
                    `${base_url}employee/create-with-account`,
                    {
                        DonViID: formValues.DonViID,
                        ChucVu: formValues.ChucVu,
                        HoTen: formValues.HoTen,
                        LaKTV: formValues.LaKTV,
                        username: formValues.username,
                        password: formValues.password,
                        role_id: formValues.role_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                alert("User added successfully");
            } else if (isAddingEmployee) {
                await axios.post(
                    `${base_url}employee/create`,
                    {
                        DonViID: formValues.DonViID,
                        ChucVu: formValues.ChucVu,
                        HoTen: formValues.HoTen,
                        LaKTV: formValues.LaKTV,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                alert("Employee added successfully");
            }
            setIsModalOpen(false);
            setIsAddingUser(false);
            setIsAddingEmployee(false);
            setFormValues({
                DonViID: "",
                ChucVu: "",
                HoTen: "",
                LaKTV: false,
                username: "",
                password: "",
            });
            fetchData();
        } catch (error) {
            console.error(
                "Error adding user/employee",
                error.response ? error.response.data : error.message
            );
        }
    };

    const handleUpdate = async () => {
        try {
            const token = getAccessToken();
            if (
                !selectedEmployee.DonViID ||
                !selectedEmployee.ChucVu ||
                !selectedEmployee.HoTen
            ) {
                alert(
                    "Các trường Mã đơn vị, Chức vụ, và Họ và tên không được bỏ trống."
                );
                return;
            }

            await axios.put(`${base_url}employee/update`, selectedEmployee, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert("Employee updated successfully");
            handleCloseModal();
            const updatedData = data.map((emp) =>
                emp.id === selectedEmployee.id ? selectedEmployee : emp
            );
            setData(updatedData);
            setFilteredData(updatedData);
        } catch (error) {
            console.error("Error updating employee", error);
        }
    };

    const notificationDelete = () => {
        if (window.confirm("Bạn có chắc chắn muốn xóa?")) {
            handleDelete();
        }
    };

    const handleDelete = async () => {
        try {
            const token = getAccessToken();
            if (selectedEmployee != null) {
                await axios.delete(
                    `${base_url}employee/${selectedEmployee.id}/delete`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                alert("Employee deleted successfully");
                setData(data.filter((emp) => emp.id !== selectedEmployee.id));
                setFilteredData(
                    filteredData.filter((emp) => emp.id !== selectedEmployee.id)
                );
                handleCloseModal();
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedEmployee(null);
        setIsAddingUser(false);
        setIsAddingEmployee(false);
        setFormValues({
            DonViID: "",
            ChucVu: "",
            HoTen: "",
            LaKTV: false,
            username: "",
            password: "",
        });
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (donViRef.current) {
            donViRef.current.focus();
        } else if (chucVuRef.current) {
            chucVuRef.current.focus();
        } else if (hoTenRef.current) {
            hoTenRef.current.focus();
        } else if (userNameRef.current) {
            userNameRef.current.focus();
        } else if (passwordRef.current) {
            passwordRef.current.focus();
        }
        fetchListRoomChoice();
        fetchData();
    }, [isModalOpen]);

    const handleViewEdit = async (id) => {
        try {
            const response = await axios.get(
                `${base_url}employee/${id}/detail`
            );
            const employeeData = response.data.result;
            if (!employeeData.userInfo) {
                employeeData.userInfo = { username: "", password: "" };
            }
            setSelectedEmployee(employeeData);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching employee details", error);
        }
    };

    const handleAddingUser = () => {
        setIsAddingUser(true);
        setIsAddingEmployee(false);
        setIsModalOpen(true);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Cập nhật giá trị tìm kiếm
    };

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    };

    const titile = () => {
        if (isAddingEmployee) {
            return "Thêm Nhân viên";
        } else if (isAddingUser) {
            return "Thêm Người dùng";
        } else if (selectedEmployee) {
            return "Chi tiết nhân viên / người dùng";
        }
    };

    const [listRoom, setListRoom] = useState([]);

    const fetchListRoomChoice = async () => {
        const res = await axios.get(`${base_url}department/all-list`);
        setListRoom(res.data.result.data);
    };

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{'Danh sách Người dùng'}</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddingUser}>
                        Thêm người dùng
                    </button>
                    <div className="search-container">
                        <form
                            className="searchForm"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                value={searchTerm} // Đặt giá trị tìm kiếm
                                onChange={handleSearchChange} // Cập nhật giá trị tìm kiếm
                            />
                            <button type="submit">
                                <FaSearch />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Họ và tên</th>
                        <th>Đơn vị</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.HoTen}</td>
                            <td>{item.DonVi.name}</td>
                            <td>
                                <button
                                    className="editButton"
                                    onClick={() => handleViewEdit(item.id)}
                                >
                                    Xem/Sửa
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
            />

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <h2 className="title">{titile}</h2>
                        <div className="formGroup">
                            <label>Đơn vị:</label>
                            <select
                                name="DonViID"
                                className="formSelect"
                                value={
                                    selectedEmployee
                                        ? selectedEmployee.DonViID
                                        : formValues.DonViID
                                }
                                onChange={
                                    selectedEmployee
                                        ? handleSelectedEmployeeChange
                                        : handleInputChange
                                }
                            >
                                <option> </option>
                                {listRoom.map((room) => (
                                    <option key={room.id} value={room.id}>
                                        {room.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Họ và tên:</label>
                            <input
                                type="text"
                                name="HoTen"
                                value={
                                    selectedEmployee
                                        ? selectedEmployee.HoTen
                                        : formValues.HoTen
                                }
                                onChange={
                                    selectedEmployee
                                        ? handleSelectedEmployeeChange
                                        : handleInputChange
                                }
                                ref={hoTenRef}
                            />
                        </div>
                        <div className="formGroup">
                        </div>
                        {(isAddingUser || selectedEmployee) && (
                            <div>
                                <div className="formGroup">
                                    <label>Tên người dùng:</label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={
                                            selectedEmployee?.userInfo
                                                ?.username ||
                                            formValues.username
                                        }
                                        onChange={
                                            selectedEmployee
                                                ? handleSelectedEmployeeChange
                                                : handleInputChange
                                        }
                                        ref={userNameRef}
                                    />
                                </div>
                                <div className="formGroup">
                                    <label>Mật khẩu:</label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={
                                            selectedEmployee?.userInfo
                                                ?.password ||
                                            formValues.password ||
                                            ""
                                        }
                                        onChange={
                                            selectedEmployee
                                                ? handleSelectedEmployeeChange
                                                : handleInputChange
                                        }
                                        ref={passwordRef}
                                    />
                                </div>
                                {isAddingUser && (
                                    <div className="formGroup">
                                        <label>Quyền hạn:</label>
                                        <select
                                            name="role_id"
                                            className="formSelect"
                                            value={
                                                selectedEmployee?.userInfo
                                                    ?.role_id ??
                                                formValues.role_id
                                            }
                                            onChange={
                                                selectedEmployee
                                                    ? handleSelectedEmployeeChange
                                                    : handleInputChange
                                            }
                                        >
                                            <option> </option>
                                            <option value={1}>ADMIN</option>
                                            <option value={2}>
                                                Phòng tổng hợp
                                            </option>
                                            <option value={3}>
                                                Phòng chuyên môn
                                            </option>
                                        </select>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="formButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    if (isAddingUser || isAddingEmployee) {
                                        handleSubmitForm();
                                    } else if (selectedEmployee) {
                                        handleUpdate();
                                    }
                                }}
                                className="submitButton"
                            >
                                Lưu
                            </button>
                            {selectedEmployee && (
                                <button
                                    type="button"
                                    onClick={notificationDelete}
                                    className="deleteButton"
                                >
                                    Xóa
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageUser;
