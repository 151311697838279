import React, { useEffect, useState } from 'react';
import { getStat } from '../../api/feartures/PTHservice';

const Dashboard = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getStat(); // Giả sử đây là API endpoint để lấy dữ liệu
                console.log(response.result);
                setData(response.result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Chạy một lần khi component được mount

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-wrap gap-4">
                {/* Total Contracts */}
                <div className="bg-white shadow-lg rounded-lg p-4 flex-1 min-w-[200px]">
                    <div className="flex items-center">
                        {/* <div className="text-2xl font-bold font-bold">Total Contracts</div> */}
                        <div className="ml-auto text-red-500">
                        </div>
                    </div>
                    <div className='mb-2'>
                        <img src="https://icons.iconarchive.com/icons/iconka/business-finance/128/bubble-icon.png" width="128" height="128" />
                    </div>
                    <div className="text-gray-500 font-bold mb-2">Tổng số hợp đồng </div>

                    <div className="text-green-500 text-2xl font-bold">{data && data.tongSoHopDong}</div>
                </div>

                {/* Uncompleted Contracts */}
                <div className="bg-white shadow-lg rounded-lg p-4 flex-1 min-w-[200px]">
                    <div className="flex items-center">
                        {/* <div className="text-2xl font-bold font-bold">Uncompleted Contracts</div> */}
                        <div className="ml-auto text-green-500">
                        </div>
                    </div>
                    <div className='mb-2'>
                        <img src="https://icons.iconarchive.com/icons/graphicloads/colorful-long-shadow/128/Arrow-up-icon.png" width="128" height="128" />
                    </div>
                    <div className="text-gray-500 font-bold mb-2">Số hợp đồng chưa hoàn thành</div>
                    <div className="text-red-500 text-2xl font-bold">{data && data.soHopDongChuaHoanThanh}</div>
                </div>

                {/* Uncompleted Reports */}
                <div className="bg-white shadow-lg rounded-lg p-4 flex-1 min-w-[200px]">
                    <div className="flex items-center">
                        {/* <div className="text-2xl font-bold font-bold">Uncompleted Reports</div> */}
                        <div className="ml-auto text-yellow-500">
                        </div>
                    </div>
                    <div className='mb-2'>
                        <img src="https://icons.iconarchive.com/icons/iconka/business-finance/128/contract-icon.png" width="128" height="128" />
                    </div>
                    <div className="text-gray-500 font-bold mb-2">Số báo cáo chưa hoàn thành</div>
                    <div className="text-green-500 text-2xl font-bold">{data && data.soBaoCaoChuaHoanThanh}</div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
