import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import { getBoardMembers, getDepartmentList } from '../../api/feartures/departmentService';
import { getThanhLyTypes } from '../../api/feartures/contractService';
import { getCustomers } from '../../api/feartures/customerService';
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css';
import { useFormUtils } from '../../hooks/useFormUtils';
import { useCurrencyUtils } from '../../hooks/useCurrencyUtils';
import { createThanhLy } from '../../api/feartures/thanhlySerrvice';
import { REQUIRED_TL_FIELD } from '../../api/utils/constant';
import { validateRequiredFields } from '../../api/utils/common';
import { format } from 'date-fns';
function CreateTLHD({ onClose, thanhLy }) {
    const { formData, setFormData } = useFormUtils({
        ...thanhLy,
    });
    const { formatCurrency } = useCurrencyUtils();
    const [listPCM, setlistPCM] = useState([]);
    const [thanhLyTypes, setThanhLyTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const hasFetchedData = useRef(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [thanhLyTypesData, boardMembersData, customersData, listPCMData] = await Promise.all([
                    getThanhLyTypes(),
                    getBoardMembers(),
                    getCustomers(),
                    getDepartmentList()
                ]);

                setThanhLyTypes(thanhLyTypesData);
                setBoardMembers(boardMembersData);
                setCustomers(customersData);
                setlistPCM(listPCMData.result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);
    const calculateGiaTriSauVAT = (giaTriTruocVAT, vat) => {
        const vatPercentage = vat ? parseFloat(vat.replace('%', '')) / 100 : 0;
        const giaTriSauVAT = giaTriTruocVAT ? parseFloat(giaTriTruocVAT.replace(/\./g, '').replace(',', '.')) * (1 + vatPercentage) : 0;
        return Math.round(giaTriSauVAT).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // làm tròn tới số nguyên và định dạng
    };


    const customHandleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: value,
            };
            if (name === 'GiaTriTruocVAT' || name === 'VAT') {
                updatedFormData.GiaTriSauVAT = calculateGiaTriSauVAT(updatedFormData.GiaTriTruocVAT, updatedFormData.VAT);
            }
            setErrors({ ...errors, [name]: '' });
            return updatedFormData;
        });
    };
    const handleDateChange = (date, name) => {
        const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy') : '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: formattedDate,
        }));
        setErrors((prevErrors) => {
            const { [name]: _, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });
    };


    const handleSave = async () => {
        const newErrors = validateRequiredFields(formData, REQUIRED_TL_FIELD)
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }
        const dataToSend = {
            ...formData,
            HopDongID: thanhLy.HopDongID,
            GiaTriTruocVAT: formData.GiaTriTruocVAT ? parseFloat(formData.GiaTriTruocVAT.replace(/\./g, '').replace(',', '.')) : 0,
            GiaTriSauVAT: formData.GiaTriSauVAT ? parseFloat(formData.GiaTriSauVAT.replace(/\./g, '').replace(',', '.')) : 0,
            MaLoaiTL: formData.MaLoaiTL ? parseInt(formData.MaLoaiTL, 10) : 0,
            MaThanhVienBGD: formData.MaThanhVienBGD ? parseInt(formData.MaThanhVienBGD, 10) : 0,
            SoBan: formData.SoBan ? parseInt(formData.MaLoaiTL, 10) : 0
        };
        console.log(dataToSend);
        try {
            const response = await createThanhLy(dataToSend)
            toast.success(response?.message);
            onClose();
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error("Có lỗi xảy ra, vui lòng điền đủ các trường");
        }
    };


    const handleClickOutside = (event) => {
        event.stopPropagation();
    };

    const data = [
        { "id": 1, "name": "8" },
        { "id": 2, "name": "10" }
    ];

    return (
        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <div className="flex h-full">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col items-center">
                            <h1 className="font-bold mb-4 text-xl text-gray-600">Tạo thanh lý</h1>
                        </div>
                        <div className="overflow-auto p-4 flex-grow">
                            {[
                                { label: 'Thuộc hợp đồng', name: 'SoHopDong', value: thanhLy.SoHopDong },
                                { label: 'Số thanh lý của khách hàng', name: 'MaTLCuaKH' },
                                { label: 'Ngày ghi thực tế trên Thanh lý', name: 'NgayGhiThucTe', type: 'date' },
                                { label: 'Loại thanh lý', name: 'MaLoaiTL', type: 'selectID', options: thanhLyTypes },
                                { label: 'Số bản', name: 'SoBan' },
                                { label: 'Giá trị trước VAT', name: 'GiaTriTruocVAT', format: 'currency' },
                                { label: 'VAT', name: 'VAT', format: 'currency', type: 'select', options: data },
                                { label: 'Giá trị sau VAT', name: 'GiaTriSauVAT', format: 'currency' },
                                { label: 'Nội dung', name: 'Noidung', type: 'textarea' },
                                { label: 'Phòng ban thực hiện', name: 'DonViThucHien', type: 'select', options: listPCM },
                                { label: 'Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'selectID', options: boardMembers },
                                { label: 'Link Drive', name: 'LinkDrive' },
                            ].map((field, idx) => (
                                <div className="mb-2 flex items-center" key={idx}>
                                    <label className="font-bold w-1/3">{field.label}:</label>
                                    <div className="w-2/3">
                                        {errors[field.name] && <span className="text-red-500 text-sm">{errors[field.name]}</span>}
                                        {field.type === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name]}
                                                onChange={customHandleInputChange}
                                                rows="3"
                                            ></textarea>
                                        ) : field.type === 'select' ? (
                                            <select
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name] || ''}
                                                onChange={customHandleInputChange}
                                            >
                                                <option value="">Chọn một tùy chọn</option>
                                                {field.options.map((option) => (
                                                    <option key={option.id} value={option.name}>
                                                        {option.name || option.HoTen}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.type === 'date' ? (
                                            <DatePicker
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                format="dd/MM/yyyy"
                                                locale="vi"
                                                clearIcon={null}
                                                calendarIcon={null}
                                                className="w-full border rounded px-2 py-1"
                                                placeholderText="Ngày/Tháng/Năm"
                                            />

                                        ) : field.type === 'selectID' ? (
                                            <select
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={formData[field.name] || ''}
                                                onChange={customHandleInputChange}
                                            >
                                                <option value="">Chọn một tùy chọn</option>
                                                {field.options.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name || option.HoTen}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.type === 'text' ? (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.value}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                className="w-full border rounded px-2 py-1"
                                                value={field.format === 'currency' ? formatCurrency(formData[field.name]) : (field.value || formData[field.name])}
                                                onChange={customHandleInputChange}
                                                readOnly={field.name === 'GiaTriSauVAT'}
                                            />
                                        )}
                                    </div>
                                </div>

                            ))}
                        </div>
                        <div className="w-1/2 flex flex-col">
                            <div className="flex justify-end mt-4 px-4">
                                <button onClick={handleSave} className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded">
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

CreateTLHD.propTypes = {
    onClose: PropTypes.func.isRequired,
    thanhLy: PropTypes.shape({
        SoHopDong: PropTypes.string,
        creation_at: PropTypes.string,
        NgayGhiThucTe: PropTypes.string,
        MaLoaiTL: PropTypes.number,
        SoBan: PropTypes.string,
        ThoiGianHieuLuc: PropTypes.string,
        GiaTriTruocVAT: PropTypes.string,
        GiaTriSauVAT: PropTypes.string,
        Noidung: PropTypes.string,
        MaKhachHang: PropTypes.number,
        DonViThucHien: PropTypes.string,
        MaNguoiNhap: PropTypes.string,
        MaThanhVienBGD: PropTypes.number,
        LinkDrive: PropTypes.string,
        MaTLCuaKH: PropTypes.string
    }).isRequired,
};

export default CreateTLHD;
