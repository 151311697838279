import { useState } from 'react';
import { format } from 'date-fns';


export const useFormUtils = (initialData = {}) => {
    const [formData, setFormData] = useState(initialData);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleDateChange = (date, name) => {
        const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy') : '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: formattedDate,
        }));
    };
    const handleDateChangeTGHL = (date, name) => {
        const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy') : '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: formattedDate,
            MaThanhVienBGD: null,
            DanhSachKTV: null,
        }));
    };
    return {
        formData,
        setFormData,
        handleInputChange,
        handleDateChange,
        handleDateChangeTGHL
    };
};
