// departmentService.js
import axios from 'axios';
import { base_url } from '../utils/baseURL';
import { config } from '../utils/axiosConfig';

// Lấy danh sách phòng ban
export const getDepartmentList = async () => {
    try {
        const res = await axios.get(`${base_url}department/all-list`, config);
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error fetching department list:', error);
        throw error;
    }
};

// Lấy chi tiết phòng ban theo ID
export const getDepartmentDetail = async (id) => {
    try {
        const res = await axios.get(`${base_url}departments/detail?id=${id}`, config);
        if (res.data) return res.data.result;
    } catch (error) {
        console.error('Error fetching department detail:', error);
        throw error;
    }
};

// Lấy danh sách nhân viên trong phòng ban theo ID phòng ban
export const getEmployeesInDepartment = async (departmentId) => {
    try {
        const res = await axios.get(`${base_url}departments/${departmentId}/employees`, config);
        if (res.data) return res.data.result;
    } catch (error) {
        console.error('Error fetching employees in department:', error);
        throw error;
    }
};

export const getBoardMembers = async () => {
    try {
        const res = await axios.get(`${base_url}contract/list-BGD`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching board members:', error);
        throw error;
    }
};