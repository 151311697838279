import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Thêm useNavigate
import "./ManageAdmin.css";
import { FaSearch } from "react-icons/fa";
import CustomerForm from "./Form/CustomerForm";
import Pagination from "../Pagination";
import { getCustomerDetail, getCustomersData } from "../../api/feartures/customerService";

const ManageCustomer = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const getAccessToken = () => localStorage.getItem("accessToken");

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    }
    // eslint-disable-next-line
    const fetchData = async () => {
        try {
            const result = await getCustomersData(currentPage);
            setData(result.data);
            setPageCount(Math.ceil(result.total / 10));
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleViewEdit = async (id) => {
        try {
            const token = getAccessToken();
            const response = await getCustomerDetail(id, token);
            setSelectedCustomer(response.data.result);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching customer details:", error);
        }
    };

    const handleAddCustomer = () => {
        setSelectedCustomer(null); // Thêm mới khách hàng
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">Danh sách khách hàng</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddCustomer}>
                        Thêm khách hàng
                    </button>
                    <div className="search-container">
                        <form className="searchForm" onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <button type="submit">
                                <FaSearch />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Tên công ty</th>
                        <th>Mã số thuế</th>
                        <th>Mã khách hàng</th>
                        <th>Số điện thoại</th>
                        <th>Email</th>
                        <th>Địa chỉ</th>
                        <th>Tên người đại diện</th>
                        <th>Loại khách hàng</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((customer) => (
                        <tr key={customer.id}>
                            <td>{customer.name}</td>
                            <td>{customer.MST}</td>
                            <td>{customer.MaKhachHang}</td>
                            <td>{customer.SDT}</td>
                            <td>{customer.email}</td>
                            <td>{customer.DiaChi}</td>
                            <td>{customer.TenNguoiDaiDien}</td>
                            <td>{customer.LoaiKH?.name || "N/A"}</td>
                            <td>
                                <button
                                    className="editButton"
                                    onClick={() => handleViewEdit(customer.id)}
                                >
                                    Xem/Sửa
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
            />

            {isModalOpen && (
                <CustomerForm
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    customer={selectedCustomer}
                    refreshData={fetchData}
                />
            )}
        </div>
    );
};

export default ManageCustomer;
