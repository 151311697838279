import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, getUserRoleId, updateUserInfo } from '../redux/slices/userSlice';
import '../css/user-profile.css';

const UserProfile = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.user);
    const userRoleId = useSelector(getUserRoleId);
    const token = localStorage.getItem('accessToken') || '';
    useEffect(() => {
        dispatch(fetchUser(token));
    }, [dispatch]);

    dispatch(updateUserInfo(userInfo));

    const [formData, setFormData] = useState({
        id: '',
        username: '',
        role_id: '',
        access_token: '',
        refresh_token: '',
        NhanVienID: '',
        HoTen: '',
        ChucVu: '',
        LaKTV: false,
        DonViID: '',
        DonViName: '',
    });

    useEffect(() => {
        if (userInfo) {
            setFormData({
                id: userInfo.id,
                username: userInfo.username,
                role_id: userInfo.role_id,
                access_token: userInfo.access_token,
                refresh_token: userInfo.refresh_token,
                NhanVienID: userInfo.NhanVienID,
                HoTen: userInfo.NhanVien.HoTen,
                ChucVu: userInfo.NhanVien.ChucVu,
                LaKTV: userInfo.NhanVien.LaKTV,
                DonViID: userInfo.NhanVien.DonViID,
                DonViName: userInfo.NhanVien.DonVi.name,
            });
        }
    }, [userInfo]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Dispatch action to update user info
        // dispatch(updateUser(formData)); // Bạn cần định nghĩa action updateUser trong userSlice
    };

    if (!userInfo) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="user-profile">
            <h1 className="profile-title">Thông tin người dùng</h1>
            <form onSubmit={handleSubmit} className="profile-form">
                <div className="form-group">
                    <label>Username:</label>
                    <input type="text" name="username" value={formData.username} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Họ tên nhân viên</label>
                    <input type="text" name="HoTen" value={formData.HoTen} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Chức Vụ:</label>
                    <input type="text" name="ChucVu" value={formData.ChucVu} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Là kiểm toán viên:</label>
                    <input title="LaKTV" type="checkbox" name="LaKTV" checked={formData.LaKTV} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Tên đơn vị</label>
                    <input type="text" name="DonViName" value={formData.DonViName} onChange={handleChange} />
                </div>
                <button type="submit" className="submit-button">Cập Nhật</button>
            </form>
        </div>
    );
};

export default UserProfile;
