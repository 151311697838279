import React, { useState } from "react";
import PropTypes from "prop-types";
import './../../css/index.css';
import './../../css/phongtonghop/popUpDetail.css';
import BaoCaoDetails from "../overlay/baoCaoDetail";
import { getDetailBC } from "../../api/feartures/contractService";
import { toast } from "react-toastify";
import { getStatusColor, getStatusText } from "../../api/utils/constant";
import CreateNhiemVu from "../create/newNhiemVu";


// const getStatusType = (text) => {
//   switch (text) {
//     case "BCXD":
//         return "Báo cáo Xây dựng"
//     case "BCTC":
//         return "Báo cáo Tài chính";
//     default :
//          return "bctv"

//   }
// }
function PopUpDetailProgress({ onClose, contract, HopDongID, PhuLucID }) {
    const [isOverlayBC, setOverlayBC] = useState(false);
    const [detailBC, setDetailBC] = useState(null);
    const [isOpenNhiemVu, setOpenNhiemVu] = useState(false);


    const handleBCClick = async (id) => {
        try {
            if (id) {
                const response = await getDetailBC(id);
                setDetailBC(response);
                setOverlayBC(true);
            }
            else {
                toast.warning("Chưa tạo báo cáo")
            }
        } catch (error) {
            console.error("Error fetching detail BC:", error);
        }
    };

    const handleCloseBC = () => {
        setOverlayBC(false);
        setDetailBC(null);
    };

    const handleClickOutside = (event) => {
        if (event.target.id === "popup-overlay") {
            onClose();
        }
    };
    const handleCloseNhiemVu = () => {
        setOpenNhiemVu(false);
    };
    const onSubmitNhiemVU = () => {
        onClose()
    }
    return (
        <div
            id="popup-overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div
                className="bg-white p-4 rounded-md shadow-lg relative transform translate-y-[-100vh]"
                style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
            >
                <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </button>
                <br />

                <div>
                    {contract.map((report) => (
                        <React.Fragment key={report.BaoCaoID}>
                            <div className="w-full flex flex-row justify-between border rounded my-2 cursor-pointer" onClick={() => handleBCClick(report.BaoCaoID)}>
                                <div className="py-2 m-2">
                                    <span className="font-bold cursor-pointer">
                                        {report.LoaiBC.name}
                                    </span>
                                    <hr className={`border-gray-400 w-48`}></hr>
                                    {report.BaoCao.SoBaoCao ? <p>{report.BaoCao.SoBaoCao}</p> : <p>Chưa có báo cáo</p>}
                                </div>
                                <div
                                    className={`${getStatusColor(report.BaoCao.TrangThai)} text-xs font-semibold flex px-2 m-4 w-32 rounded items-center justify-center`}
                                >
                                    &nbsp;{getStatusText(report.BaoCao.TrangThai)}&nbsp;
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                {/* <div className="container-list-btnadd">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        onClick={() => setOpenNhiemVu(true)}
                    >
                        Nhiệm vụ hợp đồng mới
                    </button>
                </div> */}
                <div className="flex justify-center mt-4">
                </div>
            </div>

            {isOverlayBC && (
                <BaoCaoDetails onClose={handleCloseBC} baoCao={detailBC} />
            )}
            {isOpenNhiemVu && (
                <CreateNhiemVu
                    HopDongID={HopDongID}
                    PhuLucID={PhuLucID}
                    onClose={handleCloseNhiemVu}
                    onSave={onSubmitNhiemVU}
                />
            )}
        </div>
    );
}

PopUpDetailProgress.propTypes = {
    onClose: PropTypes.func.isRequired,
    contract: PropTypes.array.isRequired,
};

export default PopUpDetailProgress;
