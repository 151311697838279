import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Thêm useNavigate
import "./ManageAdmin.css";
import { FaSearch } from "react-icons/fa";
import Pagination from "../Pagination";
import { getCustomerTypeDetail, getCustomerTypesData } from "../../api/feartures/customerTypeService";
import CustomerTypeForm from "./Form/CustomerTypeForm";

const ManageCustomerType = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getAccessToken = () => localStorage.getItem("accessToken");

    // eslint-disable-next-line
    const fetchData = async () => {
        try {
            const result = await getCustomerTypesData();
            setData(result.data);
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleViewEdit = async (id) => {
        try {
            const token = getAccessToken();
            const response = await getCustomerTypeDetail(id, token);
            setSelectedCustomerType(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching customer details:", error);
        }
    };

    const handleAddCustomerType = () => {
        setSelectedCustomerType(null); // Thêm mới khách hàng
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">Danh sách danh mục Khách hàng</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddCustomerType}>
                        Thêm loại khách hàng
                    </button>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Tên loại khách hàng</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((customerType) => (
                        <tr key={customerType.id}>
                            <td>{customerType.name}</td>
                            <td>
                                <button
                                    className="editButton"
                                    onClick={() => handleViewEdit(customerType.id)}
                                >
                                    Xem/Sửa
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <CustomerTypeForm
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    customerType={selectedCustomerType}
                    refreshData={fetchData}
                />
            )}
        </div>
    );
};

export default ManageCustomerType;
