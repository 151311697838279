import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import './ManageAdmin.css';
import { base_url } from "../../api/utils/baseURL";
import { FaSearch } from "react-icons/fa";
import Pagination from "../Pagination";
import EmployeeForm from "./Form/EmployeeForm"

const ManageEmployee = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState()
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${base_url}employee/list-user?page=${currentPage + 1}`);
            setFilteredData(response.data.result.data);
            setPageCount(Math.ceil(response.data.result.total / 10));
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const handleAddingEmployee = async () => {
        setSelectedEmployee(null);
        setShowModal(true)
    }

    const handleViewEdit = async (id) => {
        try {
            const response = await axios.get(`${base_url}employee/${id}/detail`);
            const employeeData = response.data.result;
            setSelectedEmployee(employeeData);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching employee details', error);
        }
    }

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    }


    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{'Danh sách Kiểm toán viên'}</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddingEmployee}>Thêm kiểm toán viên</button>
                    {/* <div className="search-container">
                        <form className="searchForm" onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                value={searchTerm} // Đặt giá trị tìm kiếm
                                onChange={handleSearchChange} // Cập nhật giá trị tìm kiếm
                            />
                            <button type="submit"><FaSearch /></button>
                        </form>
                    </div> */}
                </div>
            </div>

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Họ và tên</th>
                        <th>Mã kiểm toán viên</th>
                        <th>Là thành viên BGĐ</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.HoTen}</td>
                            <td>{item.MaNhanVien}</td>
                            <td>{item.DonViID === 5 ? "Có" : "Không"}</td>
                            <td>
                                <button className="editButton" onClick={() => handleViewEdit(item.id)}>Xem/Sửa</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal &&
                <EmployeeForm
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    employeeData={selectedEmployee}
                    refreshData={fetchData}
                />
            }
            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
            />

        </div>
    )
}

export default ManageEmployee;
