import React, { useState } from 'react';
import "../../css/modal.css"; // Nếu cần
import { createNote } from '../../api/feartures/contractService';
import { toast } from 'react-toastify'; //import toast thông báo
function ModalText({ isOpen, onClose, VanBanId, LoaiVB, message }) {
    const [NoiDung, setNoiDung] = useState('');

    const handleSubmit = async () => {
        try {
            const response = await createNote(VanBanId, LoaiVB, NoiDung);
            console.log('Response:', response.data);
            // Xử lý xong việc gửi dữ liệu, sau đó đóng modal
            onClose(); // Đóng modal sau khi xử lý thành công
            toast.success("Từ chối thành công")
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className={`custom-modal-overlay ${isOpen ? 'open' : ''}`}>
            <div className="custom-modal-content bg-white border border-gray-300 shadow-lg rounded-lg p-4 max-w-sm mx-auto">
                <h2 className="text-lg font-bold mb-4">{message}</h2>
                <textarea
                    className="border-2 border-gray-300 rounded-md p-2 mt-2 w-full h-32 resize-none focus:outline-none focus:border-blue-500"
                    value={NoiDung}
                    onChange={(e) => setNoiDung(e.target.value)}
                />
                <div className="flex justify-end mt-4">
                    <button className="custom-button custom-button-confirm mr-2 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md transition duration-300" onClick={handleSubmit}>Gửi</button>
                    <button className="custom-button custom-button-cancel bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md transition duration-300" onClick={onClose}>Hủy</button>
                </div>
            </div>
        </div>
    );
}

export default ModalText;
