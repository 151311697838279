// contractService.js
import axios from 'axios';
import { base_url } from "../utils/baseURL";
import { config, configUser } from "../utils/axiosConfig";

export const updateStatusReport = async (data) => {
    try {
        const res = await axios.put(`${base_url}user/update`, data, config);
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error updating status report:', error);
        throw error;
    }
};

export const getContractList = async (page) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/list?page=${page}`, config);
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error fetching contract list:', error);
        throw error;
    }
};

export const getDetailHD = async (id) => {

    try {
        const res = await axios.get(`${base_url}generalRoom/detailHD?id=${id}`, config);
        if (res.data) return res.data.result


    } catch (error) {
        console.log(error)
        throw error;
    }
}


export const getDetailPL = async (id) => {

    try {
        const res = await axios.get(`${base_url}generalRoom/detailPL?id=${id}`, config);
        if (res.data) return res.data.result


    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getProcessReportofHD = async (id) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/bao-cao/tien-do?HopDongID=${id}`, config)
        if (res.data) return res.data.result
    }
    catch (error) {
        throw error;
    }

}

export const getProcessReportofPL = async (id) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/bao-cao/tien-do?PhuLucID=${id}`, config)
        if (res.data) return res.data.result
    }
    catch (error) {
        throw error;
    }

}


export const getContractTypes = async () => {
    try {
        const res = await axios.get(`${base_url}contract/list-type-contract`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching contract types:', error);
        throw error;
    }
};

export const getPhuLucTypes = async () => {
    try {
        const res = await axios.get(`${base_url}phuLuc/list-type-PL`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching phu luc types:', error);
        throw error;
    }
}

export const getThanhLyTypes = async () => {
    try {
        const res = await axios.get(`${base_url}thanhLy/list-type-TL`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching thanh ly types:', error);
        throw error;
    }
}



export const createHopDong = async (data, token) => {
    try {
        const res = await axios.post(`${base_url}contract/create`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error creating hop dong:', error);
        throw error;
    }
}

export const updateHopDong = async (data, token) => {
    try {
        const res = await axios.put(`${base_url}contract/update`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error update hop dong:', error);
        throw error;
    }
}

export const createPhuLuc = async (data, token) => {
    try {
        const res = await axios.post(`${base_url}phuLuc/create`, data, configUser(token));
        return res.data ? res.data : null;
    } catch (error) {
        console.error('Error creating phu luc:', error);
        throw error;
    }
}

export const getDetailTL = async (id) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/detailTL?id=${id}`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching detail thanh ly:', error);
        throw error;
    }
}

export const getDetailBC = async (id) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/detailBC?id=${id}`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching detail bao cao:', error);
        throw error;
    }
}

export const getBaoCaoTypes = async () => {
    try {
        const res = await axios.get(`${base_url}report/list-type-report`, config);
        return res.data.result;
    } catch (error) {
        console.error('Error fetching bao cao types:', error);
        throw error;
    }
}

export const confirmVB = async (VanBanId, LoaiVB) => {
    try {
        const res = await axios.post(`${base_url}generalRoom/confirmVB`, {
            VanBanId: VanBanId,
            LoaiVB: LoaiVB,
        }, config)
        return res.data
    } catch (error) {
        console.log("lỗi ở đây", error)
    }
}

export const approveVB = async (VanBanId, LoaiVB) => {
    try {
        const res = await axios.post(`${base_url}generalRoom/approveVB`, {
            VanBanId: VanBanId,
            LoaiVB: LoaiVB,
        }, config)
        return res.data
    } catch (error) {
        console.log("lỗi ở đây", error)
    }
}


export const createNote = async (VanBanId, LoaiVB, NoiDung) => {
    try {
        const res = await axios.post(`${base_url}note/add`, {
            NoiDung: NoiDung,
            VanBanId: VanBanId,
            LoaiVB: LoaiVB,
        }, configUser(localStorage.getItem("accessToken")))
        return res.data
    } catch (error) {
        console.log("lỗi ở đây", error)
    }
}


export const getNote = async (VanBanId, LoaiVB) => {
    try {
        const res = await axios.get(`${base_url}note/get`, {
            params: {
                VanBanId: VanBanId,
                LoaiVB: LoaiVB
            },
            config
        });
        return res.data;
    } catch (error) {
        console.error("Lỗi ở đây:", error);
        throw error;
    }
};


export const searchContract = async (pageNumber, TenKhachHang, SoHopDong, TrangThai) => {
    try {
        const res = await axios.get(`${base_url}generalRoom/list?page=${pageNumber}` +
            (TenKhachHang ? `&TenKhachHang=${TenKhachHang}` : '') +
            (SoHopDong ? `&SoHopDong=${SoHopDong}` : '') +
            (TrangThai ? `&TrangThai=${TrangThai}` : ''), config);
        if (res.data) return res.data;
    } catch (error) {
        console.error('Error fetching list data:', error);
        return null;  // hoặc bạn có thể xử lý lỗi theo cách khác tùy thuộc vào yêu cầu của bạn
    }
};
export const createNhiemVuHD = async (body) => {
    try {
        const res = await axios.post(`${base_url}contract/create-nhiem-vu`, {
            HopDongID: body?.HopDongID,
            PhuLucID: body?.PhuLucID,
            listNhiemVu: body?.listNhiemVu,
            MaLoaiBC: body?.MaLoaiBC,
        }, configUser(localStorage.getItem("accessToken")))
        return res.data
    } catch (error) {
        console.log("lỗi ở đây", error)
    }
}
export const getNhiemVu = async (id, type) => {
    try {
        let res;
        if (type === "HD") {
            res = await axios.post(`${base_url}contract/get-nhiem-vu?HopDongID=${id}`)
        } else if (type === "PL") {
            res = await axios.post(`${base_url}contract/get-nhiem-vu?PhuLucID=${id}`)
        }
        return res.data
    } catch (error) {
        console.log("lỗi ở đây", error)
    }
}