import React, { useState, useEffect } from "react";
import axios from 'axios';
import './ManageAdmin.css';
import { base_url } from "../../api/utils/baseURL";
import Pagination from "../Pagination";
import DepartmentForm from "./Form/DepartmentForm";

const ManageDepartment = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [selectedDepartmentData, setSelectedDepartmentData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showModal, setShowModal] = useState(false)

    const fetchData = async () => {
        try {
            const response = await axios.get(`${base_url}department/list?page=${currentPage + 1}`);
            setFilteredData(response.data.result.data);
            setPageCount(Math.ceil(response.data.result.total / 10));
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const handleAddDepartment = () => {
        setSelectedDepartmentData(null);
        setShowModal(true);
    };

    const handleViewEdit = async (id) => {
        try {
            const response = await axios.get(`${base_url}department/${id}/detail`);
            const departmentData = response.data.result;
            setSelectedDepartmentData(departmentData);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching department details', error);
        }
    };

    const handlePageClick = (event) => {
        const newCurrentPage = event.selected;
        setCurrentPage(newCurrentPage);
    }

    return (
        <div>
            <div className="header">
                <div className="leftPart">
                    <h1 className="TitleHeader">{'Danh sách Phòng ban'}</h1>
                </div>
                <div className="rightPart">
                    <button className="addButton" onClick={handleAddDepartment}>Thêm phòng ban</button>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Tên phòng ban</th>
                        <th>Mã phòng ban</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((department) => (
                        <tr key={department.id}>
                            <td>{department.fullname}</td>
                            <td>{department.name}</td>
                            <td className="tdbutton">
                                <button
                                    className="editButton"
                                    onClick={() => handleViewEdit(department.id)}
                                >
                                    Xem/Sửa
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {
                showModal &&
                <DepartmentForm
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    departmentData={selectedDepartmentData}
                    refreshData={fetchData}
                />
            }
            <Pagination
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
            />

        </div>
    );
};

export default ManageDepartment;
