import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { getBoardMembers } from '../../api/feartures/departmentService';
import { approveVB, getBaoCaoTypes } from '../../api/feartures/contractService';
import { useDateUtils } from '../../hooks/useDateUtils';
import { useCurrencyUtils } from '../../hooks/useCurrencyUtils';
import { useFormUtils } from '../../hooks/useFormUtils';
import '../../css/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../css/phongtonghop/customDatepicker.css';
import Modal from '../modal';
import { confirmVB } from '../../api/feartures/contractService';
import { toast } from 'react-toastify';
import ModalText from '../modal/modalText';
import Note from './noteDetail';
import { CHO_DUYET, DANG_THUC_HIEN, DA_NHAN_XET, LuuY_LIST, REQUIRED_BC_FIELD, ROLE_ID, getStatusColor, getStatusText } from '../../api/utils/constant';
import { formatDateToDDMMYYYY, formatDateToISO, validateRequiredFields } from '../../api/utils/common';
import { getKTVKy, updateBaoCao } from '../../api/feartures/baoCaoService';
import ReactSelect from 'react-select';
import { customStyles } from '../create/customStyle';
import moment from 'moment';
function BaoCaoDetails({ onClose, baoCao }) {
    const { parseDateString, RealDateString } = useDateUtils();
    const DanhSachKTV = baoCao?.ListKTVKy[0]
    const MaThanhVienBGD = baoCao?.ListKTVKy[1]
    const { formData, handleInputChange, handleDateChange, handleDateChangeTGHL } = useFormUtils({
        ...baoCao,
        DanhSachKTV,
        MaThanhVienBGD,
        created_at: baoCao.created_at ? RealDateString(baoCao.created_at) : null,
        NgayGhiThucTe: baoCao.NgayGhiThucTe ? RealDateString(baoCao.NgayGhiThucTe) : null,
        ThoiGianHieuLuc: baoCao.NgayGhiThucTe ? RealDateString(baoCao.NgayGhiThucTe) : null,
    });
    const { formatCurrency } = useCurrencyUtils();
    const [baoCaoTypes, setBaoCaoTypes] = useState([]);
    const [boardMembers, setBoardMembers] = useState([]);
    const hasFetchedData = useRef(false);
    const [KTVKy, setKTVKy] = useState([])
    // Modal
    const [modalMessage, setModalMessage] = useState(" ");
    const [modalIsOpen, setModalIsOpen] = useState(false); // state quản lý modal approve
    const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false); // state quản lý modal confirm
    const [modalTextIsOpen, setModalTextIsOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const openModal = (message) => {
        setModalIsOpen(true);
        setModalMessage(message);
    };
    const openModalConfirm = (message) => {
        setModalConfirmIsOpen(true);
        setModalMessage(message);
    };
    const closeModal = () => {
        setModalIsOpen(false);
        setModalTextIsOpen(false)
    };
    const closeModalConfirm = () => {
        setModalConfirmIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [baoCaoTypesData, boardMembersData, KTVKyData] = await Promise.all([
                    getBaoCaoTypes(),
                    getBoardMembers(),
                    getKTVKy(baoCao.MaLoaiBC, baoCao.HopDongID),
                ]);
                setKTVKy(KTVKyData)
                setBaoCaoTypes(baoCaoTypesData);
                setBoardMembers(boardMembersData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true;
        }
    }, []);

    const handleSave = async () => {
        const newErrors = validateRequiredFields(formData, REQUIRED_BC_FIELD);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const formattedData = {
            ...formData,
            ThoiGianHieuLuc: formatDateToISO(formData.ThoiGianHieuLuc),
            NgayGhiThucTe: formatDateToDDMMYYYY(formData.NgayGhiThucTe),
            created_at: formatDateToISO(formData.created_at),
        };
        try {
            const response = await updateBaoCao(formattedData, localStorage.getItem("accessToken"));
            if (response?.result) {
                toast.success('Cập nhật báo cáo thành công!');
                onClose()
            }
        } catch (error) {
            console.error('Error updating contract:', error);
            toast.error('Có lỗi xảy ra khi cập nhật hợp đồng.');
        }
    };

    const handleClickOutside = (event) => {
        event.stopPropagation();
    };

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    };

    const handleModalConfirm = async (baoCao) => {
        const LoaiVB = "Bao cao";

        try {
            toast.success("Duyệt văn bản thành công, reload lại trang để xem cập nhật");
            const data = await confirmVB(baoCao.id, LoaiVB);
            setModalIsOpen(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    };
    //Note
    const [openNote, setOpenNote] = useState(false);
    const [vanBanID, setVanBanID] = useState(null);

    const handleRefuse = async (baoCao) => {
        if (baoCao.TrangThai === "CHO_DUYET") {
            setVanBanID(baoCao.id);
            openModalText("Thêm ghi chú cho báo cáo này:");
        }
        else {
            toast.error("Không thể từ chối văn bản này")
        }
    };

    const openModalText = (message) => {
        setModalTextIsOpen(true);
        setModalMessage(message);
    }


    const handleOpenNote = (id) => {
        console.log("id = ", id)
        setVanBanID(id);
        setOpenNote(true);
    }


    ///////

    const handleModalApprove = async (baoCao) => {
        const LoaiVB = "Bao cao";

        try {
            toast.success("Hoàn thành văn bản!, reload lại trang để xem cập nhật");
            const data = await approveVB(baoCao.id, LoaiVB);
            setModalIsOpen(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                console.log('Lỗi từ server:', error.response.data);
                alert('Có lỗi xảy ra từ server: ' + error.response.data.message);
            } else {
                console.error('Lỗi khi gửi yêu cầu:', error.message);
                alert('Có lỗi xảy ra: ' + error.message);
            }
        }
    };

    const handleConfirm = async (baoCao) => {
        if (baoCao.TrangThai === "DANG_THUC_HIEN") {
            toast.warn("Đã duyệt văn bản này rồi!");
        } else if (baoCao.TrangThai === "HOAN_THANH") {
            toast.warn("Văn bản này đã hoàn thành rồi!");
        } else {
            openModalConfirm("Bạn có chắc chắn duyệt báo cáo này?");
        }
    };

    const handleSucces = async (baoCao) => {
        if (baoCao.TrangThai === "HOAN_THANH") {
            toast.warn("Văn bản này đã hoàn thành rồi");
        } else if (baoCao.TrangThai === "CHUA_THUC_HIEN" || baoCao.TrangThai === "CHO_DUYET") {
            toast.error("Văn bản chưa được thực hiện");
        } else {
            openModal("Bạn có chắc chắn Hoàn thành báo cáo này?");
        }
    };

    const role_id = localStorage.getItem("role_id");
    const [selectedKTVId, setSelectedKTVId] = useState(formData.DanhSachKTV?.MaKTV);
    const [selectedBGDId, setSelectedBGDId] = useState(formData.MaThanhVienBGD?.MaKTV);
    const getOptionDisabled = (id) => {
        return id === selectedKTVId || id === selectedBGDId;
    };
    useEffect(() => {
        setSelectedKTVId(formData?.selectedKTVId);
        setSelectedBGDId(formData?.selectedBGDId);
    }, [formData])
    return (

        <div
            id="overlay"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ animation: "slideDown 0.5s ease-out forwards" }}
            onClick={handleClickOutside}
        >
            <div>

                <div className="bg-white p-4 rounded-md shadow-lg relative w-[1024px] h-[600px] overflow-hidden"
                    style={{ animation: "slideDownContent 0.5s ease-out forwards" }}
                >
                    <button onClick={onClose} className="absolute top-0 right-0 m-2 text-gray-500">
                        <i className="fa-solid fa-rectangle-xmark"></i>
                    </button>
                    <div className="flex h-full">
                        <div className="w-full flex flex-col">
                            <div className="flex flex-col items-center">
                                <h1 className="font-bold mb-4 text-xl text-gray-600">Chi tiết báo cáo</h1>
                                <span className={`block ${getStatusColor(baoCao.TrangThai)} text-sm  font-bold px-0.5 py-2 rounded-full text-center w-36`}>
                                    {getStatusText(baoCao.TrangThai)}
                                </span>
                            </div>
                            <div className="overflow-auto p-4 flex-grow">
                                {[
                                    { label: 'Thuộc hợp đồng', name: 'SoHopDong', value: baoCao.HopDong?.SoHopDong || '' },
                                    { label: 'Số báo cáo', name: 'SoBaoCao', value: baoCao.SoBaoCao || '' },
                                    { label: 'Số báo cáo của khách hàng', name: 'MaBCCuaKH', value: baoCao.MaBCCuaKH || '' },
                                    { label: 'Ngày khởi tạo', name: 'created_at', type: 'dateKT' },
                                    { label: 'Ngày ghi thực tế trên báo cáo', name: 'NgayGhiThucTe', type: 'date' },
                                    { label: 'Loại báo cáo', name: 'LoaiBC', type: 'fixed' },
                                    { label: 'Số bản', name: 'SoBan', type: 'number', value: baoCao.SoBan || '' },
                                    ...(ROLE_ID === "2" ? [{ label: 'Số lưu', name: 'SoLuu', type: 'number' }, { label: 'Lưu ý', name: 'LuuY', type: 'select', options: LuuY_LIST },
                                    { label: 'Ghi chú', name: 'Note' }] : []),
                                    { label: 'Thời gian hiệu lực báo cáo', name: 'ThoiGianHieuLuc', type: 'date' },
                                    { label: 'Nội dung', name: 'Noidung', type: 'textarea', value: baoCao.Noidung || '' },
                                    { label: 'Phòng ban thực hiện', name: 'DonViThucHien', type: 'text', value: baoCao.NguoiNhap?.DonVi?.name || '' },
                                    { label: 'Người nhập', name: 'MaNguoiNhap', type: 'text', value: baoCao.NguoiNhap?.HoTen || '' },
                                    { label: 'Trưởng nhóm', name: 'TruongNhom', type: 'text', value: baoCao.TruongNhom },
                                    { label: 'Kỹ thuật viên', name: 'KyThuatVien', type: 'text', value: baoCao.KyThuatVien },
                                    { label: 'Kiểm toán viên ký', name: 'DanhSachKTV', type: 'select', options: KTVKy },
                                    { label: 'Thành viên ban TGD ký', name: 'MaThanhVienBGD', type: 'select', options: KTVKy },
                                    { label: 'Link Drive', name: 'LinkDrive', value: baoCao.LinkDrive || '' },
                                ].map((field, idx) => (
                                    <div className="mb-2 flex items-center" key={idx}>
                                        <label className="font-bold w-1/3">{field.label}:</label>
                                        <div className="w-2/3">
                                            {errors[field.name] && <span className="text-red-500 text-sm">{errors[field.name]}</span>}
                                            {field.type === 'textarea' ? (
                                                <textarea
                                                    name={field.name}
                                                    className="w-full border rounded px-2 py-1"
                                                    value={formData[field.name] || ''}
                                                    onChange={handleInputChange}
                                                    rows="3"
                                                ></textarea>
                                            ) : field.type === 'select' && field.name !== "DanhSachKTV" && field.name !== "MaThanhVienBGD" ? (
                                                <select
                                                    name={field.name}
                                                    className="w-full border rounded px-2 py-1"
                                                    value={formData[field.name] || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    {field.options.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name || option.HoTen}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : field.type === 'fixed' ? (
                                                <p className={`w-full border rounded px-2 py-1`}>
                                                    {baoCao[field.name]?.name || "Không có dữ liệu"}
                                                </p>
                                            ) : (field.type === 'select' && field.name === "DanhSachKTV") ? (
                                                <div className={`w-full`}>
                                                    {formData.ThoiGianHieuLuc ? (
                                                        <ReactSelect
                                                            name={field.name}
                                                            value={{
                                                                value: formData.DanhSachKTV?.id || '',  // Lưu id vào value
                                                                label: formData.DanhSachKTV?.NhanVien?.HoTen || '',  // Hiển thị tên nhân viên
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                const selectedKTV = field.options?.ListKTV.find(option => option.id === selectedOption.id)
                                                                    || field.options?.ListKTVKyLienTiep.find(option => option.id === selectedOption.id);
                                                                setSelectedKTVId(selectedKTV?.id)
                                                                const event = {
                                                                    target: {
                                                                        name: "DanhSachKTV",
                                                                        value: {
                                                                            id: selectedKTV?.id,
                                                                            NhanVien: selectedKTV,
                                                                        },
                                                                    },
                                                                };
                                                                handleInputChange(event);
                                                            }}
                                                            styles={customStyles}
                                                            options={[
                                                                ...(Array.isArray(field.options?.ListKTV)
                                                                    ? field.options.ListKTV.map((option) => ({
                                                                        value: option.id,
                                                                        label: option.HoTen,
                                                                        id: option.id,
                                                                        isDisabled: getOptionDisabled(option.id),
                                                                    }))
                                                                    : []),
                                                                ...(Array.isArray(field.options?.ListKTVPhat)
                                                                    ? field.options.ListKTVPhat.map((option) => ({
                                                                        value: option.id,
                                                                        label: `${option.HoTen} (Phạt)`,
                                                                        isDisabled: true,
                                                                        id: option.id,
                                                                        title: 'Đang bị phạt, không thể ký',
                                                                    }))
                                                                    : []),
                                                                ...(Array.isArray(field.options?.ListKTVKyLienTiep)
                                                                    ? field.options.ListKTVKyLienTiep.map((option, index, array) => {
                                                                        const formYear1 = new Date(formData?.ThoiGianHieuLuc)?.getFullYear();
                                                                        let formYear2
                                                                        if (!formYear1) {
                                                                            const ngayGhiThucTe = formData?.ThoiGianHieuLuc;
                                                                            const parts = ngayGhiThucTe?.split('/');
                                                                            formYear2 = Number(parts[2]);
                                                                        }
                                                                        const formYear = formYear1 || formYear2
                                                                        if (option.NamKyLienTiep.length === KTVKy.times) {
                                                                            const yearLast = option.NamKyLienTiep[KTVKy.times - 1];
                                                                            const yearFirst = option.NamKyLienTiep[0];
                                                                            if (formYear === yearLast + 1 || formYear === yearFirst - 1) {
                                                                                return {
                                                                                    value: option.id,
                                                                                    label: `${option.HoTen} (Ký 3 năm)`,
                                                                                    isDisabled: getOptionDisabled(option.id) || true,
                                                                                    id: option.id,
                                                                                    title: 'Đã ký 3 năm liên tiếp',
                                                                                };
                                                                            } else {
                                                                                return {
                                                                                    value: option.id,
                                                                                    label: `${option.HoTen}`,
                                                                                    isDisabled: getOptionDisabled(option.id) || false,
                                                                                    id: option.id,
                                                                                    title: 'Được phép ký',
                                                                                };
                                                                            }
                                                                        } else {
                                                                            return {
                                                                                value: option.id,
                                                                                label: `${option.HoTen}`,
                                                                                isDisabled: getOptionDisabled(option.id) || false, // Không chặn
                                                                                id: option.id,
                                                                                title: 'Được phép ký',
                                                                            };
                                                                        }
                                                                    })
                                                                    : []),
                                                            ]}
                                                        />
                                                    ) : (
                                                        <div className="text-red-500">Vui lòng nhập Thời gian hiệu lực trước khi chọn KTV ký.</div>
                                                    )}
                                                </div>
                                            ) : (field.type === 'select' && field.name === "MaThanhVienBGD") ? (
                                                <div className={`w-full`}>
                                                    {formData.ThoiGianHieuLuc ? (
                                                        <ReactSelect
                                                            name={field.name}
                                                            value={{
                                                                value: formData.MaThanhVienBGD?.id || '',  // Lưu id vào value
                                                                label: formData.MaThanhVienBGD?.NhanVien?.HoTen || '', // Hiển thị tên nhân viên BGD
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                const selectedBGD = field.options?.ListKTV.find(option => option.id === selectedOption.id)
                                                                    || field.options?.ListKTVKyLienTiep.find(option => option.id === selectedOption.id);
                                                                setSelectedBGDId(selectedBGD?.id)
                                                                const event = {
                                                                    target: {
                                                                        name: "MaThanhVienBGD",
                                                                        value: {
                                                                            id: selectedBGD?.id,
                                                                            NhanVien: selectedBGD,
                                                                        },
                                                                    },
                                                                };
                                                                handleInputChange(event);
                                                            }}
                                                            styles={customStyles}
                                                            options={[
                                                                ...(Array.isArray(field.options?.ListKTV)
                                                                    ? field.options.ListKTV
                                                                        .filter((option) => option.DonViID === 5)
                                                                        .map((option) => ({
                                                                            value: option.id,
                                                                            label: option.HoTen,
                                                                            id: option.id,
                                                                            isDisabled: getOptionDisabled(option.id),
                                                                        }))
                                                                    : []),
                                                                ...(Array.isArray(field.options?.ListKTVPhat)
                                                                    ? field.options.ListKTVPhat
                                                                        .filter((option) => option.DonViID === 5)
                                                                        .map((option) => ({
                                                                            value: option.id,
                                                                            label: `${option.HoTen} (Phạt)`,
                                                                            isDisabled: true,
                                                                            id: option.id,
                                                                            title: 'Đang bị phạt, không thể ký',
                                                                        }))
                                                                    : []),
                                                                ...(Array.isArray(field.options?.ListKTVKyLienTiep)
                                                                    ? field.options.ListKTVKyLienTiep
                                                                        .filter((option) => option.DonViID === 5)
                                                                        .map((option) => {
                                                                            const formYear1 = new Date(formData?.ThoiGianHieuLuc)?.getFullYear();
                                                                            let formYear2
                                                                            if (!formYear1) {
                                                                                const ngayGhiThucTe = formData?.ThoiGianHieuLuc;
                                                                                const parts = ngayGhiThucTe?.split('/');
                                                                                formYear2 = Number(parts[2]);
                                                                            }
                                                                            const formYear = formYear1 || formYear2
                                                                            if (option.NamKyLienTiep.length === KTVKy.times) {
                                                                                const yearLast = option.NamKyLienTiep[KTVKy.times - 1];
                                                                                const yearFirst = option.NamKyLienTiep[0];
                                                                                if (formYear === yearLast + 1 || formYear === yearFirst - 1) {
                                                                                    return {
                                                                                        value: option.id,
                                                                                        label: `${option.HoTen} (Ký  ${KTVKy.times} năm)`,
                                                                                        id: option.id,
                                                                                        title: `Đã ký  ${KTVKy.times} năm liên tiếp`,
                                                                                        isDisabled: getOptionDisabled(option.id) || true,
                                                                                    };
                                                                                } else {
                                                                                    return {
                                                                                        value: option.id,
                                                                                        label: `${option.HoTen}`,
                                                                                        id: option.id,
                                                                                        title: 'Được phép ký',
                                                                                        isDisabled: getOptionDisabled(option.id) || false,

                                                                                    };
                                                                                }
                                                                            } else {
                                                                                return {
                                                                                    value: option.id,
                                                                                    label: `${option.HoTen}`,
                                                                                    id: option.id,
                                                                                    title: 'Được phép ký',
                                                                                    isDisabled: getOptionDisabled(option.id) || false,

                                                                                };
                                                                            }
                                                                        })
                                                                    : []),
                                                            ]}
                                                        />
                                                    ) : (
                                                        <div className="text-red-500">Vui lòng nhập Thời gian hiệu lực trước khi chọn BGD ký.</div>
                                                    )}
                                                </div>
                                            ) : field.type === 'date' && field.name !== 'ThoiGianHieuLuc' ? (
                                                <DatePicker
                                                    onChange={(date) => handleDateChange(date, field.name)}
                                                    value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                    format="dd/MM/yyyy"
                                                    locale="vi"
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    className="w-full border rounded px-2 py-1"
                                                    placeholderText="Ngày/Tháng/Năm"
                                                />
                                            ) : (field.type === 'date' && field.name === 'ThoiGianHieuLuc') ? (
                                                <DatePicker
                                                    onChange={(date) => handleDateChangeTGHL(date, field.name)}
                                                    value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                    format="dd/MM/yyyy"
                                                    locale="vi"
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    className="w-full border rounded px-2 py-1"
                                                    placeholderText="Ngày/Tháng/Năm"
                                                />
                                            ) : field.type === 'dateKT' ? (
                                                <DatePicker
                                                    value={formData[field.name] ? new Date(moment(formData[field.name], 'DD/MM/YYYY').format()) : null}
                                                    format="dd/MM/yyyy"
                                                    locale="vi"
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    className="w-full border rounded px-2 py-1"
                                                    placeholderText="Ngày/Tháng/Năm"
                                                    readOnly
                                                />
                                            ) : field.type === 'text' && field.name !== 'TruongNhom' && field.name !== 'KyThuatVien' ? (
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    className="w-full border rounded px-2 py-1"
                                                    value={field.value || ''}
                                                    readOnly
                                                />
                                            ) : field.type === 'text' && (field.name === 'TruongNhom' || field.name === 'KyThuatVien') ? (
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    className="w-full border rounded px-2 py-1"
                                                    value={field.value || ''}
                                                />
                                            ) : field.type === 'number' ? (
                                                <input
                                                    type={"number"}
                                                    className={"w-full border rounded px-2 py-1"}
                                                    name={field.name}
                                                    value={formData[field.name]}
                                                    onChange={handleInputChange}
                                                    placeholder={field.placeholder}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    className="w-full border rounded px-2 py-1"
                                                    value={
                                                        field.format === 'currency'
                                                            ? formatCurrency(formData[field.name])
                                                            : (field.name === "SoBaoCao")
                                                                ? baoCao.MaBCCuaKH
                                                                    ? `${baoCao.MaBCCuaKH} - ${baoCao.SoBaoCao}`
                                                                    : `${baoCao.SoBaoCao}`
                                                                : (field.name === "SoHopDong")
                                                                    ? baoCao.HopDong.MaHDCuaKH
                                                                        ? `${baoCao.HopDong.MaHDCuaKH} - ${baoCao.HopDong.SoHopDong}`
                                                                        : `${baoCao.HopDong.SoHopDong}`
                                                                    : (field.value || formData[field.name])
                                                    }

                                                    onChange={handleInputChange}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* <button className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2">
              Danh sách công việc
            </button> */}
                            <div className="flex flex-col items-center">
                                <div className="flex mt-4 px-4">
                                    {((baoCao.TrangThai === CHO_DUYET || baoCao.TrangThai === DA_NHAN_XET) || (role_id === '2')) && (
                                        <button
                                            onClick={handleSave}
                                            className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
                                        >
                                            Lưu
                                        </button>
                                    )}

                                    {role_id === '2' && (
                                        <>
                                            {baoCao.TrangThai === CHO_DUYET && (
                                                <button onClick={() => handleRefuse(baoCao)} className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded mr-2"
                                                >
                                                    Từ chối
                                                </button>
                                            )}
                                            {(baoCao.TrangThai === CHO_DUYET || baoCao.TrangThai === DA_NHAN_XET) && (
                                                <button onClick={() => handleConfirm(baoCao)} className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded">
                                                    Duyệt
                                                </button>
                                            )}
                                            {/* {(baoCao.TrangThai === DANG_THUC_HIEN) && (
                                                <button
                                                    onClick={() => handleSucces(baoCao)} // Thay handleNewAction bằng hàm xử lý cho nút mới
                                                    className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded "
                                                >
                                                    Hoàn thành
                                                </button>
                                            )} */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal isOpen={modalConfirmIsOpen} onClose={closeModalConfirm} message={modalMessage} onConfirm={() => handleModalConfirm(baoCao)} />
                <ModalText isOpen={modalTextIsOpen} onClose={closeModal} message={modalMessage} VanBanId={vanBanID} LoaiVB="Bao cao" />
                <Modal isOpen={modalIsOpen} onClose={closeModal} message={modalMessage} onConfirm={() => handleModalApprove(baoCao)} />
            </div>
            <div>
                <button onClick={() => handleOpenNote(baoCao.id)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                    <i class="fa-solid fa-comment"></i>
                </button>

            </div>
            {openNote && (<Note onClose={() => setOpenNote(false)} vanbanID={vanBanID} LoaiVB="Bao cao" />)}

        </div>

    );
}

BaoCaoDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    baoCao: PropTypes.shape({
        SoHopDong: PropTypes.string,
        SoBaoCao: PropTypes.string,
        MaBCCuaKH: PropTypes.string,
        created_at: PropTypes.string,
        NgayGhiThucTe: PropTypes.string,
        MaLoaiHD: PropTypes.number,
        SoBan: PropTypes.string,
        Note: PropTypes.string,
        LuuY: PropTypes.string,
        SoLuu: PropTypes.string,
        ThoiGianHieuLuc: PropTypes.string,
        GiaTriTruocVAT: PropTypes.string,
        GiaTriSauVAT: PropTypes.string,
        Noidung: PropTypes.string,
        DonViThucHien: PropTypes.string,
        NguoiNhap: PropTypes.shape({
            DonVi: PropTypes.shape({
                name: PropTypes.string,
            }),
            HoTen: PropTypes.string,
        }),
        DanhSachKTV: PropTypes.string,
        TruongNhom: PropTypes.string,
        KyThuatVien: PropTypes.string,
        MaThanhVienBGD: PropTypes.number,
        LinkDrive: PropTypes.string,
        previewImage: PropTypes.string,
    }).isRequired,
};

export default BaoCaoDetails;
